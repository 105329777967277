import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M1_V2 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, max.data);

    // if (typeof max.data=="undefined" ||max.data<1) {
    //   return (
    //     <div className="text-center">
    //       <h3>Please complete the Project-1 before proceeding ahead</h3>
    //     </div>
    //   );
    // }
    // else{
    return (
      <div className="container">
        <h3 className="ibep-textual-title">Working with data in Excel</h3>
        <h4>
          <strong className="ibep-textual-sub-title">
            1. Importing Data into Excel{" "}
          </strong>
        </h4>
        <div>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp; Excel supports importing data from
            multiple sources:
          </p>
          <p className="ibep-textual-para">
            1.&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">CSV files</strong>:
            Common format for data exchange.
          </p>
          <p className="ibep-textual-para">
            2.&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">
              Other file formats
            </strong>{" "}
            are JSON, XML, Excel workbooks, and online sources.
          </p>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">
              Steps to Import CSV Files
            </strong>
            :
          </p>
          <p className="ibep-textual-para">
            1.&nbsp;&nbsp;&nbsp; Open Excel → Go to the{" "}
            <strong className="ibep-textual-sub-title">"Data" tab</strong> →
            Click <strong className="ibep-textual-sub-title">"Get Data"</strong>{" "}
            → Choose{" "}
            <strong className="ibep-textual-sub-title">"From Text/CSV"</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image001.jpg"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            2.&nbsp;&nbsp;&nbsp; Select the CSV file, preview the data, and
            ensure the correct delimiter is detected. We will import this
            “sample_data.csv” file to practice.
            <br /> [provide the link to the sample dataset].
          </p>
          <p className="ibep-textual-para">
            3.&nbsp;&nbsp;&nbsp; Click{" "}
            <strong className="ibep-textual-sub-title">"Load"</strong> to import
            the data into a new worksheet. Some steps differ based on the
            version of Excel you are using. However, broadly, the overall steps
            are the same
          </p>
          <p className="ibep-textual-para">
            Some steps differ based on the version of Excel you are using.
            However, broadly, the overall steps are the same.
          </p>
          <h4>
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image002.png"
              alt=""
            />
          </h4>
          <h4>&nbsp;</h4>
          <hr />
          <h4>
            <strong className="ibep-textual-sub-title">
              2. Renaming Sheets
            </strong>
          </h4>
          <p className="ibep-textual-para">●&nbsp;&nbsp;&nbsp;&nbsp; Rename sheets for clarity:</p>
          <p className="ibep-textual-para">
            1.&nbsp;&nbsp;&nbsp; We have “Sheet1”, the sheet in which we have
            our data loaded. We can click the plus icon to create more sheets.
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image003.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            2.&nbsp;&nbsp;&nbsp; Right-click on the sheet tab (e.g., "Sheet1") →
            Select <strong className="ibep-textual-sub-title">Rename</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image004.jpg"
              alt=""
            />
          </p>
  
          <p className="ibep-textual-para">
            3.&nbsp;&nbsp;&nbsp; Type the desired name (e.g., "data") → Press{" "}
            <strong className="ibep-textual-sub-title">Enter</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image005.png"
              alt=""
            />
          </p>
          <div>
            <hr />
          </div>
          <h4>
            <strong className="ibep-textual-sub-title">
              3. Assigning Data Types to Columns
            </strong>
          </h4>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp; Setting correct data types improves data
            handling and analysis.
          </p>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">
              Steps to Assign Data Types
            </strong>
            :
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Number Datatype</strong>:
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Select the column “A” (ID Col) → Go to the{" "}
            <strong className="ibep-textual-sub-title">"Home" tab</strong> →
            Under <strong className="ibep-textual-sub-title">"Number,"</strong>{" "}
            select <strong className="ibep-textual-sub-title">"Number."</strong>
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Use{" "}
            <strong className="ibep-textual-sub-title">
              "Decrease Decimal"
            </strong>{" "}
            to remove extra decimals.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image006.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image007.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Text Datatype</strong>:
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Select the column(s) → Choose{" "}
            <strong className="ibep-textual-sub-title">"Text"</strong> from the{" "}
            <strong className="ibep-textual-sub-title">"Number"</strong>{" "}
            dropdown menu.
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Date Datatype</strong>:
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Select the column → Choose{" "}
            <strong className="ibep-textual-sub-title">"Short Date"</strong> or{" "}
            <strong className="ibep-textual-sub-title">"Long Date"</strong> from
            the <strong className="ibep-textual-sub-title">"Number"</strong>{" "}
            dropdown. (e.g., Short date: MM/DD/YYYY, Long date: Monday, January
            1, 2024).
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image008.png"
              alt=""
            />
          </p>
 
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">
              Currency Datatype
            </strong>
            :
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Select the column” E” → Click{" "}
            <strong className="ibep-textual-sub-title">
              "More Number Formats"
            </strong>{" "}
            → Choose{" "}
            <strong className="ibep-textual-sub-title">Currency</strong> → Set
            the symbol (e.g., INR).
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image009.jpg"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image010.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </p>

          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image011.png"
              alt=""
            />
          </p>
 
          <p className="ibep-textual-para">
            <strong className="ibep-textual-sub-title">&nbsp;</strong>
          </p>
          <div>
            <hr />
          </div>
          <h4>
            <strong className="ibep-textual-sub-title">
              4. Sorting and Filtering Data
            </strong>
          </h4>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Sorting</strong>: To sort
            the data, we first need to select the entire set. Then, we can
            arrange the data in ascending or descending order.
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Example</strong>: Sort
            the Salary column (largest to smallest) by:
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Selecting the dataset → Applying{" "}
            <strong className="ibep-textual-sub-title">
              Sort &amp; Filter
            </strong>{" "}
            → Choosing{" "}
            <strong className="ibep-textual-sub-title">
              Sort Largest to Smallest
            </strong>
            .
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image012.png"
              alt=""
            />
          </p>
     
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image013.jpg"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image014.png"
              alt=""
            />
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image015.jpg"
              alt=""
            />
          </p>

          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Filtering</strong>: Focus
            on rows that meet specific criteria.
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Example</strong>: Filter
            employees in the IT department.
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Apply{" "}
            <strong className="ibep-textual-sub-title">Filter</strong> from{" "}
            <strong className="ibep-textual-sub-title">
              Sort &amp; Filter
            </strong>{" "}
            or use the shortcut{" "}
            <strong className="ibep-textual-sub-title">CTRL + SHIFT + L</strong>
            .
          </p>
          <p className="ibep-textual-para">
            ■&nbsp;&nbsp;&nbsp;&nbsp; Click the dropdown in the "Department"
            column → Select{" "}
            <strong className="ibep-textual-sub-title">IT</strong> → Click{" "}
            <strong className="ibep-textual-sub-title">OK</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image016.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">&nbsp;</p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image017.png"
              alt=""
            />
          </p>

          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image018.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Custom Filters</strong>:
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; For text: Use "Contains" or "Begins With"
            filters.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image019.png"
              alt=""
            />
          </p>
      
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; For numbers: Use "Greater Than" or "Less
            Than."
          </p>
          <div>
            <hr />
          </div>
          <h4>
            <strong className="ibep-textual-sub-title">
              5. Splitting Delimited Text
            </strong>
          </h4>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp; Use{" "}
            <strong className="ibep-textual-sub-title">Text to Columns</strong>{" "}
            to split data in one column into multiple columns:
          </p>
          <p className="ibep-textual-para">
            1.&nbsp;&nbsp;&nbsp; Select the column with delimited text (e.g.,
            "Skills").
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image020.jpg"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">&nbsp;</p>
          <p className="ibep-textual-para">&nbsp;</p>
          <p className="ibep-textual-para">
            2.&nbsp;&nbsp;&nbsp; Go to the{" "}
            <strong className="ibep-textual-sub-title">"Data" tab</strong> →
            Click{" "}
            <strong className="ibep-textual-sub-title">Text to Columns</strong>{" "}
            → Choose{" "}
            <strong className="ibep-textual-sub-title">Delimited</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image021.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">
            3.&nbsp;&nbsp;&nbsp; Specify the delimiter (e.g., "|") → Preview →
            Assign column types → Click{" "}
            <strong className="ibep-textual-sub-title">Finish</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image022.png"
              alt=""
            />
          </p>
          <p className="ibep-textual-para">&nbsp;</p>
          <p className="ibep-textual-para">
            4.&nbsp;&nbsp;&nbsp; Rename the new columns (e.g., "Skill 2", "Skill
            3").
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image023.png"
              alt=""
            />
          </p>
 
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image024.png"
              alt=""
            />
          </p>
 
          <div>
            <hr />
          </div>
          <h4>
            <strong className="ibep-textual-sub-title">
              6. Removing Duplicates
            </strong>
          </h4>
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Steps</strong>:
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; Select the data range → Click{" "}
            <strong className="ibep-textual-sub-title">
              Remove Duplicates
            </strong>{" "}
            (under the <strong className="ibep-textual-sub-title">Data</strong>{" "}
            tab).
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image025.png"
              alt=""
            />
          </p>
       
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; Check{" "}
            <strong className="ibep-textual-sub-title">
              "My data has headers"
            </strong>
            .
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; Choose columns to check for duplicates.
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image026.png"
              alt=""
            />
          </p>
    
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; Click{" "}
            <strong className="ibep-textual-sub-title">OK</strong> → Excel will
            display the number of duplicates removed and remaining rows.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v2/image027.png"
              alt=""
            />
          </p>
 
          <p className="ibep-textual-para">
            ●&nbsp;&nbsp;&nbsp;&nbsp;{" "}
            <strong className="ibep-textual-sub-title">Tips</strong>:
          </p>
          <p className="ibep-textual-para">
            ○&nbsp;&nbsp;&nbsp;&nbsp; Be cautious when selecting fewer columns
            to avoid removing unique entries mistakenly.
          </p>
        </div>
      </div>
    );
    // }
  }
}
XL_M1_V2.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M1_V2);
