import React, { Component } from 'react';
import { connect } from "react-redux";
import PropTypes from "prop-types";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false,}
  }

  // Lifecycle method to catch errors
  static getDerivedStateFromError(error) {
    return { hasError: true};
  }

  // Optionally, you can log the error info
  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.hasError && this.props.user.cprogram != prevProps.user.cprogram) {
      // this will reset the error state when the error is resolved.
      this.setState({ hasError: false });
    }
  }

  resetError = () => {
    this.setState({ hasError: false });
  };
  
    render() {
        if (this.state.hasError) {
          // return (
          //   <div
          //     className="modal fade show"
          //     id="maintenanceModal"
          //     tabIndex="-1"
          //     aria-labelledby="maintenanceModalLabel"
          //     aria-modal="true"
          //     role="dialog"
          //     style={{ display: 'block' }}
          //   >
          //     <div className="modal-dialog modal-xl modal-dialog-centered modal-sm">
          //       <div className="modal-content">
          //         <div className="modal-body">
          //           <div className="container">
          //             <div className="row error">
          //               <div className="col-md-6 col-sm-6 d-flex flex-column" style={{ gap: '1rem' }}>
          //                 <h1>We will be back online shortly!</h1>
          //                 <h2>Our project service is currently <br /> undergoing scheduled <br /> maintenance.</h2>
          //                 {/* <h3>Estimated downtime:</h3>
          //                 <h3 id="countdown"></h3> */}
          //               </div>
        
          //               <div className="col-md-6 col-sm-6">
          //                 <img src="./error.png" alt="Error" style={{ width: '100%' }} />
          //               </div>
          //             </div>
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //   </div>
          // );
        
          return this.props.fallback;
        } 

        return this.props.children;
    }
}

ErrorBoundary.propstype = {
  userprogarm: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user.info
});
export default connect(
  mapStateToProps,
  {  }
)(ErrorBoundary);

// export default ErrorBoundary;
