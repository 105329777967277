import {

    DS_RESOURCE_VIDEOFETCH_SCORE,
    DS_RESOURCE_VIDEOQUESTIONS
} from "../actions/types";

const initialState = {
    vquestions: {},
    vscores: {},
};

export default function (state = initialState, action) {
    switch (action.type) {
        case DS_RESOURCE_VIDEOQUESTIONS:
            return {
                ...state,
                vquestions: action.payload
            };

        case DS_RESOURCE_VIDEOFETCH_SCORE:
            return {
                ...state,
                vscores: action.payload
            };

        default:
            return state;
    }
}
