import { IVEPRESEARCH_DATA, IVEPRATERESEARCH_DATA,IVEPGET_NOTIFY_SCORE,IVEPGET_MAX,IVEPGET_ELI } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{},
  max:{},
  eli:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case IVEPRESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case IVEPRATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case IVEPGET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
      case IVEPGET_MAX:
      return {
        ...state,
        max: action.payload
      };
      case IVEPGET_ELI:
      return {
        ...state,
        eli: action.payload
      };
    default:
      return state;
  }
}

