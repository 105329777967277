import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Row,
  Button,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip
} from "reactstrap";
import { Redirect, withRouter } from "react-router-dom";
import { switchprogram } from "../../actions/selectActions";
import { FaChevronDown } from "react-icons/fa";

import { selectprogram } from "../../actions/selectActions";
import { GoDotFill } from "react-icons/go";

import moment from "moment";
class Userswitchmodel extends Component {
  constructor() {
    super();
    this.state = {
      modal: false,
      modal1: true,
      redirect: false,
      ivep: "IBEPGOLD",
      fmep: "FMEPGOLD",
      ba: "BA",
      FMEPSILVER: "FMEPSILVER",
      fafmep: "FAFMEP",
      fafmepplatinum: "FMEPPLATINUM",
      ibepsliver: "IBEPSLIVER",
      FnO: "FnO",
      DS: "DS",
      DSGOLD: "DSGOLD",
      ML:"ML",
      EXCEL:"EXCEL",
      img: "/img/active/changeProgram.png",
      aprogram: "",
      bprogram: "",
      isOpenD: false,
      buttonText: "Your Programs",
    };
    this.dropdownRef = React.createRef();
  }

  componentDidMount = () => {
    this.setState({ aprogram: this.props.user.cprogram });
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ isOpenD: false });
    }
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      isOpenD: !prevState.isOpenD,
    }));
  };

  // componentDidMount() {
  //   document.addEventListener('mousedown', this.handleClickOutside);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener('mousedown', this.handleClickOutside);
  // }

  // handleClickOutside = (event) => {
  //   console.log("Handling click outside")
  //   if (this.dropdownRef.current && !this.dropdownRef.current.contains(event.target)) {
  //     this.setState({ programSelect: false });
  //   }
  // };

  toggle = () => {
    this.setState({
      modal: !this.state.modal,
    });
  };

  toggle1 = () => {
    this.setState({
      modal1: !this.state.modal1,
    });
  };

  toggleprogram = () => {
    console.log(this.state.programSelect, "Toggling program");
    this.setState({
      programSelect: !this.state.programSelect,
    });
  };

  setButtonText = (pname) => {
    console.log(pname, "Settting btn text");
    this.setState({
      buttonText: pname,
    });
  };

  setRedirect = (url) => {
    this.setState({ redirect: url, dropdownOpen: false });
  };
  setRedirect1 = (url) => {
    console.log(url);
    return <Redirect to={url} />;
  };
  setprogram = (p) => {
    this.setState({ bprogram: this.state.aprogram, aprogram: p });
  };

  formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };

  firstdisplay = () => {
    const currentDate = moment().format("YYYY-MM-DD");
    const currentDateInUtc = moment.utc().format("YYYY-MM-DD");
    let loginDate =
      this.props.session.start_time != undefined
        ? this.props.session.start_time.split("T")[0]
        : "";
    const date1 = moment(currentDate);
    const date2 = moment(loginDate);

    const options = { day: "numeric", month: "long", year: "numeric" };

    let loginTime =
      this.props.session.start_time != undefined
        ? this.props.session.start_time.split("T")[1].split(".")[0]
        : "";
    const currentTimePlusOneMinute = moment(loginTime, "HH:mm:ss").add(
      1,
      "minutes"
    );

    // const currentTimePlusOneMinute = loginTime.add(1, 'minutes').format('HH:mm:ss');

    const currentTim = moment.utc().format("HH:mm:ss");
    const currentTime = moment(currentTim, "HH:mm:ss");

    let programarray =
      this.props.user.program == undefined ? [] : this.props.user.program;
    programarray = programarray.filter((e) => e.start_date <= currentDate);

    console.log(
      date1.isSame(date2),
      currentTimePlusOneMinute,
      currentTime,
      currentTimePlusOneMinute.isAfter(currentTime),
      currentTimePlusOneMinute.isBefore(currentTime),
      programarray,
      "asdfgh"
    );
    // console.log(programarray,currentDate,currentTime,"sesssssssssssssssssssssssssssss");   currentTimePlusOneMinute
    // loginTime <= '06:17:16
    if (
      this.props.session.start_time != undefined &&
      programarray.length > 1 &&
      date1.isSame(date2) &&
      currentTimePlusOneMinute.isAfter(currentTime)
    ) {
      console.log(programarray, "Program array");
      return (
        <>
          {this.state.modal1 && ( // Render blur div only when modal1 is true
            <div
            // style={{
            //   position: "fixed",
            //   top: 0,
            //   left: 0,
            //   width: "100%",
            //   height: "100%",
            //   zIndex: "100",
            //   backdropFilter: "blur(100px)",
            // }}
            >
              <Modal
                isOpen={this.state.modal1}
                toggle={this.toggle1}
                className={this.props.className}
                centered={true}
                size="md"
                // className="col-md-9"
              >
                {/* client/public/img/dark_logo_transparent .png */}
                <ModalHeader
                  toggle={this.toggle1}
                  className="text-center d-flex m-auto"
                >
                  {/* <img
                    src="/img/dark_logo_transparent .png"
                    alt="logo"
                    style={{ width: "150px" }}
                  /> */}
                  {/* <h1>Choose Program</h1> */}
                  <h5 className="m-2 chooseProgTitle">
                    Welcome! Choose the program that you want to start
                  </h5>
                </ModalHeader>
                <ModalBody
                  className="firstDisplayBody"
                >
                  {typeof programarray != "undefined" && programarray.length > 1
                    ? programarray.map((n) => (
                        <div
                          key={n.id}
                          className="firstDisplayMain"
                          onClick={async () => {
                            await this.props.getDisplay(n.program_name);
                            if (
                              n.program_name == "FMEPGOLD" ||
                              n.program_name == "FAFMEP" ||
                              n.program_name == "FMEPPLATINUM" ||
                              n.program_name == "FMEPSILVER"
                            ) {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/dashboard");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/portfolio",
                                n.program_name,
                                this.state.bprogram
                              );
                            } else if (
                              n.program_name == "SSEP" ||
                              n.program_name == "SSEPGOLD"
                            ) {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            } else if (
                              n.program_name == "DS" ||
                              n.program_name == "DSGOLD"
                            ) {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            } else if (n.program_name == "FnO") {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            } else if (
                              n.program_name == "BA" ||
                              n.program_name == "BAGOLD"
                            ) {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            } else if (n.program_name == "ML") {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            
                            }
                            else if (n.program_name == "PYTHON") {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            }
                            else if (n.program_name == "EXCEL") {
                              // if(!moment('24-02-2025').isAfter(moment.now()) ){
                  
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                              // }
                              console.log(n.program_name)
                            }
                             else {
                              await this.setprogram(n.program_name);
                              await this.setRedirect1("/newresource");
                              await this.props.switchprogram(n.program_name);
                              await this.setState({ modal1: false });
                              await this.props.reflectactive(
                                "/newresource",
                                n.program_name,
                                this.state.bprogram
                              );
                            }
                          }}
                        >
                          <div>
                            <div
                              className=""
                              style={{
                                color:
                                  n.end_date < currentDate ||
                                  n.resume_date > currentDate 
                                    ? "#AFA9A9"
                                    : "green",
                                border: "none",
                               
                              }}
                            >
                              <div className="autopopswitchprogrambox text-center">
                                <div>
                                  <p className="m-0">
                                    <b>
                                      {n.program_name == "FMEPGOLD"
                                        ? "FMEP"
                                        : n.program_name == "FMEPPLATINUM"
                                        ? "FMEPGOLD"
                                        : n.program_name}
                                    </b>
                                  </p>
                                </div>
                                <div
                                      className="m-0 text-sm"
                                        // style={{
                                        //   color: n.program_name === "EXCEL" && moment().isBefore(moment('2025-02-20')) ? 'red' : '#979797',
                                        // }}
                                    >
                                <span title="Start Date" className="firstDisplayDate">
                                    {n.start_date}
                                  </span>{" "}
                                  <span className="firstDisplayDate">To</span>
                                  <span> </span>
                                  <span
                                    title="End Date"
                                    className="firstDisplayDate"
                                  >
                                    {n.end_date}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    : null}
                </ModalBody>
              </Modal>
            </div>
          )}
          {!this.state.modal1 && ( // Render blur div only when modal1 is false
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: "100",
                backdropFilter: "blur(3px)",
                display: "none",
              }}
            >
              {/* This div is hidden when modal1 is false */}
            </div>
          )}
        </>
      );
    } else if (
      this.props.session.start_time != undefined &&
      this.state.modal1 == true &&
      currentTimePlusOneMinute.isBefore(currentTime) &&
      programarray.length > 0
    ) {
      console.log("eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee");
      this.setState({
        modal1: !this.state.modal1,
      });
      return null;
    }
  };

  render() {
    var programarray = this.props.user.program;
    var bads;
    if (programarray) {
      const start_date = programarray.find(
        (item) => item.program_name === "EXCEL"
      );
      // console.log(start_date.start_date, "start_date is here")}
      if (start_date) {
        bads = start_date.start_date;
      }
      // console.log(this.props.user.cprogram,"fiyreyfurgf")
      console.log(programarray, "This is program array for users");
    }
    console.log(programarray, "fiyreyfurgf");
    console.log(programarray, "This is program array for users");

    if (this.state.redirect) {
      this.setState({ redirect: false, modal: false });
      return <Redirect to={this.state.redirect} />;
    }
    const currentDate = moment().format("YYYY-MM-DD");

    console.log(programarray, "ALL the programs");

    return (
      <div class="dropdown" ref={this.dropdownRef}>
        <button
          id="selectProgramBtn"
          class="dropbtn p-2"
          onClick={this.toggleDropdown}
        >
          {this.state.buttonText}
        </button>
        {this.firstdisplay()}
        {this.state.isOpenD && (
          <div class="dropdown-content">
            {typeof programarray != "undefined"
              ? programarray.map((n) => (
                  <div
                    style={{ backgroundColor: "#eef5fe" }}
                    className="phover"
                  >
                    <div
                      key={n.id}
                      className={`programHover ${
                        this.props.currProgram == n.program_name
                          ? "activeProgramUS"
                          : ""
                      }`}
                      style={{
                        width: "90%",
                        margin: "auto",
                        padding: "0.5rem",
                        paddingRight: "3rem",
                        backgroundColor: "#eef5fe",
                      }}
                      onClick={async () => {
                        await this.props.getDisplay(n.program_name);
                        if (
                          n.program_name == "FMEPGOLD" ||
                          n.program_name == "FAFMEP" ||
                          n.program_name == "FMEPPLATINUM" ||
                          n.program_name == "FMEPSILVER"
                        ) {
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/dashboard");
                          await this.props.switchprogram(n.program_name);
                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/portfolio",
                            n.program_name,
                            this.state.bprogram
                          );
                        } else if (
                          n.program_name == "SSEP" ||
                          n.program_name == "SSEPGOLD"
                        ) {
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);
                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        } else if (
                          n.program_name == "DS" ||
                          n.program_name == "DSGOLD"|| n.program_name=="PYTHON"
                        ) {
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);
                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        } else if (n.program_name == "FnO") {
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);
                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        } else if (
                          n.program_name == "BA" ||
                          n.program_name == "BAGOLD"
                        ) {
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);

                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        } else if (n.program_name == "ML") {
                          // console.log(moment('04-06-2024').isBefore(moment.now()),'helllllllllllllllllllllllllllllllllllllllllllllllllllllllllo' )

                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);

                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        }
                        else if (n.program_name == "EXCEL") {
                          // console.log(moment('04-06-2024').isBefore(moment.now()),'helllllllllllllllllllllllllllllllllllllllllllllllllllllllllo' )
                          // if(moment('24-02-2025').isAfter(moment.now()) ){
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);

                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        // }
                        }
                         else {
                          await this.setprogram(n.program_name);
                          await this.setRedirect1("/newresource");
                          await this.props.switchprogram(n.program_name);

                          await this.setState({ modal: false });
                          await this.props.reflectactive(
                            "/newresource",
                            n.program_name,
                            this.state.bprogram
                          );
                        }
                        console.log("droping down");
                        this.setState({isOpenD : false});
                      }}
                    >
                      <div className="" style={{ border: "none" }}>
                        <div className="switchprogrambox">
                          <p className="m-0">
                            <div>
                              
                                <p   id={programarray!==undefined && (n.program_name=='EXCEL' )? 'Excel':{}} >
                                {n.program_name == "FMEPGOLD"
                                  ? "FMEP"
                                  : n.program_name == "FMEPPLATINUM"
                                  ? "FMEPGOLD"
                                  : n.program_name}
                                  <p>
                               

                                  </p>
                                 
                                   
                              </p>
                              
                      
                              {/* {this.props.currProgram == n.program_name ? (
                                <GoDotFill />
                              ) : null} */}
                              {/* <div className="m-0  text-sm"> */}
                     
                    
                      {/* </div> */}
                       
                              {/* <div className="m-0  text-sm" style={{display:"block"}}>
                          
                            { programarray!==undefined && (n.program_name=='EXCEL' )? (
<sup className="error" style={{color:"gray",     fontSize: "xx-small",    marginTop: '-1rem',    lineHeight: '0.9rem',}}>{bads}</sup>): null}


                    
                      </div> */}
                      </div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              : null}
          </div>
        )}
      </div>
    );
  }
}

Userswitchmodel.propTypes = {
  user: PropTypes.object.isRequired,
  session: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.info,
  session: state.user.session,
  errors: state.errors,
});

export default withRouter(
  connect(
    mapStateToProps,
    { switchprogram }
  )(Userswitchmodel)
);