import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M1_V1 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <div>
          <h3 className="ibep-textual-title">Introduction to Excel</h3>
          <p className="ibep-textual-para">
            <strong>1. Overview</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Microsoft Excel</strong>: A powerful spreadsheet software
              included in the Microsoft Office suite.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Importance</strong>: Useful for organizing, analyzing, and
              presenting data.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Applications</strong>: Widely used by individuals and
              businesses to manage data efficiently.
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>2. Getting Started with Excel</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Installation</strong>: Ensure Microsoft Excel is installed
              on your computer.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Launching Excel</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  Open the Start menu, search for Excel, and click to launch.
                </li>
                <li className="ibep-textual-sub-para">
                  Select “Blank Workbook” to begin.
                </li>
              </ul>
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>3. Excel Interface Basics</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Grid Layout</strong>: Made up of rows and columns.
              <ul>
                <li className="ibep-textual-sub-para">
                  <strong>Columns</strong>: Vertical segments labeled with
                  letters (A, B, …, Z, AA, AB, etc.).
                  <img
                    className="rounded mx-auto d-block img-fluid ibep-textual-img"
                    src="/img/excel/m1v1/image001.png"
                    alt=""
                    width={434}
                    height={246}
                  />
                </li>
              </ul>
            </li>
          </ul>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Rows</strong>: Horizontal segments labeled with numbers
              (1, 2, 3, …).
              <img
                className="rounded mx-auto d-block img-fluid ibep-textual-img"
                src="/img/excel/m1v1/image002.png"
                alt=""
                width={439}
                height={246}
              />
            </li>
          </ul>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Cells</strong>: Intersection of rows and columns (e.g.,
              A1, B2).
              <ul>
                <li className="ibep-textual-sub-para">
                  It can contain text, numbers, dates, times, or formulas.
                </li>
                <li className="ibep-textual-sub-para">
                  Identified by a <strong>cell reference</strong> (e.g., A1).
                </li>
                <img
                  className="rounded mx-auto d-block img-fluid ibep-textual-img"
                  src="/img/excel/m1v1/image003.png"
                  alt=""
                  width={348}
                  height={201}
                />
              </ul>
            </li>
          </ul>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Adjusting Rows and Columns</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  <strong>Resize Columns</strong>:
                  <ul>
                    <li className="ibep-textual-sub-para">
                      Place the cursor on the boundary between column headers.
                    </li>
                    <li className="ibep-textual-sub-para">
                      Drag to resize or double-click for AutoFit (adjusts to the
                      longest entry).
                    </li>
                  </ul>
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Resize Rows</strong>:
                  <ul>
                    <li className="ibep-textual-sub-para">
                      Place the cursor on the boundary between row numbers.
                    </li>
                    <li className="ibep-textual-sub-para">
                      Drag to adjust or double-click for AutoFit (adjusts to the
                      tallest entry).
                    </li>
                  </ul>
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Multiple Resizes</strong>: Select multiple
                  rows/columns to adjust their size simultaneously.
                </li>
              </ul>
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>4. Entering Data in Excel</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Steps</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  Click on a cell (e.g., A1) to make it active.
                </li>
                <li className="ibep-textual-sub-para">
                  Enter data (text, numbers, date, etc.) and press "Enter" (to
                  move down) or "Tab" (to move right).
                </li>
              </ul>
            </li>
          </ul>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v1/image005.png"
              alt=""
              width={256}
              height={233}
            />
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v1/image004.png"
              alt=""
              width={325}
              height={225}
            />
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m1v1/image006.png"
              alt=""
              width={293}
              height={284}
            />
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong> AutoFill Feature</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  Enter the first value in a series (e.g., dates or numbers).
                </li>
                <li className="ibep-textual-sub-para">
                  Drag the fill handle (bottom-right corner of the cell) to
                  extend the series.
                </li>
              </ul>
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>5. Key Features of the Excel Interface</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Formula Bar</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  Located above the grid.
                </li>
                <li className="ibep-textual-sub-para">
                  Displays or edits the content of the active cell.
                </li>
                <li className="ibep-textual-sub-para">
                  Helpful in managing complex formulas.
                  <img
                    className="rounded mx-auto d-block img-fluid ibep-textual-img"
                    src="/img/excel/m1v1/image007.png"
                    alt=""
                    width={602}
                    height={174}
                  />
                </li>
              </ul>
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Address Bar (Name Box)</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  Shows the cell reference (e.g., A1) of the active cell.
                </li>
                <li className="ibep-textual-sub-para">
                  It can be used to navigate to specific cells or ranges
                  quickly.
                  <img
                    className="rounded mx-auto d-block img-fluid ibep-textual-img"
                    src="/img/excel/m1v1/image008.png"
                    alt=""
                    width={602}
                    height={172}
                  />
                </li>
              </ul>
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>6. Ribbon (Menu Toolbar)</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              Located at the top of the Excel window, divided into tabs with
              specific functionalities:
              <ul>
                <li className="ibep-textual-sub-para">
                  <strong>Home</strong>: Basic clipboard, formatting, and
                  editing tools.
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Insert</strong>: Add tables, charts, images, shapes,
                  hyperlinks, etc.
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Page Layout</strong>: Adjust print layout settings
                  like margins, orientation, and size.
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Formulas</strong>: Access Excel's function library and
                  tools for formula auditing.
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Data</strong>: Import, sort, filter, and validate
                  data.
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>Review</strong>: Proofreading tools, comments, and
                  worksheet protection.
                </li>
                <li className="ibep-textual-sub-para">
                  <strong>View</strong>: Manage workbook presentation with
                  options like gridlines, freeze panes, and split views.
                  <img
                    className="rounded mx-auto d-block img-fluid ibep-textual-img"
                    src="/img/excel/m1v1/image009.png"
                    alt=""
                    width={602}
                    height={88}
                  />
                </li>
              </ul>
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>7. Excel Workbooks and Sheets</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Workbook</strong>: An Excel file containing multiple
              sheets.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Sheets</strong>: Grids of rows and columns for entering
              data.
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            <strong>8. Saving Excel Files</strong>
          </p>
          <ul>
            <li className="ibep-textual-sub-para">
              <strong>Steps to Save</strong>:
              <ul>
                <li className="ibep-textual-sub-para">
                  Go to the "File" tab in the ribbon.
                </li>
                <li className="ibep-textual-sub-para">
                  Choose the "Save" option.
                </li>
                <li className="ibep-textual-sub-para">
                  Select a location (e.g., "This PC") and provide a file name.
                </li>
              </ul>
            </li>
          </ul>
          <div>
            <hr />
          </div>
          <p className="ibep-textual-para">
            Explore more advanced features and tools as you progress in your
            Excel journey. This provides a basic introduction to Microsoft
            Excel, covering its layout, functionality, and essential tools for
            beginners.
          </p>
        </div>
      </div>
    );
  }
}
XL_M1_V1.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M1_V1);


