import { BARESEARCH_DATA, BARATERESEARCH_DATA,BAGET_NOTIFY_SCORE,BAGET_MAX,BAGET_ELI } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{},
  max:{},
  eli:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case BARESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case BARATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case BAGET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
      case BAGET_MAX:
      return {
        ...state,
        max: action.payload
      };
      case BAGET_ELI:
      return {
        ...state,
        eli: action.payload
      };
    default:
      return state;
  }
}

