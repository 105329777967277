import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M3_V2 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <h1 className="ibep-textual-title">
          <strong>Scenario Building and Analysis in Excel</strong>
        </h1>
        <h2 className="ibep-textual-title">
          <strong>Introduction</strong>
        </h2>
        <p className="ibep-textual-para">
          Scenario Analysis is a powerful tool used to evaluate the impact of
          different future conditions on a business model. By considering
          multiple scenarios, businesses can{" "}
          <strong>
            anticipate risks, identify opportunities, and make informed
            decisions
          </strong>
          .
        </p>
        <p className="ibep-textual-para">
          This method is widely used in{" "}
          <strong>
            financial modeling, strategic planning, and risk management
          </strong>
          , helping companies prepare for uncertainties by modeling best-case,
          worst-case, and base-case scenarios.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>What is Scenario Analysis?</strong>
        </h2>
        <p className="ibep-textual-para">
          Scenario Analysis examines how different{" "}
          <strong>input variables</strong> affect an <strong>outcome</strong> by
          creating and comparing multiple scenarios.
        </p>
        <p className="ibep-textual-para">
          By conducting scenario analysis, we can:
        </p>
        <p className="ibep-textual-para">
          ● <strong>Assess potential risks and opportunities.</strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Understand the impact of changing business conditions.
          </strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Make strategic decisions based on different possible futures.
          </strong>
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Applications of Scenario Analysis</strong>
        </h2>
        <p className="ibep-textual-para">Scenario Analysis is used in:</p>
        <p className="ibep-textual-para">
          ● <strong>Financial Planning</strong> &ndash; Forecasting revenue and
          profit under different conditions.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Investment Analysis</strong> &ndash; Evaluating the impact
          of economic changes on portfolios.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Business Strategy</strong> &ndash; Planning for market
          uncertainties.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Risk Management</strong> &ndash; Identifying potential
          threats to profitability.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>How Does Scenario Analysis Work?</strong>
        </h2>
        <p className="ibep-textual-para">
          Scenario Analysis follows these key steps:
        </p>
        <ol start="1" type="1">
          <li>
            <strong>Defining the scenarios</strong> &ndash; Identifying key
            factors and their potential changes.
          </li>
          <li>
            <strong>Setting up the financial model</strong> &ndash; Structuring
            inputs and calculations in Excel.
          </li>
          <li>
            <strong>Using Scenario Manager in Excel</strong> &ndash; Creating,
            saving, and comparing different scenarios.
          </li>
          <li>
            <strong>Generating a Summary Report</strong> &ndash; Analyzing the
            impact of each scenario.
          </li>
          <li>
            <strong>Interpreting results</strong> &ndash; Drawing key insights
            for decision-making.
          </li>
        </ol>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Practical Implementation in Excel</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>Scenario: Smartphone Manufacturing Company</strong>
        </h3>
        <p className="ibep-textual-para">
          We will conduct Scenario Analysis for a{" "}
          <strong>smartphone manufacturing company</strong> to evaluate how
          different market conditions affect <strong>profitability</strong>.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Step 1: Define the Scenarios</strong>
        </h3>
        <p className="ibep-textual-para">
          We will consider <strong>three market conditions</strong>:
        </p>
        {/* <table border="1" width="602" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td width="86">
                <p className="ibep-textual-para">
                  <strong>Scenario</strong>
                </p>
              </td>
              <td width="258">
                <p className="ibep-textual-para">
                  <strong>Market Condition</strong>
                </p>
              </td>
              <td width="123">
                <p className="ibep-textual-para">
                  <strong>Production Volume</strong>
                </p>
              </td>
              <td width="135">
                <p className="ibep-textual-para">
                  <strong>Material Cost per Unit</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width="86">
                <p className="ibep-textual-para">
                  <strong>Optimistic</strong>
                </p>
              </td>
              <td width="258">
                <p className="ibep-textual-para">
                  Favorable government policies &amp; economic growth
                </p>
              </td>
              <td width="123">
                <p className="ibep-textual-para">20,000 units</p>
              </td>
              <td width="135">
                <p className="ibep-textual-para">$45</p>
              </td>
            </tr>
            <tr>
              <td width="86">
                <p className="ibep-textual-para">
                  <strong>Normal</strong>
                </p>
              </td>
              <td width="258">
                <p className="ibep-textual-para">Stable market conditions</p>
              </td>
              <td width="123">
                <p className="ibep-textual-para">10,000 units</p>
              </td>
              <td width="135">
                <p className="ibep-textual-para">$50</p>
              </td>
            </tr>
            <tr>
              <td width="86">
                <p className="ibep-textual-para">
                  <strong>Pessimistic</strong>
                </p>
              </td>
              <td width="258">
                <p className="ibep-textual-para">
                  Unfavorable policies &amp; reduced demand
                </p>
              </td>
              <td width="123">
                <p className="ibep-textual-para">5,000 units</p>
              </td>
              <td width="135">
                <p className="ibep-textual-para">$55</p>
              </td>
            </tr>
          </tbody>
        </table> */}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Scenario</th>
                <th>Market Condition</th>
                <th>Production Volume</th>
                <th>Material Cost per Unit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Optimistic</strong>
                </td>
                <td>Favorable government policies & economic growth</td>
                <td>20,000 units</td>
                <td>$45</td>
              </tr>
              <tr>
                <td>
                  <strong>Normal</strong>
                </td>
                <td>Stable market conditions</td>
                <td>10,000 units</td>
                <td>$50</td>
              </tr>
              <tr>
                <td>
                  <strong>Pessimistic</strong>
                </td>
                <td>Unfavorable policies & reduced demand</td>
                <td>5,000 units</td>
                <td>$55</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Step 2: Set Up the Model in Excel</strong>
        </h3>
        <ol start="1" type="1">
          <li>
            Open an Excel worksheet and enter the following variables:
            <br />
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v2/image001.png"
              alt=""
              width="262"
              height="328"
              hspace="12"
              vspace="12"
            />
          </li>
        </ol>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Variable</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Production Volume</td>
                <td>10,000</td>
              </tr>
              <tr>
                <td>Material Cost per Unit</td>
                <td>$50</td>
              </tr>
              <tr>
                <td>Fixed Costs</td>
                <td>$200,000</td>
              </tr>
              <tr>
                <td>Sales Price per Unit</td>
                <td>$150</td>
              </tr>
              <tr>
                <td>Net Profit Formula</td>
                <td>
                  <strong>
                    = (Sales Price - Material Cost) × Production Volume - Fixed
                    Costs
                  </strong>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>
            Step 3: Perform Scenario Analysis Using Excel&rsquo;s "Scenario
            Manager"
          </strong>
        </h3>
        <p className="ibep-textual-para">
          To analyze how different scenarios affect{" "}
          <strong>profitability</strong>, we use{" "}
          <strong>Excel&rsquo;s Scenario Manager</strong>.
        </p>
        <h4>
          <strong>How to Create Scenarios in Excel</strong>
        </h4>
        <ol start="1" type="1">
          <li>
            <strong>Open the Excel file</strong> and navigate to the financial
            model.
          </li>
          <li>
            <strong>Go to</strong>:{" "}
            <strong>
              Data &rarr; What-If Analysis &rarr; Scenario Manager
            </strong>
            .
          </li>
        </ol>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image002.png"
            alt=""
            width="602"
            height="156"
          />
        </p>
        <ol start="3" type="1">
          <li>
            Click <strong>Add</strong> to create a new scenario:
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ <strong>Scenario Name</strong>: <strong>Optimistic</strong>
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Changing Cells</strong>: Select{" "}
          <strong>
            Material Cost per Unit (F5) &amp; Production Volume (F6)
          </strong>
        </p>
        <p className="ibep-textual-para">
          ○ <strong>New Values</strong>: Material Cost = <strong>$45</strong>,
          Production Volume = <strong>20,000</strong>
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image003.png"
            alt=""
            width="335"
            height="283"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image004.png"
            alt=""
            width="304"
            height="146"
            hspace="12"
            vspace="12"
          />
        </p>
        <ol start="4" type="1">
          <li>
            Click <strong>Add</strong> again to create the{" "}
            <strong>Normal Scenario</strong>:
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ <strong>Keep values unchanged</strong> (Material Cost ={" "}
          <strong>$50</strong>, Production Volume = <strong>10,000</strong>).
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image005.png"
            alt=""
            width="387"
            height="324"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image006.png"
            alt=""
            width="301"
            height="147"
            hspace="12"
            vspace="12"
          />
        </p>
        <ol start="5" type="1">
          <li>
            Click <strong>Add</strong> for the{" "}
            <strong>Pessimistic Scenario</strong>:
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ <strong>New Values</strong>: Material Cost = <strong>$55</strong>,
          Production Volume = <strong>5,000</strong>.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image007.png"
            alt=""
            width="383"
            height="326"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v2/image008.png"
            alt=""
            width="303"
            height="147"
            hspace="12"
            vspace="12"
          />
        </p>
        <ol start="6" type="1">
          <li>
            Click <strong>OK</strong> to save all scenarios.
          </li>
        </ol>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Step 4: Generate a Scenario Summary Report</strong>
        </h3>
        <ol start="1" type="1">
          <li>
            <strong>In the Scenario Manager</strong>, click{" "}
            <strong>Summary</strong>.
          </li>
          <li>
            <strong>Select the result cells</strong> (Net Profit &amp; Revenue).
          </li>
          <li>
            Click <strong>OK</strong> to generate a{" "}
            <strong>Scenario Summary Table</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v2/image009.png"
              alt=""
              width="602"
              height="249"
              hspace="12"
              vspace="12"
            />
          </li>
        </ol>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Step 5: Analyze the Scenario Summary Table</strong>
        </h3>
        <p className="ibep-textual-para">
          The summary table compares how <strong>Net Profit and Revenue</strong>{" "}
          change under each scenario.
        </p>
        {/* <table border="1" width="578" cellspacing="0" cellpadding="0">
          <tbody>
            <tr>
              <td width="119">
                <p className="ibep-textual-para">
                  <strong>Scenario</strong>
                </p>
              </td>
              <td width="147">
                <p className="ibep-textual-para">
                  <strong>Production Volume</strong>
                </p>
              </td>
              <td width="110">
                <p className="ibep-textual-para">
                  <strong>Material Cost</strong>
                </p>
              </td>
              <td width="113">
                <p className="ibep-textual-para">
                  <strong>Total Revenue</strong>
                </p>
              </td>
              <td width="87">
                <p className="ibep-textual-para">
                  <strong>Net Profit</strong>
                </p>
              </td>
            </tr>
            <tr>
              <td width="119">
                <p className="ibep-textual-para">
                  <strong>Current Values</strong>
                </p>
              </td>
              <td width="147">
                <p className="ibep-textual-para">10,000</p>
              </td>
              <td width="110">
                <p className="ibep-textual-para">$50</p>
              </td>
              <td width="113">
                <p className="ibep-textual-para">$1,500,000</p>
              </td>
              <td width="87">
                <p className="ibep-textual-para">$700,000</p>
              </td>
            </tr>
            <tr>
              <td width="119">
                <p className="ibep-textual-para">
                  <strong>Optimistic</strong>
                </p>
              </td>
              <td width="147">
                <p className="ibep-textual-para">20,000</p>
              </td>
              <td width="110">
                <p className="ibep-textual-para">$45</p>
              </td>
              <td width="113">
                <p className="ibep-textual-para">$3,000,000</p>
              </td>
              <td width="87">
                <p className="ibep-textual-para">$1,600,000</p>
              </td>
            </tr>
            <tr>
              <td width="119">
                <p className="ibep-textual-para">
                  <strong>Normal</strong>
                </p>
              </td>
              <td width="147">
                <p className="ibep-textual-para">10,000</p>
              </td>
              <td width="110">
                <p className="ibep-textual-para">$50</p>
              </td>
              <td width="113">
                <p className="ibep-textual-para">$1,500,000</p>
              </td>
              <td width="87">
                <p className="ibep-textual-para">$700,000</p>
              </td>
            </tr>
            <tr>
              <td width="119">
                <p className="ibep-textual-para">
                  <strong>Pessimistic</strong>
                </p>
              </td>
              <td width="147">
                <p className="ibep-textual-para">5,000</p>
              </td>
              <td width="110">
                <p className="ibep-textual-para">$55</p>
              </td>
              <td width="113">
                <p className="ibep-textual-para">$750,000</p>
              </td>
              <td width="87">
                <p className="ibep-textual-para">$275,000</p>
              </td>
            </tr>
          </tbody>
        </table> */}
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Scenario</th>
                <th>Production Volume</th>
                <th>Material Cost</th>
                <th>Total Revenue</th>
                <th>Net Profit</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <strong>Current Values</strong>
                </td>
                <td>10,000</td>
                <td>$50</td>
                <td>$1,500,000</td>
                <td>$700,000</td>
              </tr>
              <tr>
                <td>
                  <strong>Optimistic</strong>
                </td>
                <td>20,000</td>
                <td>$45</td>
                <td>$3,000,000</td>
                <td>$1,600,000</td>
              </tr>
              <tr>
                <td>
                  <strong>Normal</strong>
                </td>
                <td>10,000</td>
                <td>$50</td>
                <td>$1,500,000</td>
                <td>$700,000</td>
              </tr>
              <tr>
                <td>
                  <strong>Pessimistic</strong>
                </td>
                <td>5,000</td>
                <td>$55</td>
                <td>$750,000</td>
                <td>$275,000</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Interpreting Results</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>1. Optimistic Scenario (Best-Case)</strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>Production doubles</strong>,{" "}
          <strong>material costs decrease</strong> &rarr;{" "}
          <strong>Higher revenue &amp; profit</strong>.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Net Profit increases to $1,600,000</strong>,{" "}
          <strong>showing</strong>
          <strong>significant growth potential</strong>.
        </p>
        <h3 className="ibep-textual-title">
          <strong>2. Normal Scenario (Base-Case)</strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>Market conditions remain unchanged</strong>.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Revenue stays at $1,500,000</strong>,
          <strong> and Net Profit at $700,000</strong>.
        </p>
        <p className="ibep-textual-para">
          ● <strong>No major risks or opportunities</strong>
          <strong>are present.</strong>
        </p>
        <h3 className="ibep-textual-title">
          <strong>3. Pessimistic Scenario (Worst-Case)</strong>
        </h3>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Lower demand &amp; increased costs &rarr; Reduced profitability
          </strong>
          .
        </p>
        <p className="ibep-textual-para">
          ● <strong>Net Profit drops to $275,000</strong>,{" "}
          <strong>indicating</strong>
          <strong>high financial risk</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Key Insights</strong>
        </h2>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Material Costs &amp; Production Volume are key drivers of
            profitability.
          </strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Scenario Planning helps businesses anticipate risks and
            opportunities.
          </strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            A well-prepared business strategy should include contingency plans.
          </strong>
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Conclusion</strong>
        </h2>
        <p className="ibep-textual-para">
          Scenario Analysis is a crucial tool that helps businesses prepare for
          different market conditions. By modeling{" "}
          <strong>Optimistic, Normal, and Pessimistic Scenarios</strong>,
          companies can:
        </p>
        <p className="ibep-textual-para">
          ● <strong>Mitigate financial risks</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Identify strategic opportunities</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Make data-driven business decisions</strong>
        </p>
        <p className="ibep-textual-para">
          With <strong>Excel&rsquo;s Scenario Manager</strong>, implementing
          Scenario Analysis is simple and effective, ensuring businesses are
          well-prepared for the future.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
      </div>
    );
  }
}
XL_M3_V2.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M3_V2);
