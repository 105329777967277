import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M4_V4 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <h1 className="ibep-textual-title">
          <strong>Refining a Multi-Linear Regression Model in Excel</strong>
        </h1>
        <h2 className="ibep-textual-title">
          <strong>Introduction</strong>
        </h2>
        <p className="ibep-textual-para">
          Multiple linear regression is a statistical technique that helps in
          understanding the relationship between one dependent variable and
          multiple independent variables.
        </p>
        <p className="ibep-textual-para">
          While an initial regression model might contain several predictors,{" "}
          <strong>
            not all variables contribute significantly to the model
          </strong>
          . Refining the model involves{" "}
          <strong>removing insignificant variables</strong> to improve accuracy,
          reduce complexity, and enhance predictive power.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Why Refine a Regression Model?</strong>
        </h3>
        <p className="ibep-textual-para">
          ● To <strong>remove insignificant predictors</strong> that do not
          contribute meaningfully.
        </p>
        <p className="ibep-textual-para">
          ● To <strong>improve model efficiency</strong> by focusing only on the
          strongest variables.
        </p>
        <p className="ibep-textual-para">
          ● To <strong>avoid overfitting</strong>, ensuring the model
          generalizes well to new data.
        </p>
        <p className="ibep-textual-para">
          ● To <strong>simplify interpretation</strong>, making results easier
          to communicate.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 1: Initial Data Analysis</strong>
        </h2>
        <p className="ibep-textual-para">
          We will refine a regression model using a{" "}
          <strong>housing dataset</strong> where the goal is to predict{" "}
          <strong>Sales Price</strong> based on multiple factors.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Dataset Variables</strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>Dependent Variable:</strong>
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Sales Price</strong> &ndash; The price of a house in
          dollars.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Independent Variables:</strong>
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Square Feet</strong> &ndash; The total area of the house.
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Stories</strong> &ndash; Number of floors in the house.
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Bathrooms</strong> &ndash; Number of bathrooms.
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Age</strong> &ndash; Age of the house in years.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Setting Up the Model in Excel</strong>
        </h3>
        <ol start="1" type="1">
          <li>
            Open the dataset in Excel and{" "}
            <strong>enable Analysis ToolPak</strong> by going to:
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○{" "}
          <strong>
            File &rarr; Options &rarr; Add-ins &rarr; Manage Excel Add-ins
            &rarr; Check Analysis ToolPak &rarr; OK{" "}
          </strong>
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image001.png"
            alt=""
            width="602"
            height="493"
            hspace="12"
            vspace="12"
          />
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image002.png"
            alt=""
            width="287"
            height="396"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <ol start="2" type="1">
          <li>
            Go to the{" "}
            <strong>Data tab &rarr; Data Analysis &rarr; Regression</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m4v4/image003.png"
              alt=""
              width="702"
              height="158"
              hspace="12"
              vspace="12"
            />
          </li>
          <li>Select:</li>
        </ol>
        <p className="ibep-textual-para">
          ○ <strong>Input Y Range</strong> &rarr; The Sales Price column.
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Input X Range</strong> &rarr; The independent variable
          columns (Square Feet, Stories, Bathrooms, Age).
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Check Labels</strong> if headers are included.
        </p>
        <ol start="4" type="1">
          <li>
            Choose an <strong>Output Range</strong> for the regression results.
          </li>
          <li>
            Click <strong>OK</strong> to run the analysis.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m4v4/image004.png"
              alt=""
              width="411"
              height="369"
              hspace="12"
              vspace="12"
            />
          </li>
        </ol>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 2: Reviewing the Initial Model</strong>
        </h2>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image005.png"
            alt=""
            width="602"
            height="355"
          />
        </p>
        <p className="ibep-textual-para">
          After running the regression, key statistics to evaluate include:
        </p>
        <h3 className="ibep-textual-title">
          <strong>1. Regression Statistics</strong>
        </h3>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Meaning</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Multiple R</td>
                <td>
                  Measures correlation strength (close to 1 indicates a strong
                  model).
                </td>
              </tr>
              <tr>
                <td>R² (R-Square)</td>
                <td>
                  Shows how well the independent variables explain the dependent
                  variable (higher is better).
                </td>
              </tr>
              <tr>
                <td>Adjusted R²</td>
                <td>
                  Adjusts R² for the number of predictors to prevent
                  overfitting.
                </td>
              </tr>
              <tr>
                <td>Significance F</td>
                <td>
                  Indicates overall model significance (should be &lt; 0.05).
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p className="ibep-textual-para">
          ● In our case, <strong>R&sup2; = 0.9525</strong> means 95.25% of the
          variation in <strong>Sales Price</strong> is explained by the model.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Significance F = 0.0001</strong>, which is very small,
          confirming that the model is statistically significant.
        </p>
        <h3 className="ibep-textual-title">
          <strong>2. Evaluating P-Values for Individual Variables</strong>
        </h3>
        <p className="ibep-textual-para">
          Each independent variable has a <strong>P-value</strong> that
          determines its statistical significance.
        </p>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Variable</th>
                <th>P-Value</th>
                <th>Interpretation</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Square Feet</td>
                <td>0.073</td>
                <td>Borderline significant</td>
              </tr>
              <tr>
                <td>Stories</td>
                <td>0.625</td>
                <td>Not significant (p &gt; 0.05)</td>
              </tr>
              <tr>
                <td>Bathrooms</td>
                <td>0.079</td>
                <td>Borderline significant</td>
              </tr>
              <tr>
                <td>Age</td>
                <td>0.322</td>
                <td>Not significant (p &gt; 0.05)</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p className="ibep-textual-para">
          <strong>Insight:</strong>
        </p>
        <p className="ibep-textual-para">
          ● Variables with <strong>P-value &gt; 0.05</strong> do not
          significantly contribute to the model.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Stories and Age</strong> have high P-values and should be
          removed first.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 3: Refining the Model</strong>
        </h2>
        <p className="ibep-textual-para">
          We will refine the model by{" "}
          <strong>removing the least significant variable first</strong> and
          rerunning the regression.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Iteration 1: Removing "Stories"</strong>
        </h3>
        <ol start="1" type="1">
          <li>
            <strong>Copy data to a new sheet ("Data Set 2")</strong>.
          </li>
          <li>
            <strong>Delete the "Stories" column</strong>.
          </li>
          <li>
            <strong>Run regression again</strong> with the remaining variables.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image006.png"
            alt=""
            width="371"
            height="492"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image007.png"
            alt=""
            width="602"
            height="373"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4 className="ibep-textual-title">
          <strong>New Results:</strong>
        </h4>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adjusted R&sup2;</td>
                <td>
                  <strong>0.9334</strong> (increased)
                </td>
              </tr>
              <tr>
                <td>Significance F</td>
                <td>
                  <strong>1.32e-05</strong> (still significant)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            The model is still strong, and Adjusted R&sup2; has improved
          </strong>
          , meaning it is now{" "}
          <strong>more accurate with fewer variables</strong>.
        </p>
        <p className="ibep-textual-para">
          ● However, <strong>Age still has a high P-value (0.322)</strong>, so
          it should be removed next.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Iteration 2: Removing "Age"</strong>
        </h3>
        <ol start="1" type="1">
          <li>
            <strong>Copy data to "Data Set 3"</strong>.
          </li>
          <li>
            <strong>Delete the "Age" column</strong>.
          </li>
          <li>
            <strong>Run regression again</strong> with only{" "}
            <strong>Square Feet and Bathrooms</strong>.
          </li>
        </ol>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image008.png"
            alt=""
            width="566"
            height="494"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <p className="ibep-textual-para">&nbsp;</p>
        <h4 className="ibep-textual-title">&nbsp;</h4>
        <h4 className="ibep-textual-title">&nbsp;</h4>
        <h4 className="ibep-textual-title">&nbsp;</h4>
        <h4 className="ibep-textual-title">&nbsp;</h4>
        <h4 className="ibep-textual-title">&nbsp;</h4>
        <h4 className="ibep-textual-title">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image009.png"
            alt=""
            width="602"
            height="372"
            hspace="12"
            vspace="12"
          />
        </h4>
        <h4 className="ibep-textual-title">&nbsp;</h4>
        <h4 className="ibep-textual-title">
          <strong>New Results:</strong>
        </h4>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Adjusted R&sup2;</td>
                <td>
                  <strong>0.9325</strong> (still high)
                </td>
              </tr>
              <tr>
                <td>Significance F</td>
                <td>
                  <strong>2.18e-06</strong> (very small, still valid)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p className="ibep-textual-para">
          ●{" "}
          <strong>Square Feet is now highly significant (P &lt; 0.005)</strong>.
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Bathrooms is still borderline (P = 0.1059), so we should remove it
            next.
          </strong>
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Iteration 3: Removing "Bathrooms"</strong>
        </h3>
        <ol start="1" type="1">
          <li>
            <strong>Copy data to "Data Set 4"</strong>.
          </li>
          <li>
            <strong>Delete the "Bathrooms" column</strong>.
          </li>
          <li>
            <strong>Run final regression with only "Square Feet"</strong> as the
            independent variable.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image010.png"
            alt=""
            width="602"
            height="423"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image011.png"
            alt=""
            width="602"
            height="344"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4 className="ibep-textual-title">
          <strong>Final Regression Results:</strong>
        </h4>
        <div className="table-container">
          <table>
            <thead>
              <tr>
                <th>Metric</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Multiple R</td>
                <td>
                  <strong>0.9618</strong> (strong correlation)
                </td>
              </tr>
              <tr>
                <td>R&sup2;</td>
                <td>
                  <strong>0.9250</strong> (still high)
                </td>
              </tr>
              <tr>
                <td>Adjusted R&sup2;</td>
                <td>
                  <strong>0.9175</strong>
                </td>
              </tr>
              <tr>
                <td>Significance F</td>
                <td>
                  <strong>6.03e-07</strong> (highly significant)
                </td>
              </tr>
              <tr>
                <td>Square Feet P-Value</td>
                <td>
                  <strong>&lt; 0.005</strong> (highly significant)
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <p className="ibep-textual-para">
          ● <strong>The final model is simple yet powerful</strong>, with only{" "}
          <strong>Square Feet</strong> explaining most of the variation in{" "}
          <strong>Sales Price</strong>.
        </p>
        <h4 className="ibep-textual-title">
          <strong>Final Regression Equation:</strong>
        </h4>
        <p className="ibep-textual-para">
          Sales Price = 3.9041 + 5.5619 &times; (Square Feet)
        </p>

        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 4: Visualizing the Final Model</strong>
        </h2>
        <p className="ibep-textual-para">
          To visualize the relationship between{" "}
          <strong>Sales Price and Square Feet</strong>:
        </p>
        <ol start="1" type="1">
          <li>
            <strong>Highlight the data for Square Feet and Sales Price</strong>.
          </li>
          <li>
            <strong>Go to Insert &rarr; Charts &rarr; Scatter Plot</strong>.
          </li>
          <li>
            <strong>Add a Trendline</strong>:
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ Right-click on a data point and select{" "}
          <strong>"Add Trendline"</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Check <strong>"Display Equation on Chart"</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Check <strong>"Display R&sup2; Value"</strong>.
        </p>
        <p className="ibep-textual-para">
          The <strong>trendline equation</strong> will match our regression
          model and confirm its accuracy.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v4/image012.png"
            alt=""
            width="439"
            height="260"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Conclusion</strong>
        </h2>
        <h2 className="ibep-textual-title">
          <strong>Key Learnings from Refining the Model:</strong>
        </h2>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Removing insignificant variables improves Adjusted R&sup2;, making
            the model more reliable.
          </strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            A simple model with fewer predictors can be just as effective as a
            complex one.
          </strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Square Feet was the most significant predictor of Sales Price.
          </strong>
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            The final model is highly interpretable and avoids unnecessary
            complexity.
          </strong>
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
      </div>
    );
  }
}
XL_M4_V4.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M4_V4);
