import {
  NEWRESOURCE_VIDEOQUESTIONS,
  NEWRESOURCE_VIDEOFETCH_SCORE
} from "../actions/types";

const initialState = {
  vquestions: {},
  vscores: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case NEWRESOURCE_VIDEOQUESTIONS:
      return {
        ...state,
        vquestions: action.payload
      };

    case NEWRESOURCE_VIDEOFETCH_SCORE:
      return {
        ...state,
        vscores: action.payload
      };

    default:
      return state;
  }
}
