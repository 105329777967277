import { FNORESEARCH_DATA, FNORATERESEARCH_DATA, FNOGET_NOTIFY_SCORE, FNOGET_MAX, FNOGET_ELI } from "../actions/types";

const initialState = {
    data: {},
    rate_research: {},
    notify: {},
    max: {},
    eli: {}

};

export default function (state = initialState, action) {
    switch (action.type) {
        case FNORESEARCH_DATA:
            return { ...state, data: action.payload.data, ratings: action.payload.ratings, rated: action.payload.rated };
        case FNORATERESEARCH_DATA:
            return {
                ...state,
                rate_research: action.payload
            };
        case FNOGET_NOTIFY_SCORE:
            return {
                ...state,
                notify: action.payload
            };
        case FNOGET_MAX:
            return {
                ...state,
                max: action.payload
            };
        case FNOGET_ELI:
            return {
                ...state,
                eli: action.payload
            };
        default:
            return state;
    }
}

