import { BABASICRESEARCH_DATA, BABASICRATERESEARCH_DATA,BABASICGET_NOTIFY_SCORE,BABASICGET_MAX,BABASICGET_ELI } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{},
  max:{},
  eli:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case BABASICRESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case BABASICRATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case BABASICGET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
      case BABASICGET_MAX:
      return {
        ...state,
        max: action.payload
      };
      case BABASICGET_ELI:
      
      return {
        ...state,
        eli: action.payload
      };
    default:
      return state;
  }
}

