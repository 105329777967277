import { SSEPRESEARCH_DATA, SSEPRATERESEARCH_DATA,SSEPGET_NOTIFY_SCORE,SSEPGET_MAX,SSEPGET_ELI } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{},
  max:{},
  eli:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case SSEPRESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case SSEPRATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case SSEPGET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
      case SSEPGET_MAX:
      return {
        ...state,
        max: action.payload
      };
      case SSEPGET_ELI:
      return {
        ...state,
        eli: action.payload
      };
    default:
      return state;
  }
}

