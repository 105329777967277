import React from "react";
import { Nav, NavItem, NavLink, UncontrolledCollapse } from "reactstrap";

class Sidebar extends React.Component {
  state = {
    showComponent: false,
  };

  onButtonClick = () => {
    this.setState({
      showComponent: true,
    });
  };

  render() {
    return (
      <div className="resource">
        <Nav vertical className="sidebar">
          <NavItem>
            <NavLink
              onClick={() => {
                this.props.onSelect("xl-m1-v1");
              }}
              className={this.props.active == "xl-m1-v1" ? "active" : ""}
              id="toggler1"
            >
              A Primer of Excel
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler1"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m1-v1");
                    }}
                    className={
                      this.props.active == "xl-m1-v1" ? "activeSub" : ""
                    }
                  >
                    Introduction to Excel
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m1-v2");
                    }}
                    className={
                      this.props.active == "xl-m1-v2" ? "activeSub" : ""
                    }
                  >
                    Working with data in Excel
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m1-v3");
                    }}
                    className={
                      this.props.active == "xl-m1-v3" ? "activeSub" : ""
                    }
                  >
                    Data tools in Excel
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m1-v4");
                    }}
                    className={
                      this.props.active == "xl-m1-v4" ? "activeSub" : ""
                    }
                  >
                    Formulas and Functions in Excel
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>

          <NavItem>
            <NavLink
              id="toggler2"
              onClick={() => {
                this.props.onSelect("xl-m2-v1");
              }}
              className={this.props.active == "xl-m2-v1" ? "active" : ""}
            >
              Interactive Dashboard
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler2"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m2-v1");
                    }}
                    className={
                      this.props.active == "xl-m2-v1" ? "activeSub" : ""
                    }
                  >
                    Interactive HR Insights using Pivot Table
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler3"
              onClick={() => {
                this.props.onSelect("xl-m3-v1");
              }}
              className={this.props.active == "xl-m3-v1" ? "active" : ""}
            >
              Simulations
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler3"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m3-v1");
                    }}
                    className={
                      this.props.active == "xl-m3-v1" ? "activeSub" : ""
                    }
                  >
                    Sensitivity Analysis
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m3-v2");
                    }}
                    className={
                      this.props.active == "xl-m3-v2" ? "activeSub" : ""
                    }
                  >
                    Scenario Building
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m3-v3");
                    }}
                    className={
                      this.props.active == "xl-m3-v3" ? "activeSub" : ""
                    }
                  >
                    Monte Carlo Simulation
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m3-v4");
                    }}
                    className={
                      this.props.active == "xl-m3-v4" ? "activeSub" : ""
                    }
                  >
                    Forecasting
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
          <NavItem>
            <NavLink
              id="toggler4"
              onClick={() => {
                this.props.onSelect("xl-m4-v1");
              }}
              className={this.props.active == "xl-m4-v1" ? "active" : ""}
            >
              Predictive Modelling
            </NavLink>
            <UncontrolledCollapse
              toggler="#toggler4"
              className="collapse-items"
            >
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m4-v1");
                    }}
                    className={
                      this.props.active == "xl-m4-v1" ? "activeSub" : ""
                    }
                  >
                    Regression Overview
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m4-v2");
                    }}
                    className={
                      this.props.active == "xl-m4-v2" ? "activeSub" : ""
                    }
                  >
                    Simple Linear Regression
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m4-v3");
                    }}
                    className={
                      this.props.active == "xl-m4-v3" ? "activeSub" : ""
                    }
                  >
                    Multiple Linear Regression
                  </NavLink>
                </NavItem>
              </Nav>
              <Nav navbar>
                <NavItem>
                  <NavLink
                    onClick={() => {
                      this.props.onSelect("xl-m4-v4");
                    }}
                    className={
                      this.props.active == "xl-m4-v4" ? "activeSub" : ""
                    }
                  >
                    Refining a Multilinear Regression Model
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </NavItem>
        </Nav>
      </div>
    );
  }
}

export default Sidebar;
