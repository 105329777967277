import React, { Component, Suspense, lazy } from "react";
import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Provider } from "react-redux";
import store from "./store";
import { loadInitialDataSocket } from "./actions/socketActions";
import Loader from "./components/Loader";
import { verifyToken, logout } from "./actions/authActions";
import ExcelNotes from "./components/ExcelNotes";
import Shape from "./components/shape";
import Header from "./components/Header";
import Main from "./components/Main";
import Ticker from "./components/Ticker";
import ErrorBoundary from "./components/ErrorBoundary";
import ErrorPage from "./components/ErrorPage";


// Lazy loading components
const Notification = lazy(() => import("./components/Notification"));
const Lifesession = lazy(() => import("./components/Lifesession"));
const Dashboard = lazy(() => import("./components/Dashboard"));
const PostSignup = lazy(() => import("./components/PostSignup"));
const Newresearch = lazy(() => import("./components/Newresearch"));
const FaResearch = lazy(() => import("./components/FaResearch"));
const TextModule = lazy(() => import("./components/TextMoudle"));
const BANotes = lazy(() => import("./components/BANotes"));
const SSNotes = lazy(() => import("./components/SSNotes"));
const Portfolio = lazy(() => import("./components/Portfolio"));
const Company = lazy(() => import("./components/Company"));
const Games = lazy(() => import("./components/Games"));
const GamesPerformance = lazy(() => import("./components/Games/games_performance"));
const Stats = lazy(() => import("./components/Stats"));
const Research = lazy(() => import("./components/Research"));
const Resource = lazy(() => import("./components/Resource"));
const Profile = lazy(() => import("./components/Profile"));
const FAQs = lazy(() => import("./components/FAQs"));
const ResearchComponent = lazy(() => import("./components/ResearchComponent"));
const ResourceComponent = lazy(() => import("./components/ResourceComponent"));
const BeginnerResource = lazy(() => import("./components/ResourceComponent/BeginnerResource"));
const Interview = lazy(() => import("./components/Interview"));
const FnONotes = lazy(() => import("./components/FnONotes"));
const DSNotes = lazy(() => import("./components/DSNOTES"));
const MLNotes = lazy(() => import("./components/MLNotes"));
const EXCELNOTES = lazy(() => import("./components/ExcelNotes"))

class App extends Component {
  componentDidMount() {
    if (window.user_token) {
      store.dispatch(verifyToken(window.user_token));
      store.dispatch(loadInitialDataSocket(window.user_token));
      window.$("#ticker1").jStockTicker({ interval: 10 });
      window.$("#ticker2").jStockTicker({ interval: 15 });
    } else {
      store.dispatch(logout());
    }
  }

  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <Shape />
            <Header />
            <Loader />
            <Main />
              
              <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                <Route path="/signup" component={PostSignup} />
                <Route path="/dashboard" component={Dashboard} />
                <Route path="/notifications" component={Notification} />
                <Route exact path="/portfolio" component={Portfolio} />
                <Route exact path="/company" component={Company} />
                <Route exact path="/stats" component={Stats} />
                <Route path="/games/performance" component={GamesPerformance} />
                <Route path="/games" component={Games} />
                <Route path="/research" component={Research} />
                <Route exact path="/newresearch" component={Newresearch} />
                <Route exact path="/faresearch" component={FaResearch} />
                <Route exact path="/note" component={TextModule} />
                <Route exact path="/lifesession" component={Lifesession} />
                <Route exact path="/ivepresearch" component={ResearchComponent} />
                <Route path="/resource" component={Resource} />
                <Route path="/newresource" component={ResourceComponent} />
                <Route path="/beginerresource" component={BeginnerResource} />
                <Route path="/banotes" component={BANotes} />
                <Route path="/ssepnotes" component={SSNotes} />
                <Route path="/MLNotes" component={MLNotes} />

                <Route path="/profile" component={Profile} />

                <Route path="/faq" component={FAQs} />
                <Route path="/FnOnotes" component={FnONotes} />
                <Route path="/DSNOTES" component={DSNotes} />
                <Route path="/EXCELNOTES" component={EXCELNOTES} />
                <Route path="/mockinterview" component={Interview} />

                </Switch>
                {/* <Switch>
                  <Route path="/signup" component={() => (
                      // <ErrorBoundary fallback={<ErrorPage servicename = "signup"/>}>
                        <PostSignup/>
                      // </ErrorBoundary> 
                  )} 
                  />
                  <Route path="/dashboard" component={ () => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "dashboard"/>}>
                      <Dashboard /> 
                    </ErrorBoundary> )}
                  />
                  <Route path="/notifications" component={ () => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "notification"/>}>
                      <Notification />
                    </ErrorBoundary>
                  )} 
                  />
                  <Route exact path="/portfolio" component={() => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "portfolio"/>}>  
                      <Portfolio />
                    </ErrorBoundary>  
                  )} 
                  />
                  <Route exact path="/company" component={() => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "company"/>}>
                      <Company />
                    </ErrorBoundary>  
                  )} 
                  />
                  <Route exact path="/stats" component={() => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "statistics"/>}>
                      <Stats />
                    </ErrorBoundary>
                  )} 
                  />
                  <Route path="/games/performance" component={() => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "games performace"/>}>
                      <GamesPerformance />
                    </ErrorBoundary>  
                    )} 
                  />
                  <Route path="/games" component={() => (
                    <ErrorBoundary fallback={<ErrorPage servicename = "games"/>}>
                      <Games />
                    </ErrorBoundary>
                  )} 
                    />
                    <Route path="/research" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "research"/>}>
                        <Research />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route exact path="/newresearch" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "research"/>}>
                        <Newresearch />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route exact path="/faresearch" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "research"/>}>
                        <FaResearch />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route exact path="/note" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "notes"/>}>
                        <TextModule />
                      </ErrorBoundary>  
                      )} 
                    />
                    <Route exact path="/lifesession" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "liveSession"/>}>
                        <Lifesession />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route exact path="/ivepresearch" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "research"/>}>
                        {/* <ErrorPage servicename = "Research"/> */}
                        {/* <ResearchComponent/>
                      </ErrorBoundary>
                    )} />
                    <Route path="/resource" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "resource"/>}>
                        <Resource />
                      </ErrorBoundary>  
                    )} />
                    <Route path="/newresource" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "resource"/>}>
                        <ResourceComponent />
                        {/* <ErrorPage servicename = "Mock Interview"/>                         */}
                      {/* </ErrorBoundary>
                    )} />
                    <Route path="/beginerresource" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "beginner resource"/>}>
                        <BeginnerResource />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/banotes" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "notes"/>}>
                        <BANotes />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/ssepnotes" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "notes"/>}>
                        <SSNotes />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/MLNotes" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "notes"/>}>
                        <MLNotes />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/profile" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "user profile"/>}>
                        <Profile />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/faq" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "faq"/>}>
                        <FAQs />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/FnOnotes" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "notes"/>}>
                        <FnONotes />
                      </ErrorBoundary>  
                        )} 
                    />
                    <Route path="/DSNOTES" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "notes"/>}>
                        <DSNotes />
                      </ErrorBoundary>
                      )} 
                    />
                    <Route path="/mockinterview" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "mock interview"/>}>
                        <Interview />
                      </ErrorBoundary>
                      )} 
                    /> */}
                    {/* <Route path="/errorpage" component={() => (
                      <ErrorBoundary fallback={<ErrorPage servicename = "Mock Interview"/>}>
                        <ErrorPage servicename = "Mock Interview"/>
                      </ErrorBoundary>
                      )} 
                    /> */}
                {/* </Switch>  */}
              </Suspense>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            <Ticker />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;