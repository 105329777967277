import React, { Component } from "react";
import { Redirect, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

class Main extends Component {
  constructor() {
    super();
  }

  render() {
    const { info } = this.props.user;

    const validPaths = [
      "/note",
      "/BANotes",
      "/FnONotes",
      "/beginerresource",
      "/MLNOTES",
      "/games",
      "/ssepnotes",
      "/mockinterview",
      "/faq"
    ];

    const validPathspython = [
      "/note",
      "/BANotes",
      "/FnONotes",
      "/beginerresource",
      "/MLNOTES",
      "/games",
      "/ssepnotes",
      "/mockinterview",
      "/ivepresearch",
      "/stats",
      "/lifesession"
    ];

    const validPathsfno = [
      "/note",
      "/BANotes",
      "/lifesession",
      "/MLNOTES",
      "/DSNOTES",
      "/ssepnotes",
      "/faq"
    ];
    const validPathsBAGOLD = [
      "/note",
      "/MLNOTES",
      "/FnONotes",
      "/DSNOTES",
      "/ssepnotes",
      "/beginerresource",
      "/mockinterview"
    ];
  


    let url = "";

    if (info.cprogram != "FMEPGOLD" && info.cprogram != "FAFMEP" && info.cprogram != "FMEPSILVER" && info.cprogram != "FMEPPLATINUM") {

      switch (info.post_signup_steps) {
        case 0:
          url = "/signup/step1";
          break;
        case 1:
          url = "/newresource";
          break;
        case 2:
          url = "/newresource";
        break;  
      }
      // if(info.cprogram == "IBEPGOLD" || info.cprogram == "IBEPSILVER" || info.cprogram == "BAGOLD" || info.cprogram == "BA" || info.cprogram == "SSEP" || info.cprogram == "SSEPGOLD" || info.cprogram == "IBEPGOLD"){
      //   if(this.props.location.pathname == "/games"){
      //     return <Redirect to={"/games"} />;
      //   }
      // }
      

      if (info.cprogram == "IBEPGOLD") {

        if (this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/ssepnotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/MLNOTES" || this.props.location.pathname == "/DSNOTES" || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "IBEPSILVER") {

        if (this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/ssepnotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/MLNOTES" || this.props.location.pathname == "/DSNOTES" || this.props.location.pathname == "/mockinterview" || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "BA" || info.cprogram == "BABASIC") {
        // if (this.props.location.pathname == "/note" || this.props.location.pathname == "/ssepnotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/MLNOTES" || this.props.location.pathname == "/DSNOTES") {
          if(validPathsBAGOLD.includes(this.props.location.pathname) || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/EXCELNOTES"){  
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }


      if (info.cprogram == "BAGOLD") {
        // if (this.props.location.pathname == "games/MLNotes" || this.props.location.pathname == "/note" || this.props.location.pathname == "/ssepnotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/DSNOTES") {
        if(validPathsBAGOLD.includes(this.props.location.pathname) || this.props.location.pathname == "/EXCELNOTES"){  
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "FnO") {
        // if (this.props.location.pathname == "/note" || this.props.location.pathname == "/ssepnotes" || this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/MLNotes" || this.props.location.pathname == "/DSNOTES") {
        if(validPathsfno.includes(this.props.location.pathname) || this.props.location.pathname == "/EXCELNOTES"){
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "SSEP") {
        if (this.props.location.pathname == "/note" || this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/MLNOTES" || this.props.location.pathname == "/DSNOTES" || this.props.location.pathname == "/mockinterview" || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }


      if (info.cprogram == "SSEPGOLD") {
        if (this.props.location.pathname == "/note" || this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/MLNOTES" || this.props.location.pathname == "/DSNOTES" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/mockinterview" || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "DS") {
        if (this.props.location.pathname == "/note" || this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/beginerresource" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/MLNOTES" || this.props.location.pathname == "/games" || this.props.location.pathname == "/ssepnotes" || this.props.location.pathname == "/mockinterview" ||  this.props.location.pathname == "/faq" || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

       
      if (info.cprogram == "DSGOLD") {
        if (validPaths.includes(this.props.location.pathname) || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
          // Your code here
      }else {
          if (info.post_signup_steps > 0) {
            console.log("sunnillllllllllllllllllllllllllllllllllll")
            console.log(this.props.location.pathname)
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "ML") {
        if (this.props.location.pathname == "/note" || this.props.location.pathname == "/BANotes" || this.props.location.pathname == "/FnONotes" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/DSNOTES" || this.props.location.pathname == "/games" || this.props.location.pathname == "/ssepnotes"|| this.props.location.pathname == "/mockinterview" || this.props.location.pathname == "/faq" || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/ivepresearch"} />;
        } else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "EXCEL") {
        if (validPaths.includes(this.props.location.pathname) || this.props.location.pathname == "/DSNOTES" || this.props.location.pathname == "/lifesession" || this.props.location.pathname == "/stats" || this.props.location.pathname == "/faq") {
          return <Redirect to={"/EXCELNOTES"} />;
          // Your code here
      }
      else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      if (info.cprogram == "PYTHON") {
        if (validPathspython.includes(this.props.location.pathname) || this.props.location.pathname == "/EXCELNOTES") {
          return <Redirect to={"/DSNOTES"} />;
          // Your code here
      }
      else {
          if (info.post_signup_steps > 0) {
            return <Redirect to={"/newresource"} />;
          }
        }
      }

      

      if (this.props.location.pathname == "/" && url) {
        return <Redirect to={url} />;
      }
      if (this.props.location.pathname == "/portfolio" && url) {
        return <Redirect to={url} />;
      }

      if (this.props.location.pathname == "/dashboard" && url) {
        return <Redirect to={url} />;
      }

    } 
    else {
      if (info.post_signup_steps > -1) {
        switch (info.post_signup_steps) {
          case 0:
            url = "/signup/step1";
            break;
          case 1:
            url = "/signup/step2";
            break;
          case 2:
            url = "/portfolio";
            break;
          case 3:
            url = "/dashboard";
            break;
        }
      }
      
      if (info.post_signup_steps >= 2 && info.cprogram.match(/FMEP/)) {
        console.log("5",info.post_signup_steps)
        if (info.post_signup_steps > 0) {
          return <Redirect to={"/portfolio"} />;
        }
      }

      if (info.cprogram == "IBEPGOLD" || info.cprogram == "IBEPSILVER") {
        console.log("1")
        if (info.post_signup_steps > 0) {
          return <Redirect to={"/newresource"} />;
        }
      }

      if (info.cprogram == "BA" || info.cprogram == "BABASIC") {
        console.log("2")
        if (info.post_signup_steps > 0) {
          return <Redirect to={"/newresource"} />;
        }
      }

      if (info.cprogram == "SSEP") {
        console.log("3")
        if (info.post_signup_steps > 0) {
          return <Redirect to={"/newresource"} />;
        }
      }


      if (info.cprogram == "FnO") {
        console.log("4")
        if (info.post_signup_steps > 0) {
          return <Redirect to={"/newresource"} />;
        }
      }

      if (info.cprogram == "SSEPGOLD") {
        console.log("5")
        if (info.post_signup_steps > 0) {
          return <Redirect to={"/newresource"} />;
        }
      }

      

      if (info.post_signup_steps < 2) {
        console.log("6")
        if (this.props.location.pathname != url) {



          return <Redirect to={url} />;
        }
      }

      if (this.props.location.pathname == "/newresource" && url) {
        console.log("7")
        return <Redirect to={url} />;
      }

      if (this.props.location.pathname == "/lifesession" && url) {
        console.log("8")
        return <Redirect to={"lifesession"} />;
      }

      if (this.props.location.pathname == "/ivepresearch" && url) {
        console.log("9")
        return <Redirect to={url} />;
      }

      if (this.props.location.pathname == "/note" && url) {
        return <Redirect to={url} />;
      }

      if (this.props.location.pathname == "/BANotes" && url) {
        return <Redirect to={url} />;
      }

      if (this.props.location.pathname == "/" && url) {
        console.log("10")
        return <Redirect to={url} />;
      }
    }

    return null;
  }
}

Main.propTypes = {
  user: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    {},
    null,
    { pure: false }
  )(Main)
);