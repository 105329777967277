import { combineReducers } from "redux";
import userReducer from "./userReducer";
import errorReducer from "./errorReducer";
import socketReducer from "./socketReducer";
import loaderReducer from "./loaderReducer";
import dashboardReducer from "./dashboardReducer";
import hrReducer from "./hrReducers";
import scripReducer from "./scripReducers";
import notificationReducer from "./notificationReducer";
import riskProfileReducer from "./riskProfileReducer";
import portfolioReducer from "./portfolioReducer";
import orderBookReducer from "./orderBookReducer";
import gameReducer from "./gameReducer";
import statsReducer from "./statsReducer";
import researchReducer from "./researchReducer";
import resourceReducer from "./resourceReducer";
import profileReducer from "./profileReducer";
import newresearchReducer from "./newresearchReducer"
import ivepresearchReducer from "./ivepresearchReducer";
import newresourceReducer from "./newresourceReducer";
import fanewresearchReducer from "./fanewresearchReducer";
import baresearchReducer from "./baresearchReducer";
import baresourceReducer from './baResourceReducer';
import ssepresourceReducer from './ssepResourceReducer';
import ssepresearchReducer from './ssepresearchReducer';
import FnOresourceReducer from './FnOResourceReducer';
import fnoresearchReducer from './fnoresearchReducer';
import dsResourceReducer from './dsResourceReducer';
import PythonResourceReducer from './PythonResourceReducer';
import DsResearch from "./dsResearchReducer";
import MLResearch from "./mlResearchReducer";
import UpdatedResaerch from './updatedresearchReducer';
import updatedresearchReducer from "./updatedresearchReducer";
import babasicresearchReducer from "./babasicresearchReducer";
import excelresearchReducer from "./excelresearchReducer";


export default combineReducers({
  user: userReducer,
  errors: errorReducer,
  socketData: socketReducer,
  loader: loaderReducer,
  dashboard: dashboardReducer,
  hr: hrReducer,
  scrips: scripReducer,
  notifications: notificationReducer,
  risk_profile: riskProfileReducer,
  portfolio: portfolioReducer,
  orders: orderBookReducer,
  games: gameReducer,
  stats: statsReducer,
  research: researchReducer,
  newresearch:newresearchReducer,
  fanewresearch:fanewresearchReducer,
  ivepresearch:ivepresearchReducer,
  resource: resourceReducer,
  newresource:newresourceReducer,
  profile: profileReducer,
  baresearch:baresearchReducer,
  updatedresearch:updatedresearchReducer,
  baresource:baresourceReducer,
  ssepresearch:ssepresearchReducer,
  ssepresource: ssepresourceReducer,
  FnOresources: FnOresourceReducer,
  fnoresearch: fnoresearchReducer,
  DSresource:dsResourceReducer,
  Pythonresource:PythonResourceReducer,
  DSresearch:DsResearch,
  MLResearch:MLResearch,
  babasicresearch :babasicresearchReducer,
  excelresearch : excelresearchReducer
});

