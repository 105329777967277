import axios from "axios";


import {
  ERRORS_GET,
  USER_DETAILS_SET,
  CERTIFICATE_GET,
  REPORT_GET,
  RESEARCH_GET,
  USER_RENEWAL,
  USER_CONDITION,
  LIVE_GET,
  ELIGIBLE,
  BA_ELIGIBLE,
  USER_SESSION,
  UPDATED_FMEP_ELIGIBLE,
  BABASIC_ELIGIBLE,
  EXCEL_ELIGIBLE
} from "./types";

export const getCurrentUserDetails = () => dispatch => {
  axios
    .get("/api/user/info")
    .then(res => {
      dispatch(setCurrentUser(res.data));
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const sessionDetails = () => dispatch => {
  console.log("sessssssssssssssssssssssssonjnj")
  axios
    .get("/api/user/usersession")
    .then(res => {
      console.log(res.data,"sessssssssssssssssssssssssonjnj")
      dispatch({
        type: USER_SESSION,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const setSignupStep = step => dispatch => {
  axios
    .post("/api/user/signup_step", { step: step })
    .then(res => { })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getlivesession = () => dispatch => {
  axios
    .get("/api/user/live")
    .then(res => {
      dispatch({
        type: LIVE_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getCertificate = () => dispatch => {
  axios
    .get("/api/user/download_certificate")
    .then(res => {
      dispatch({
        type: CERTIFICATE_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getivepeligible = () => dispatch => {
  axios
    .get("/api/user/ibepeligible")
    .then(res => {
      dispatch({
        type: ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getbaeligible = () => dispatch => {
  axios
    .get("/api/user/baeligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
export const getbabasiceligible = () => dispatch => {
  axios
    .get("/api/user/babasiceligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
export const getUpdatedFmeligible = () => dispatch => {
  axios
    .get("/api/user/updatedFmeligible")
    .then(res => {
      dispatch({
        type: UPDATED_FMEP_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
export const getssepeligible = () => dispatch => {
  axios
    .get("/api/user/ssepeligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};


export const getFnOeligible = () => dispatch => {
  axios
    .get("/api/user/FnOeligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getDSeligible = () => dispatch => {
  axios
    .get("/api/user/DSeligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getPythoneligible = () => dispatch => {
  axios
    .get("/api/user/Pythoneligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getMLeligible = () => dispatch => {
  axios
    .get("/api/user/MLeligible")
    .then(res => {
      dispatch({
        type: BA_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getexceleligible = () => dispatch => {
  axios
    .get("/api/user/exceleligible")
    .then(res => {
      dispatch({
        type: EXCEL_ELIGIBLE,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};


export const getReport = () => dispatch => {
  axios
    .get("/api/user/download_report")
    .then(res => {
      dispatch({
        type: REPORT_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getreportResearch = () => dispatch => {
  axios
    .get("/api/user/download_research")
    .then(res => {
      dispatch({
        type: RESEARCH_GET,
        payload: res.data
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getuserRenew = () => dispatch => {
  axios
    .get("/api/user/renewid")
    .then(res => {
      dispatch({
        type: USER_RENEWAL,
        payload: res.data
      });
    })
    .catch(err => console.log(err));
};

export const getconditions = () => dispatch => {
  axios
    .get("/api/user/conditions")
    .then(res => {
      // console.log(res.data)
      dispatch({
        type: USER_CONDITION,
        payload: res.data.val
      });
    })
    .catch(err => console.log(err));
};


export const setCurrentUser = decoded => {
  return {
    type: USER_DETAILS_SET,
    payload: decoded
  };
};