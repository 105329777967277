import axios from "axios";

import {
  GET_GAME_DATA,
  ERRORS_GET,
  GET_GAME_STATS,
  GET_ECODE_QUESTIONS,
  GET_ECODE_PERMISSION,
  GET_GAME_STATS_ECODE,
  GET_GAME_STATS_G1,
  GET_GAME_STATS_G2,
  GET_IBEP_GAME_DATA,
  GET_Done_IBEP_GAME,
  GET_STATS_IBEP_GAME
  
} from "./types";

import { showLoader } from "./loaderActions";

export const getGameData = game => dispatch => {
  dispatch(showLoader(true));
  axios
    .get(`/api/games/start_game/${game}`)
    .then(res => {
      dispatch(showLoader(false));
      dispatch({
        type: GET_GAME_DATA,
        payload: { data: res.data, game: game }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getGameStats = game => dispatch => {
  dispatch(showLoader(true));
  axios
    .get(`/api/games/game-stats/${game}`)
    .then(res => {
      dispatch(showLoader(false));
      dispatch({
        type: GET_GAME_STATS,
        payload: { data: res.data, game: game }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
export const getGameStatsg1 = () => dispatch => {
  axios
    .get(`/api/games/gameg1`)
    .then(res => {
      dispatch({
        type: GET_GAME_STATS_G1,
        payload: { data: res.data }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};


export const getGameStatsg2 = () => dispatch => {
  axios
    .get(`/api/games/gameg2`)
    .then(res => {
      dispatch({
        type: GET_GAME_STATS_G2,
        payload: { data: res.data }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const getGameStatsecode = () => dispatch => {
  axios
    .get(`/api/games/gameecode`)
    .then(res => {
      dispatch({
        type: GET_GAME_STATS_ECODE,
        payload: { data: res.data }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
export const setRoundWins = (game, wins) => dispatch => {
  axios
    .post(`/api/games/round-wins/${game}`, {
      wins: wins
    })
    .then(res => {
      dispatch(showLoader(false));
    })
    .catch(err => dispatch(showLoader(false)));
};

export const setWins = (game, wins) => dispatch => {
  axios
    .post(`/api/games/set-wins/${game}`, {
      wins: wins
    })
    .then(res => {
      dispatch(showLoader(false));
    })
    .catch(err => dispatch(showLoader(false)));
};

export const getECodeQuestions = () => dispatch => {
  dispatch(showLoader(true));
  axios
    .get(`/api/games/ecode/questions`)
    .then(res => {
      dispatch(showLoader(false));
      dispatch({
        type: GET_ECODE_QUESTIONS,
        payload: { data: res.data }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};

export const setECodeAns = (response, win_loss) => dispatch => {
  axios
    .post(`/api/games/ecode/set-ans`, {
      data: response,
      win_loss: win_loss
    })
    .then(res => {
      dispatch(showLoader(false));
    })
    .catch(err => dispatch(showLoader(false)));
};

export const getECodePermission = () => dispatch => {
  axios
    .get(`/api/games/ecode/permission`)
    .then(res => {
      dispatch({
        type: GET_ECODE_PERMISSION,
        payload: {
          data: res.data.data[0],
          data1: res.data.data1
        }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};
export const PostDonegame = (id) => dispatch => {
  axios
    .post(`/api/games/ecode/done`, {
      game_id: id,
    })
    .then(res => {
      dispatch(showLoader(false));
    })
    .catch(err => dispatch(showLoader(false)));
};

export const getIbepGame = () => dispatch => {
  axios
    .get(`/api/games/ibepgames`).then(res => {
      dispatch({
        type: GET_IBEP_GAME_DATA,
        payload: { data: res.data.data, totalscore: res.data.totalscore, count: res.data.count, eachscore: res.data.response }
      });
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      }));
};


export const postIbepGame = (id, ans) => dispatch => {
  axios
    .post(`/api/games/ibepgamesdata`, {
      id: id,
      ans: ans
    })
    .then(res => {
      // console.log(res.data.count, "res.donecount", res.data.data)
      dispatch({
type:GET_STATS_IBEP_GAME,
payload: {
  data: res.data.data,
}
      });
    })
    .catch(err => {
      console.log(err, "err");
    });
};


export const ibepdone = () => dispatch => {
  axios
    .get(`/api/games/ibepdone`)
    .then(res => {
      dispatch({
        type: GET_Done_IBEP_GAME,
        payload: {
          data: res.data.message,
         
        }
        
      });
      
      // console.log(res.data.message,"hello")
    })
    .catch(err =>
      dispatch({
        type: ERRORS_GET
      })
    );
};