import { MLRESEARCH_DATA, MLRATERESEARCH_DATA, MLGET_NOTIFY_SCORE, MLGET_MAX, MLGET_ELI,MLRESEARCHLOCK } from "../actions/types";

const initialState = {
    data: {},
    rate_research: {},
    notify: {},
    max: {},
    eli: {},
    videowatched:false
};

export default function (state = initialState, action) {
    switch (action.type) {
        case MLRESEARCH_DATA:
            return { ...state, data: action.payload.data, ratings: action.payload.ratings, rated: action.payload.rated };
        case MLRATERESEARCH_DATA:
            return {
                ...state,
                rate_research: action.payload
            };
        case MLGET_NOTIFY_SCORE:
            return {
                ...state,
                notify: action.payload
            };
        case MLGET_MAX:
            return {
                ...state,
                max: action.payload
            };
        case MLGET_ELI:
            return {
                ...state,
                eli: action.payload
            };
        case MLRESEARCHLOCK:{
            return{
                ...state,
                videowatched:action.payload.data
            }
        }    
        default:
            return state;
    }
}

