//ERRORS
export const ERRORS_GET = "ERRORS_GET";
export const ERRORS_CLEAR = "ERRORS_CLEAR";

//LOADER
export const LOADER_SHOW = "LOADER_SHOW";

//AUTH
export const AUTHENTICATION_TOKEN_VERIFY = "AUTHENTICATION_TOKEN_VERIFY";

//DASHBOARD
export const DASHBOARD_DATA_GET = "DASHBOARD_DATA_GET";
export const DASHBOARD_NEWS_FEED = "DASHBOARD_NEWS_FEED";

//HR
export const HR_QUESTIONS_GET = "HR_QUESTIONS_GET";
export const HR_ANSWER_SET = "HR_ANSWER_SET";
export const HR_RESPONSE_SAVE = "HR_RESPONSE_SAVE";
export const HR_ADD_BLOCK = "HR_ADD_BLOCK";
export const HR_REMOVE_BLOCK = "HR_REMOVE_BLOCK";
//   ccomment

//NOTIFICATION
export const NOTIFICATION_LIST = "NOTIFICATION_NEW";
export const NOTIFICATION_MARK_READ = "NOTIFICATION_MARK_READ";
export const NOTIFICATION_MSG = "NOTIFICATION_MSG";

//ORDER
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_CREATE = "ORDER_CREATE";
export const ORDER_DELETE = "ORDER_DELETE";
export const ORDER_REASON = "ORDER_REASON";

//PORTFOLIO
export const PORTFOLIO_UPDATES = "PORTFOLIO_UPDATES";
export const PORTFOLIO_LIST = "PORTFOLIO_LIST";
export const PORTFOLIO_SETUP_DONE = "PORTFOLIO_SETUP_DONE";
export const PORTFOLIO_UPDATES_CLEAR = "PORTFOLIO_UPDATES_CLEAR";
export const USER_FINSCORE = "USER_FINSCORE";
export const USER_RENEWAL = "USER_RENEWAL";
//RISK
export const RISK_PROFILE_GET = "RISK_PROFILE_GET";
export const RISK_PROFILE_SAVE = "RISK_PROFILE_SAVE";

//SCRIPS
export const SCRIP_DATA_UPDATES = "SCRIP_DATA_UPDATES";
export const SCRIP_LIST_ALL = "SCRIP_LIST_ALL";
export const SCRIP_LIST_FOR_PORTFOLIO = "SCRIP_LIST_FOR_PORTFOLIO";
export const SCRIP_INDUSTRIES = "SCRIP_INDUSTRIES";
export const SCRIP_COMPANY_SEARCH = "SCRIP_COMPANY_SEARCH";
export const SCRIP_WATCH_LIST = "SCRIP_WATCH_LIST";
export const SCRIP_CMP_DATA = "SCRIP_CMP_DATA";
export const SCRIP_CHART_DATA = "SCRIP_CHART_DATA";

//USER
export const USER_DETAILS_SET = "USER_DETAILS_SET";
export const USER_SESSION = "USER_SESSION"

export const GET_GAME_DATA = "GET_GAME_DATA";
export const GET_GAME_STATS = "GET_GAME_STATS";
export const GET_GAME_STATS_G1 = "GET_GAME_STATS_G1";
export const GET_GAME_STATS_G2 = "GET_GAME_STATS_G2";
export const GET_GAME_STATS_ECODE = "GET_GAME_STATS_ECODE";
export const GET_IBEP_GAME_DATA = "GET_IBEP_GAME_DATA";
export const CERTIFICATE_GET = "CERTIFICATE_GET";

export const REPORT_GET = "REPORT_GET";
export const RESEARCH_GET = "RESEARCH_GET";


export const GET_ECODE_QUESTIONS = "GET_ECODE_QUESTIONS";
export const GET_ECODE_PERMISSION = "GET_ECODE_PERMISSION";
export const GET_Done_IBEP_GAME ="GET_Done_IBEP_GAME";

export const GET_STATS_IBEP_GAME ="GET_STATS_IBEP_GAME";
//STATS

export const GET_NW_STATS = "GET_NW_STATS";

//RESEARCH
export const ON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const RESEARCH_DATA = "RESEARCH_DATA";
export const RATERESEARCH_DATA = "RATERESEARCH_DATA";
export const GET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
//NEWRESEARCH
export const NEWON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const NEWRESEARCH_DATA = "RESEARCH_DATA";
export const NEWRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const NEWGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
//FANEWRESEARCH
export const FANEWON_RESEARCH_SUBMIT = "FAON_RESEARCH_SUBMIT";
export const FANEWRESEARCH_DATA = "FARESEARCH_DATA";
export const FANEWRATERESEARCH_DATA = "FARATERESEARCH_DATA";
export const FANEWGET_NOTIFY_SCORE = "FAGET_NOTIFY_SCORE";
//IVEPRESEARCH
export const IVEPON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const IVEPRESEARCH_DATA = "RESEARCH_DATA";
export const IVEPRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const IVEPGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const IVEPGET_MAX = "IVEPGET_MAX";
export const IVEPGET_ELI = "IVEPGET_ELI";
//BARESEARCH
export const BAON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const BARESEARCH_DATA = "RESEARCH_DATA";
export const BARATERESEARCH_DATA = "RATERESEARCH_DATA";
export const BAGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const BAGET_MAX = "BAGET_MAX";
export const BAGET_ELI = "BAGET_ELI";
// BABASICRESEARCH
export const BABASICON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const BABASICRESEARCH_DATA = "RESEARCH_DATA";
export const BABASICRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const BABASICGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const BABASICGET_MAX = "BAGET_MAX";
export const BABASICGET_ELI = "BAGET_ELI";

//SSEPRESEARCH
export const SSEPON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const SSEPRESEARCH_DATA = "RESEARCH_DATA";
export const SSEPRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const SSEPGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const SSEPGET_MAX = "SSEPGET_MAX";
export const SSEPGET_ELI = "SSEPGET_ELI";


//FNORESEARCH
export const FNOON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const FNORESEARCH_DATA = "RESEARCH_DATA";
export const FNORATERESEARCH_DATA = "RATERESEARCH_DATA";
export const FNOGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const FNOGET_MAX = "FNOGET_MAX";
export const FNOGET_ELI = "FNOGET_ELI";

// DSRESEARCH
export const DSON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const DSRESEARCH_DATA = "RESEARCH_DATA";
export const DSRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const DSGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const DSGET_MAX = "FNOGET_MAX";
export const DSGET_ELI = "FNOGET_ELI";

export const MLON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const MLRESEARCH_DATA = "RESEARCH_DATA";
export const MLRESEARCHLOCK = "MLVIDEOSWATCHED"
export const MLRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const MLGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const MLGET_MAX = "FNOGET_MAX";
export const MLGET_ELI = "FNOGET_ELI";
export const MLOP = "MLOP";


//RESOURCE AND NEWRESOURSE

export const RESOURCE_QUESTIONS = "RESOURCE_QUESTIONS";
export const RESOURCE_VIDEOQUESTIONS = "RESOURCE_VIDEOQUESTIONS";
export const NEWRESOURCE_VIDEOQUESTIONS = "NEWRESOURCE_VIDEOQUESTIONS";
export const RESOURCE_FETCH_SCORE = "RESOURCE_FETCH_SCORE";
export const RESOURCE_VIDEOFETCH_SCORE = "RESOURCE_VIDEOFETCH_SCORE";
export const NEWRESOURCE_VIDEOFETCH_SCORE = "NEWRESOURCE_VIDEOFETCH_SCORE";
export const RESOURCE_VIDEOS = "RESOURCE_VIDEOS";
export const RESOURCE_ALL_VIDEOS = "RESOURCE_ALL_VIDEOS";
export const RESOURCE_FINLATICS_VIDEOS = "RESOURCE_FINLATICS_VIDEOS";
export const BA_RESOURCE_VIDEOQUESTIONS = "BA_RESOURCE_VIDEOQUESTIONS";
export const BA_RESOURCE_VIDEOFETCH_SCORE = "BA_RESOURCE_VIDEOFETCH_SCORE";

export const SSEP_RESOURCE_VIDEOQUESTIONS = "SSEP_RESOURCE_VIDEOQUESTIONS";
export const SSEP_RESOURCE_VIDEOFETCH_SCORE = "SSEP_RESOURCE_VIDEOFETCH_SCORE";

export const FNO_RESOURCE_VIDEOQUESTIONS = "FNO_RESOURCE_VIDEOQUESTIONS";
export const FNO_RESOURCE_VIDEOFETCH_SCORE = "FNO_RESOURCE_VIDEOFETCH_SCORE";

export const DS_RESOURCE_VIDEOQUESTIONS = "DS_RESOURCE_VIDEOQUESTIONS";
export const DS_RESOURCE_VIDEOFETCH_SCORE = "DS_RESOURCE_VIDEOFETCH_SCORE";


export const Python_RESOURCE_VIDEOQUESTIONS = "Python_RESOURCE_VIDEOQUESTIONS";
export const Python_RESOURCE_VIDEOFETCH_SCORE = "Python_RESOURCE_VIDEOFETCH_SCORE";
export const Python_RESOURCE_QUIZFETCH_SCORE = "Python_RESOURCE_QUIZFETCH_SCORE";



//PROFILE
export const PROFILE_PHOTO = "PROFILE_PHOTO";
export const EDUCATION_DETAILS = "EDUCATION_DETAILS";
export const USER_DETAILS = "USER_DETAILS";
export const SKILLS = "SKILLS";
export const BEHAVIOUR = "BEHAVIOUR";
export const SEARCH_COLLEGE = "SEARCH_COLLEGE";
export const USER_STOCK = "USER_STOCK";
export const USER_CONDITION = "USER_CONDITION";


//SELECT
export const SELECT_PROGRAM = "SELECT_PROGRAM";
export const LIVE_GET = "LIVE_GET";

// EXCELRESEARCH
export const EXCELON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const EXCELRESEARCH_DATA = "RESEARCH_DATA";
export const EXCELRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const EXCELGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const EXCELGET_MAX = "EXCELGET_MAX";
export const EXCELGET_ELI = "EXCELGET_ELI";

//ELI
export const ELIGIBLE = "ELIGIBLE";
export const BA_ELIGIBLE = "BA_ELIGIBLE";
export const BABASIC_ELIGIBLE = "BABASIC_ELIGIBLE";
export const UPDATED_FMEP_ELIGIBLE = "UPDATED_FMEP_ELIGIBLE";

export const EXCEL_ELIGIBLE = "EXCEL_ELIGIBLE";

// Updated FM
export const UPDATEDRESEARCH_DATA = "RESEARCH_DATA";
export const UPDATEDON_RESEARCH_SUBMIT = "ON_RESEARCH_SUBMIT";
export const UPDATEDRATERESEARCH_DATA = "RATERESEARCH_DATA";
export const UPDATEDGET_NOTIFY_SCORE = "GET_NOTIFY_SCORE";
export const UPDATEDGET_MAX = "UPDATEDGET_MAX";
export const UPDATEDGET_ELI = "UPDATEDGET_ELI";

// ERROR LOG
export const ERRORLOG = "ERRORLOG"