import axios from "axios";
import { RESEARCH_DATA, RATERESEARCH_DATA ,GET_NOTIFY_SCORE} from "./types";

export const saveResearch = data => dispatch => {
  axios
    .post(`/api/research`, data)
    .then(res => {
      dispatch(getResearch());
    })
    .catch(error => {});
};

export const getResearch = () => dispatch => {
  axios
    .get(`api/research`)
    .then(res => {
      dispatch({
        type: RESEARCH_DATA,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const saveRateResearch = data => dispatch => {
  axios
    .post(`/api/research/rate-research`, data)
    .then(res => {
      dispatch(getRateResearch());
    })
    .catch(error => {});
};
export const getnotifyscore = ()=> dispatch => {
  axios
    .get(`/api/research/notify_score`)
    .then(res => {
      dispatch({
        type:GET_NOTIFY_SCORE,
        payload:res.data
      });
    })
    .catch(error => {});
};
export const getRateResearch = () => dispatch => {
  dispatch({
    type: RATERESEARCH_DATA,
    payload:{}
  });
  axios
    .get(`api/research/rate-research`)
    .then(res => {
      dispatch({
        type: RATERESEARCH_DATA,
        payload: res.data
      });
    })
    .catch(err => {});
};
export const pnotify = data => dispatch => {
  axios
    .post(`/api/research/pnotify_score`)
    .then(res => {
    })
    .catch(error => {});
};
