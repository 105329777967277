import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M1_V4 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, max.data);

    // if (typeof max.data=="undefined" ||max.data<1) {
    //   return (
    //     <div className="text-center">
    //       <h4 className="ibep-textual-title">Please complete the Project-1 before proceeding ahead</h3>
    //     </div>
    //   );
    // }
    // else{
    return (
      <div className="container">
        <p className="ibep-textual-para">
          <h3 className="ibep-textual-title">
            <strong>Formulas and Functions in Excel</strong>
          </h3>
        </p>
        <h3>Introduction</h3>
        <p className="ibep-textual-para">
          Formulas and functions in Excel are powerful tools that allow users to
          perform calculations, manipulate data, and analyze information
          efficiently.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Formulas</strong>: User-defined expressions for
          calculations. Examples include addition (+), subtraction (-),
          multiplication (*), and division (/).
        </p>
        <p className="ibep-textual-para">
          ● <strong>Functions</strong>: Predefined formulas in Excel for
          simplifying complex calculations, such as mathematical operations,
          statistical analysis, text manipulation, date and time calculations,
          etc.
        </p>
        <h3>Cell Referencing</h3>
        <p className="ibep-textual-para">
          Cell referencing allows formulas to dynamically link to specific data
          in a spreadsheet, making calculations adaptable.
        </p>
        <h3>Types of Cell References</h3>
        <ol>
          <li className="ibep-textual-sub-para">
            <strong>Relative Reference</strong>
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ It adjusts automatically when copied to another cell.
        </p>
        <p className="ibep-textual-para">
          ○ Let’s look at an example: Suppose we have three columns in a dataset
          named product name, unit price, and quantity, and we want to calculate
          the sales of each product.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image001.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">○ Example:</p>
        <p className="ibep-textual-para">
          ■ Formula in D2: =B2*C2
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image002.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ■ When copied to D3, it adjusts to =B3*C3.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image003.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ■ Drag the formula to autofill the rest of the cells.
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            <strong>Absolute Reference</strong>
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ Fixed cell reference that does not change when copied.
        </p>
        <p className="ibep-textual-para">○ Uses $ symbol, e.g., $A$1.</p>
        <p className="ibep-textual-para">
          ○ You can add the dollar signs manually or press <strong>F4</strong>{" "}
          to toggle between reference types.
        </p>
        <p className="ibep-textual-para">
          ○ Let's say we now have a fixed marketing cost to remove from the
          total sales value to get the final sales value.
        </p>
        <p className="ibep-textual-para">○ Example:</p>
        <p className="ibep-textual-para">
          ■ Formula: =D2-$B$9
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image004.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image005.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ■ $B$9 remains constant even when copied.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image006.png"
            alt=""
          />
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            <strong>Mixed Reference</strong>
          </li>
        </ol>
        <p className="ibep-textual-para">
          ○ Combines relative and absolute references.
        </p>
        <p className="ibep-textual-para">
          ○ For example: To lock the column, use $A14. The column will stay the
          same, but the row will adjust when you copy the formula. To lock the
          row, use A$14. The row will remain fixed, while the column changes
          when the formula is copied.
        </p>
        <p className="ibep-textual-para">○ So, here basically:</p>
        <p className="ibep-textual-para">
          ■ $A14: Locks column A, but the row changes.
        </p>
        <p className="ibep-textual-para">
          ■ A$14: Locks row 14, but column changes.
        </p>
        <h3>Common Excel Functions</h3>
        <p className="ibep-textual-para">
          For this purpose, we will be given a sample dataset
          “sample_employee_data”.
        </p>
        <h3>LEFT, MID, RIGHT Functions</h3>
        <p className="ibep-textual-para">
          ● Used for extracting parts of text from a cell.
        </p>
        <p className="ibep-textual-para">
          ● Example: Creating a Unique ID (UID):
          <br /> =LEFT(B2,2) &amp; MID(D2,4,4) &amp; RIGHT(C2,2)
        </p>
        <p className="ibep-textual-para">
          ○ Extracts first two letters from FirstName, middle four digits from
          Phone, and last two from LastName.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image007.png"
            alt=""
          />
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image008.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image009.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image010.png"
            alt=""
          />
        </p>
        <h3>CONCATENATE Function</h3>
        <p className="ibep-textual-para">
          ● Joins text from multiple cells into one.
        </p>
        <p className="ibep-textual-para">
          ● Example:
          <br /> =CONCATENATE(B2, " ", C2)
        </p>
        <p className="ibep-textual-para">
          ○ Combines FirstName and LastName with a space.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image011.png"
            alt=""
          />
        </p>
        <h3>SUMIF Function</h3>
        <p className="ibep-textual-para">
          ● Adds values based on a specified condition.
        </p>
        <p className="ibep-textual-para">
          ● Example:
          <br /> =SUMIF(I2:I50, "IT", H2:H50)
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image012.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Sums salaries of employees in the IT department.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image013.png"
            alt=""
          />
        </p>
        <h3>
          <strong>COUNTIF Function</strong>
        </h3>
        <p className="ibep-textual-para">
          ● Counts the number of cells that meet a condition.
        </p>
        <p className="ibep-textual-para">
          ● Example:
          <br /> =COUNTIF(K2:K50, 5)
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image014.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Counts employees with a performance rating of 5.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image015.png"
            alt=""
          />
        </p>
        <h3>VLOOKUP Function</h3>
        <p className="ibep-textual-para">
          ● VLOOKUP stands for <strong>Vertical Lookup</strong>. Searches for a
          value in the first column of a table and returns a corresponding value
          from another column.
        </p>
        <p className="ibep-textual-para">
          ● To perform the search, we will select an empty cell, cell N6, where
          we want to display a specific employee's salary.
        </p>
        <p className="ibep-textual-para">
          ● Example:
          <br /> =VLOOKUP(N5, A2:H50, 8, FALSE)
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image016.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Searches for employee ID in column A and returns salary from column
          H.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image017.png"
            alt=""
          />
        </p>
        <h3>HLOOKUP Function</h3>
        <p className="ibep-textual-para">
          ● HLOOKUP stands for <strong>Horizontal Lookup</strong>. Searches for
          a value in the first row of a table and returns a corresponding value
          from another row.
        </p>
        <p className="ibep-textual-para">
          ● Hlookup is useful in horizontally arranged data. To see how it
          works, we need to Transform our data into Excel.
        </p>
        <p className="ibep-textual-para">
          ● &nbsp;First, select the entire data range by selecting any populated
          cell and pressing CTRL + A.
        </p>
        <p className="ibep-textual-para">
          ● Then, create a new sheet and name it “hlookup.” In the first cell
          (A1), right-click and select the paste transpose option.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image018.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image019.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ● Example:
          <br /> =HLOOKUP(C15, A1:BS12, 8, FALSE)
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image020.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Searches for employee ID in row 1 and returns salary from row 8.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v4/image021.png"
            alt=""
          />
        </p>
        <h3>Conclusion</h3>
        <p className="ibep-textual-para">
          This lesson covered various formulas, functions, and referencing
          methods in Excel. These tools help users perform calculations and
          analyze data efficiently.
        </p>
      </div>
    );
    // }
  }
}
XL_M1_V4.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M1_V4);
