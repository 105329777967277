import axios from "axios";
import { IVEPRESEARCH_DATA, IVEPRATERESEARCH_DATA ,IVEPGET_NOTIFY_SCORE,IVEPGET_MAX,IVEPGET_ELI} from "./types";

export const saveResearch = data => dispatch => {
  axios
    .post(`/api/ivepresearch`, data)
    .then(res => {
      dispatch(getResearch());
    })
    .catch(error => {});
};

export const getResearch = () => dispatch => {
  axios
    .get(`api/ivepresearch`)
    .then(res => {
      dispatch({
        type: IVEPRESEARCH_DATA,
        payload: res.data
      });
    })
    .catch(err => {});
};

export const saveRateResearch = data => dispatch => {
  axios
    .post(`/api/ivepresearch/rate-research`, data)
    .then(res => {
      dispatch(getRateResearch());
      dispatch(getResearch())
    })
    .catch(error => {});
};
export const getnotifyscore = ()=> dispatch => {
  axios
    .get(`/api/ivepresearch/notify_score`)
    .then(res => {
      dispatch({
        type:IVEPGET_NOTIFY_SCORE,
        payload:res.data
      });
    })
    .catch(error => {});
};
export const getmaxresearch = ()=> dispatch => {
  axios
    .get(`/api/ivepresearch/maxresearch`)
    .then(res => {
      dispatch({
        type:IVEPGET_MAX,
        payload:res.data
      });
    })
    .catch(error => {});
};

export const geteligible = ()=> dispatch => {
  axios
    .get(`/api/ivepresearch/eligible`)
    .then(res => {
      dispatch({
        type:IVEPGET_ELI,
        payload:res.data
      });
    })
    .catch(error => {});
};
export const getRateResearch = () => dispatch => {
  dispatch({
    type: IVEPRATERESEARCH_DATA,
    payload:{}
  });
  axios
    .get(`api/ivepresearch/rate-research`)
    .then(res => {
      dispatch({
        type: IVEPRATERESEARCH_DATA,
        payload: res.data
      });
    })
    .catch(err => {});
};
export const pnotify = data => dispatch => {
  axios
    .post(`/api/ivepresearch/pnotify_score`)
    .then(res => {
    })
    .catch(error => {});
};
