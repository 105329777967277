import isEmpty from "../validation/is-empty";

import { USER_DETAILS_SET, CERTIFICATE_GET, REPORT_GET, RESEARCH_GET, USER_RENEWAL, USER_CONDITION, LIVE_GET, ELIGIBLE, BA_ELIGIBLE,USER_SESSION, EXCEL_ELIGIBLE } from "../actions/types";
//var  Growlytics = require('growlytics-browser');
const initialState = {
  isAuthenticated: false,
  info: {},
  live: {},
  eli: 0,
  session: {},
  excel_eli:{}
};

export default function (state = initialState, action) {
  switch (action.type) {
    case USER_DETAILS_SET:
      //Growlytics.identifyUser(action.payload.user_id, action.payload)
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        info: action.payload
      };

    case CERTIFICATE_GET:
      let certificates = {}
      return {
        ...state,
        certificates: action.payload
      };

    case REPORT_GET:
      let reports = {}
      return {
        ...state,
        reports: action.payload
      };

    case RESEARCH_GET:
      let researchs = {}
      return {
        ...state,
        researchs: action.payload
      };

    case LIVE_GET:

      return {
        ...state,
        live: action.payload
      };

    case ELIGIBLE:

      return {
        ...state,
        eli: action.payload
      };

    case BA_ELIGIBLE:

      return {
        ...state,
        eli: action.payload
      };

case EXCEL_ELIGIBLE:
  return{
    ...state,
    excel_eli:action.payload
  };

    case USER_RENEWAL:
      let renewid = {}
      return {
        ...state,
        renewid: action.payload
      };

    case USER_CONDITION:
      let condition = {}
      return {
        ...state,
        condition: action.payload
      };

    case USER_SESSION:    
        return {
          ...state,
          session: action.payload
    };  

    default:
      return state;
  }
}