import {

    Python_RESOURCE_VIDEOFETCH_SCORE,
    Python_RESOURCE_VIDEOQUESTIONS,
    Python_RESOURCE_QUIZFETCH_SCORE
} from "../actions/types";

const initialState = {
    vquestions: {},
    vscores: {},
    qscores:{},
};
//   ccomment
export default function (state = initialState, action) {
    switch (action.type) {
        case Python_RESOURCE_VIDEOQUESTIONS:
            return {
                ...state,
                vquestions: action.payload
            };

        case Python_RESOURCE_VIDEOFETCH_SCORE:
            return {
                ...state,
                vscores: action.payload
            };


            case Python_RESOURCE_QUIZFETCH_SCORE:
            return {
                ...state,
                qscores: action.payload
            };
        default:
            return state;
    }
}
