import { EXCELRESEARCH_DATA, EXCELRATERESEARCH_DATA,EXCELGET_NOTIFY_SCORE,EXCELGET_MAX,EXCELGET_ELI } from "../actions/types";

const initialState = {
  data: {},
  rate_research: {},
  notify:{},
  max:{},
  eli:{}

};

export default function(state = initialState, action) {
  switch (action.type) {
    case EXCELRESEARCH_DATA:
      return { ...state, data: action.payload.data,ratings:action.payload.ratings ,rated:action.payload.rated };
    case EXCELRATERESEARCH_DATA:
      return {
        ...state,
        rate_research: action.payload
      };
      case EXCELGET_NOTIFY_SCORE:
      return {
        ...state,
        notify: action.payload
      };
      case EXCELGET_MAX:
      return {
        ...state,
        max: action.payload
      };
      case EXCELGET_ELI:
      return {
        ...state,
        eli: action.payload
      };
    default:
      return state;
  }
}

