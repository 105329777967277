export const formatDateTimeToIndia = date => {
  var todayTime = new Date(date);
  var month = todayTime.getMonth() + 1;
  var day = todayTime.getDate();
  var year = todayTime.getFullYear();

  month = month < 10 ? "0" + month : month;
  day = day < 10 ? "0" + day : day;

  var hours = todayTime.getHours();
  var minutes = todayTime.getMinutes();
  var ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${day}/${month}/${year} ${hours}:${minutes} ${ampm}`;
};

export const formatCurrency = (value, showSymbol) => {
  return value
    ? (showSymbol ? "₹ " : "") +
        value
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "₹ 0";
};

export const getTop3Ratings = r => {
  var rates = r.RateResearches.map(rr => {
    let data = JSON.parse(rr.data);
    return { points: rr.points, comment: data.rate_res };
  });

  if (parseInt(r.admin_points) > 0) {
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment
    });

    if (rates.length > 3) {
      rates.sort((a, b) => b.points - a.points);
      rates = rates.slice(0, 3);
    }
  }
  return rates;
};

export const getResearchRating = (r, rated, dConcat) => {
  var rates = getTop3Ratings(r);
  var total = rates.reduce((s, n) => s + n.points, 0);

  var out_of = r.week < 4 ? rates.length * 5 : rates.length * 10;
  out_of += 3;

  rated = rated ? rated : 0;
  if (dConcat && dConcat == true) {
    return { total: total + rated, out_of: out_of };
  }
  let total_p = ((total + rated) / out_of) * 100;

  return total + rated + "/" + out_of;
};

export const newgetTop3Ratings = r => {
 //console.log(r)
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates; 
  } else {
    
    
    var rates = r.RateResearches.map(rr => {
      let data = JSON.parse(rr.data);
     console.log(rr)
      console.log(rr.points)
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 2) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 2);
      }
    }
  }

  return rates;
};

export const ivepgetResearchRating = (r, rated, dConcat) => {
  var rates = ivepgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 3;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 3;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};



export const BAgetResearchRating = (r, rated, dConcat) => {
  var rates = BAgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};
export const BABasicgetResearchRating = (r, rated, dConcat) => {
  var rates = BABasicgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};

export const BABasicgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.BABasicRateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};


export const BAgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.BARateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};


export const ExcelgetResearchRating = (r, rated, dConcat) => {
  var rates = ExcelgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};

export const ExcelgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.ExcelRateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};




export const UpdatedgetResearchRating = (r, rated, dConcat) => {
  var rates = UpdatedgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};


export const UpdatedgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.UpdatedRateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};









export const SsepgetResearchRating = (r, rated, dConcat) => {
  var rates = SsepgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};


export const SsepgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.SSEPRateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};

export const ivepgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.RateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};

export const newgetResearchRating = (r, rated, dConcat) => {
 // console.log(r,rated,dConcat,"asdfg")
  var rates = newgetTop3Ratings(r);
  console.log(rates)

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = r.week < 4 ? 5: 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of = r.week < 4 ? 5: 10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = r.week < 4 ? (rates.length>2?10:rates.length*5) :(rates.length>2?20:rates.length*10);
    out_of += 2

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    console.log("not rated")
    var total = rates.reduce((s, n) => s + n.points, 0);
    
    var out_of = r.week < 4 ? (rates.length>2?10:rates.length*5) :(rates.length>2?20:rates.length*10);
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    console.log(total,rated,out_of)

    return total + rated + "/" + out_of;
  }
};



export const fagetResearchRating = (r, rated, dConcat) => {
  var rates = fagetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of =  10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};


export const fagetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.FARateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 2) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};


export const fnogetResearchRating = (r, rated, dConcat) => {
  var rates = fnogetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of = 10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};


export const fnogetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.FNORateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};



export const DSgetResearchRating = (r, rated, dConcat) => {
  var rates = DSgetTop3Ratings(r);

  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of = 10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};


export const DSgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.DSRateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};

export const MLgetResearchRating = (r, rated, dConcat) => {
  var rates = MLgetTop3Ratings(r);
  console.log(rates,"ml rating")
  if (typeof rates[0] != "undefined") {
    if (typeof rates[0].admin != "undefined") {
      if (dConcat && dConcat == true) {
        var out_of = 10;
        return { total: rates[0].points, out_of: out_of };
      }
      var out_of = 10;
      return rates[0].points + "/" + out_of;
    } else {
      var total = rates.reduce((s, n) => s + n.points, 0);

      var out_of = rates.length * 10;
      out_of += 2;

      rated = rated ? rated : 0;
      if (dConcat && dConcat == true) {
        return { total: total + rated, out_of: out_of };
      }
      let total_p = ((total + rated) / out_of) * 100;

      return total + rated + "/" + out_of;
    }
  } else {
    var total = rates.reduce((s, n) => s + n.points, 0);

    var out_of = rates.length * 10;
    out_of += 2;

    rated = rated ? rated : 0;
    if (dConcat && dConcat == true) {
      return { total: total + rated, out_of: out_of };
    }
    let total_p = ((total + rated) / out_of) * 100;

    return total + rated + "/" + out_of;
  }
};


export const MLgetTop3Ratings = r => {
  if (parseInt(r.admin_points) > 0) {
    var rates = [];
    rates.push({
      points: r.admin_points,
      comment: r.admin_comment,
      admin: 1
    });
    return rates;
  } else {
    var rates = r.MLRateResearches.map(rr => {
      let data = JSON.parse(rr.data);
      return { points: rr.points, comment: data.rate_res };
    });

    if (parseInt(r.admin_points) > 0) {
      rates.push({
        points: r.admin_points,
        comment: r.admin_comment
      });

      if (rates.length > 3) {
        rates.sort((a, b) => b.points - a.points);
        rates = rates.slice(0, 3);
      }
    }
  }

  return rates;
};