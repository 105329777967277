import { DSRESEARCH_DATA, DSRATERESEARCH_DATA, DSGET_NOTIFY_SCORE, DSGET_MAX, DSGET_ELI, MLOP } from "../actions/types";

const initialState = {
    data: {},
    rate_research: {},
    notify: {},
    max: {},
    eli: {},
    count:{}

};

export default function (state = initialState, action) {
    switch (action.type) {
        case DSRESEARCH_DATA:
            return { ...state, data: action.payload.data, ratings: action.payload.ratings, rated: action.payload.rated };
        case DSRATERESEARCH_DATA:
            return {
                ...state,
                rate_research: action.payload
            };
        case DSGET_NOTIFY_SCORE:
            return {
                ...state,
                notify: action.payload
            };
        case DSGET_MAX:
            return {
                ...state,
                max: action.payload
            };
        case DSGET_ELI:
            return {
                ...state,
                eli: action.payload
            };
            case MLOP:
                return {
                    ...state,
                    count: action.payload
                };
        default:
            return state;
    }
}

