import { ERRORS_GET, ERRORS_CLEAR,ERRORLOG } from '../actions/types';

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
    case ERRORS_GET:
      return {err: 'error'};
    case ERRORS_CLEAR:
      return {};
    case  ERRORLOG:
      return {};
    default:
      return state;
  }
}