import React from "react";
import Sidebar from "./sidebar";
import { Row, Col } from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getCurrentUserDetails } from "../../actions/userActions";
import { getexceleligible } from "../../actions/userActions";
import XL_M1_V1 from "./Module-1/XL_M1_V1";
import XL_M1_V2 from "./Module-1/XL_M1_V2";
import XL_M1_V3 from "./Module-1/XL_M1_V3";
import XL_M1_V4 from "./Module-1/XL_M1_V4";
import XL_M2_V1 from "./Module-2/XL_M2_V1";
import XL_M4_V1 from "./Module-4/XL_M4_V1";
import XL_M4_V2 from "./Module-4/XL_M4_V2";
import XL_M4_V4 from "./Module-4/XL_M4_V4";
import XL_M4_V3 from "./Module-4/XL_M4_V3";
import XL_M3_V1 from "./Module-3/XL_M3_V1";
import XL_M3_V2 from "./Module-3/XL_M3_V2";
import XL_M3_V3 from "./Module-3/XL_M3_V3";
import XL_M3_V4 from "./Module-3/XL_M3_V4";
class XLNotes extends React.Component {
  state = {
    active: "xl-m1-v1",
    modal: false,
  };
  setActive = (m) => {
    this.setState({ active: m });
  };
  toggle = (t) => {
    this.setState({
      modal: t,
    });
  };
  componentDidMount() {
    this.props.getCurrentUserDetails();
    // this.props.fetchBAvideoScore(this.props.userInfo.user_id);
    this.props.getexceleligible();
  }
  renderSwitch = (a) => {
    switch (a) {
      case "xl-m1-v1":
        return <XL_M1_V1 />;
        break;
      case "xl-m1-v2":
        return <XL_M1_V2 />;
        break;
      case "xl-m1-v3":
        return <XL_M1_V3 />;
        break;
      case "xl-m1-v4":
        return <XL_M1_V4 />;
        break;
      case "xl-m2-v1":
        return <XL_M2_V1 />;
        break;
      case "xl-m3-v1":
        if (this.props.eli.eligible < 1) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete First Case Project
            </h3>
          );
        } else {
          return <XL_M3_V1 />;
        }
        break;
      case "xl-m3-v2":
        if (this.props.eli.eligible < 1) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete First Case Project
            </h3>
          );
        } else {
          return <XL_M3_V2 />;
        }
        break;
      case "xl-m3-v3":
        if (this.props.eli.eligible < 1) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete First Case Project
            </h3>
          );
        } else {
          return <XL_M3_V3 />;
        }
        break;
      case "xl-m3-v4":
        if (this.props.eli.eligible < 1) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete First Case Project
            </h3>
          );
        } else {
          return <XL_M3_V4 />;
        }
        break;
      case "xl-m4-v1":
        if (this.props.eli.eligible < 2) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete Second Case Project
            </h3>
          );
        } else {
          return <XL_M4_V1 />;
        }
        break;
      case "xl-m4-v2":
        if (this.props.eli.eligible < 2) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete Second Case Project
            </h3>
          );
        } else {
          return <XL_M4_V2 />;
        }
        break;
      case "xl-m4-v3":
        if (this.props.eli.eligible < 2) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete Second Case Project
            </h3>
          );
        } else {
          return <XL_M4_V3 />;
        }
        break;
      case "xl-m4-v4":
        if (this.props.eli.eligible < 2) {
          return (
            <h3 style={{ textAlign: "center" }}>
              Please complete Second Case Project
            </h3>
          );
        } else {
          return <XL_M4_V4 />;
        }
        break;
    }
  };
  render() {
    return (
      <div className="resource  container">
        <div className="resource-info contactUsResource">
          <div className="innerBox">
            <div>
              {" "}
              <p>Need help with training resources or case studies?</p>
              <p class="font-weight-bold">
                1. Get help via WhatsApp to Team Finlatics on +91 9136622687.
              </p>
              <p class="font-weight-bold">
                2. Save +91 9136622687 for updates. (Limited communication
                without saved number)
              </p>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <Row className="mt-4">
            <Col sm="12" md="4" lg="3" className="pad-1">
              <Sidebar
                onSelect={this.setActive}
                active={this.state.active}
                onToggle={this.toggle}
                program_name={this.props.userInfo.cprogram}
              />
            </Col>
            <Col sm="12" md="8" lg="9" className="boxing mb-5 pl-5 pr-5 ">
              {this.renderSwitch(this.state.active)}
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}
XLNotes.propTypes = {
  getCurrentUserDetails: PropTypes.func.isRequired,
  eli: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  userInfo: state.user.info,
  eli: state.user.excel_eli,
});
export default connect(
  mapStateToProps,
  {
    getCurrentUserDetails,
    getexceleligible,
  }
)(XLNotes);
