import {
    SSEP_RESOURCE_VIDEOQUESTIONS,
    SSEP_RESOURCE_VIDEOFETCH_SCORE
  } from "../actions/types";
  
  const initialState = {
    vquestions: {},
    vscores: {}
  };
  
  export default function(state = initialState, action) {
    switch (action.type) {
      case SSEP_RESOURCE_VIDEOQUESTIONS:
        return {
          ...state,
          vquestions: action.payload
        };
  
      case SSEP_RESOURCE_VIDEOFETCH_SCORE:
        return {
          ...state,
          vscores: action.payload
        };
  
      default:
        return state;
    }
  }
  