import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M3_V3 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <h1 className="ibep-textual-title">
          <strong>Monte Carlo Simulation in Excel</strong>
        </h1>
        <h2 className="ibep-textual-title">
          <strong>Introduction</strong>
        </h2>
        <p className="ibep-textual-para">
          Monte Carlo Simulation is a <strong>powerful tool</strong> used across
          various domains to <strong>predict potential outcomes</strong> of
          uncertain scenarios. In this module, we will break down the concept,
          explore its <strong>applications</strong>, and learn how to{" "}
          <strong>implement it practically in Excel</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>What is Monte Carlo Simulation?</strong>
        </h2>
        <p className="ibep-textual-para">
          Monte Carlo Simulation predicts possible outcomes in situations where{" "}
          <strong>uncertainty</strong> is a key factor. It models{" "}
          <strong>probability distributions</strong> by using{" "}
          <strong>random sampling</strong> to simulate different scenarios.
        </p>
        <p className="ibep-textual-para">
          By running <strong>multiple simulations</strong>, we can estimate:
        </p>
        <p className="ibep-textual-para">
          ● The <strong>likelihood of specific results</strong>
        </p>
        <p className="ibep-textual-para">
          ● The <strong>variability</strong> in outcomes
        </p>
        <p className="ibep-textual-para">
          ● The <strong>associated risks</strong>
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Applications of Monte Carlo Simulation</strong>
        </h2>
        <p className="ibep-textual-para">
          Monte Carlo Simulation is used in:
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image001.png"
            alt=""
            width="61"
            height="51"
            hspace="2"
            vspace="2"
          />
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image002.png"
            alt=""
            width="61"
            height="61"
            hspace="2"
            vspace="2"
          />
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image003.png"
            alt=""
            width="72"
            height="61"
            hspace="2"
            vspace="2"
          />
        </p>
        <p className="ibep-textual-para">
          ● Portfolio management and investment planning to evaluate the impact
          of market fluctuations.
        </p>
        <p className="ibep-textual-para">● Risk analysis to assess financial or operational uncertainties.</p>
        <p className="ibep-textual-para">
          ● Options pricing to determine the value of financial derivatives.
        </p>
        <p className="ibep-textual-para">
          ● Logistics and manufacturing for planning spare capacity and handling
          supply chain uncertainties.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>How Does Monte Carlo Simulation Work?</strong>
        </h2>
        <p className="ibep-textual-para">Monte Carlo Simulation involves four key steps:</p>
        <ol start="1" type="1">
          <li>
            <strong>Setting up a predictive model</strong> &ndash; Identify the
            dependent variable to be predicted and the independent variables
            driving predictions.{" "}
            <img
              className=""
              src="/img/excel/m3v3/image004.png"
              alt=""
              width="602"
              height="283"
              hspace="12"
              vspace="12"
            />
          </li>
          <li>
            <strong>Specifying probability distributions</strong> &ndash; Define
            how probabilities are distributed over independent variables using
            historical data or probability weights.
          </li>
          <li>
            <strong>Running simulations</strong> &ndash; Generate random values
            for independent variables and repeat this process multiple times to
            obtain a representative set of outcomes.
          </li>
          <li>
            <strong>Analyzing results</strong> &ndash; Calculate metrics like
            mean, variance, and standard deviation to interpret the range and
            spread of possible outcomes.
          </li>
        </ol>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Practical Implementation in Excel</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>Scenario: High-End Sneaker Business</strong>
        </h3>
        <p className="ibep-textual-para">
          Let&rsquo;s apply Monte Carlo Simulation to a real-world scenario.
        </p>
        <p className="ibep-textual-para">
          Suppose you are the Product Manager for a new line of high-end
          sneakers featuring innovative technology and unique design.
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image005.png"
            alt=""
            width="80"
            height="80"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image006.png"
            alt=""
            width="82"
            height="82"
          />{" "}
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image007.png"
            alt=""
            width="76"
            height="54"
            hspace="12"
            vspace="12"
          />
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image008.png"
            alt=""
            width="76"
            height="54"
            hspace="12"
            vspace="12"
          />
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image009.png"
            alt=""
            width="85"
            height="85"
            hspace="2"
            vspace="2"
          />
        </p>
        <p className="ibep-textual-para">
          ➢ The market is competitive, and consumer demand is influenced by:
        </p>
        <p className="ibep-textual-para">● Seasonal trends</p>
        <p className="ibep-textual-para">● Marketing effectiveness</p>
        <p className="ibep-textual-para">● Competitor actions</p>
        <p className="ibep-textual-para">➢ Manufacturing costs also fluctuate due to:</p>
        <p className="ibep-textual-para">● Material price changes</p>
        <p className="ibep-textual-para">● Supply chain uncertainties</p>
        <p className="ibep-textual-para">
          To estimate potential profitability, you decide to use Monte Carlo
          Simulation in Excel. This will help make informed decisions about:
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image010.png"
            alt=""
            width="76"
            height="76"
            hspace="12"
            vspace="12"
          />
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image011.png"
            alt=""
            width="80"
            height="80"
            hspace="12"
            vspace="12"
          />
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image012.png"
            alt=""
            width="80"
            height="80"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">Pricing strategies Production volumes Marketing investments</p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 1: Define the Variables</strong>
        </h2>
        <p className="ibep-textual-para">We will use the following variables:</p>
        <p className="ibep-textual-para">
          ● <strong>Average demand</strong>: 10,000 units (Standard deviation:
          2,000 units)
        </p>
        <p className="ibep-textual-para">
          ● <strong>Sale price</strong>: Uniformly distributed between $50 and
          $70
        </p>
        <p className="ibep-textual-para">
          ● <strong>Unit cost</strong>: Average of $30 per unit (Standard
          deviation: $5)
        </p>
        <p className="ibep-textual-para">
          Monte Carlo simulations rely on random sampling, so actual values may
          vary. However, the steps and formulas remain the same.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 2: Formulas Used in Monte Carlo Simulation</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>1. NORM.INV() Function</strong>
        </h3>
        <p className="ibep-textual-para">
          Formula:
          <br /> = NORM.INV(probability, mean, std_dev)
        </p>
        <p className="ibep-textual-para">
          This function returns the inverse of the normal cumulative
          distribution for the specified mean and standard deviation.
        </p>
        <p className="ibep-textual-para">
          <strong>Why is it used in Monte Carlo Simulation?</strong>
        </p>
        <p className="ibep-textual-para">
          ● Monte Carlo simulations often require generating random samples from
          a normal distribution.
        </p>
        <p className="ibep-textual-para">
          ● NORM.INV() helps convert a uniformly distributed random number
          (RAND()) into a normally distributed value.
        </p>
        <p className="ibep-textual-para">
          <strong>How it works</strong>:
        </p>
        <p className="ibep-textual-para">
          ● probability &rarr; A random number (from RAND()) between 0 and 1.
        </p>
        <p className="ibep-textual-para">● mean &rarr; Expected average value of the distribution.</p>
        <p className="ibep-textual-para">
          ● std_dev &rarr; Standard deviation, which determines the spread of
          the distribution.
        </p>
        <p className="ibep-textual-para">
          <strong>Other Applications of NORM.INV():</strong>
        </p>
        <p className="ibep-textual-para">● Financial modeling (e.g., simulating portfolio returns)</p>
        <p className="ibep-textual-para">● Risk analysis in project management</p>
        <p className="ibep-textual-para">● Statistical analysis and hypothesis testing</p>
        <h3 className="ibep-textual-title">
          <strong>2. RAND() Function</strong>
        </h3>
        <p className="ibep-textual-para">
          Formula:
          <br /> = RAND()
        </p>
        <p className="ibep-textual-para">
          Returns a random number greater than or equal to 0 and less than 1,
          evenly distributed ( changes on recalculation).
        </p>
        <p className="ibep-textual-para">
          <strong>Why is it used in Monte Carlo Simulation?</strong>
        </p>
        <p className="ibep-textual-para">
          ● RAND() creates random values, which are then transformed using
          NORM.INV() to match required probability distributions.
        </p>
        <p className="ibep-textual-para">
          <strong>How it works</strong>:
        </p>
        <p className="ibep-textual-para">
          ● Each time Excel recalculates, RAND() produces a new random number.
        </p>
        <p className="ibep-textual-para">
          ● This introduces randomness into the simulation, making it dynamic.
        </p>
        <p className="ibep-textual-para">
          <strong>Other Applications of RAND():</strong>
        </p>
        <p className="ibep-textual-para">● Sampling for market research</p>
        <p className="ibep-textual-para">● Shuffling lists or selecting random items</p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 3: Set Up the Excel Model</strong>
        </h2>
        <p className="ibep-textual-para">
          To simulate profitability, create the following columns:
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image013.png"
            alt=""
            width="83"
            height="83"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <strong> Demand</strong> &rarr; Use the formula:
          <br /> =NORM.INV(RAND(), 10000, 2000)
        </p>
        <p className="ibep-textual-para">
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image014.png"
            alt=""
            width="79"
            height="79"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <strong> Sale Price</strong> &rarr; Use the formula:
          <br /> =50 + (70-50) * RAND()
        </p>
        <p className="ibep-textual-para">
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image015.png"
            alt=""
            width="68"
            height="68"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <strong> Unit Cost</strong> &rarr; Use the formula:
          <br /> =NORM.INV(RAND(), 30, 5)
        </p>
        <p className="ibep-textual-para">
          <img
            className="xlm3v3"
            src="/img/excel/m3v3/image016.png"
            alt=""
            width="79"
            height="79"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <strong> Profit Calculation</strong> &rarr;
          <br /> Profit = (Sale Price - Unit Cost) &times; Demand
          <br /> Formula:
          <br /> =(B2 - C2) * A2
        </p>
        <p className="ibep-textual-para">
          <img
            className=""
            src="/img/excel/m3v3/image017.png"
            alt=""
            width="439"
            height="58"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 4: Run Multiple Simulations</strong>
        </h2>
        <p className="ibep-textual-para">To simulate 1,000 different scenarios:</p>
        <p className="ibep-textual-para">● Drag the formulas down for 1,000 rows.</p>
        <p className="ibep-textual-para">
          ● This will generate 1,000 possible profit outcomes.
          <img
            className=""
            src="/img/excel/m3v3/image018.png"
            alt=""
            width="446"
            height="457"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 5: Analyze Results</strong>
        </h2>
        <p className="ibep-textual-para">Use Excel functions to calculate key metrics:</p>
        <p className="ibep-textual-para">
          <strong>Average Profit</strong> &rarr;
          <br /> =AVERAGE(D2:D1001)
          <img
            className=""
            src="/img/excel/m3v3/image019.png"
            alt=""
            width="206"
            height="138"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <strong>Minimum Profit</strong> &rarr;
          <br /> =MIN(D2:D1001)
        </p>
        <p className="ibep-textual-para">
          <strong>Maximum Profit</strong> &rarr;
          <br /> =MAX(D2:D1001)
        </p>
        <p className="ibep-textual-para">
          <strong>Standard Deviation</strong> &rarr;
          <br /> =STDEV.P(D2:D1001)
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 6: Create a Histogram in Excel</strong>
        </h2>
        <p className="ibep-textual-para">
          A histogram helps visualize profit distribution.
          <img
            className=""
            src="/img/excel/m3v3/image020.png"
            alt=""
            width="411"
            height="289"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          <strong>Steps to create a histogram:</strong>
        </p>
        <ol start="1" type="1">
          <li>Select the Profit Column &rarr; Highlight D2:D1001.</li>
          <li>
            Insert the Histogram &rarr; Go to Insert &gt; Charts &gt; Insert
            Statistic Chart &gt; Histogram.
          </li>
          <li>Customize the Histogram &rarr;</li>
        </ol>
        <p className="ibep-textual-para">○ Adjust Bin Width for better clarity.</p>
        <p className="ibep-textual-para">○ Add a descriptive title (e.g., "Profit Distribution").</p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Interpreting Results</strong>
        </h2>
        <p className="ibep-textual-para">The Monte Carlo Simulation predicts:</p>
        <p className="ibep-textual-para">● The high average profit suggests a promising market.</p>
        <p className="ibep-textual-para">● The large variability highlights financial risks.</p>
        <p className="ibep-textual-para">
          ● The wide range of possible outcomes suggests strategic risk
          mitigation is necessary.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Conclusion</strong>
        </h2>
        <p className="ibep-textual-para">
          Monte Carlo Simulation is a versatile technique that enhances
          decision-making by providing probabilistic insights.
        </p>
        <p className="ibep-textual-para">
          With tools like Excel, implementing Monte Carlo Simulations becomes
          straightforward and effective.
        </p>
      </div>
    );
  }
}
XL_M3_V3.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M3_V3);
