import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M3_V4 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <p className="ibep-textual-title">
          <strong>Forecasting Delhi AQI using Excel</strong>
        </p>
        <h3 className="ibep-textual-title">
          <strong>Introduction</strong>
        </h3>
        <p className="ibep-textual-para">
          In this module, we will forecast Delhi&rsquo;s Air Quality Index (AQI)
          using Excel&rsquo;s advanced forecasting tools, particularly
          Exponential Triple Smoothing (ETS). Understanding AQI trends and
          predicting future values can help in reducing pollution levels,
          addressing seasonal spikes, and improving public health.
        </p>
        <p className="ibep-textual-para">
          We will analyze historical AQI data from{" "}
          <strong>1st January 2018</strong> and predict values for{" "}
          <strong>1st January 2025 to 1st December 2026</strong> using
          step-by-step forecasting techniques.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Why is Forecasting Important?</strong>
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img xlm3v4"
            src="/img/excel/m3v4/image001.png"
            alt=""
            width="70"
            height="70"
            hspace="12"
            vspace="12"
          />
        </h3>
        <p className="ibep-textual-para">
          Delhi&rsquo;s air pollution is driven by multiple factors such as
          vehicular emissions, crop burning, industrial activity, and seasonal
          weather patterns.{" "}
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image002.png"
            alt=""
            width="602"
            height="155"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">Accurate forecasting allows us to:</p>
        <p className="ibep-textual-para">● Identify trends in AQI changes.</p>
        <p className="ibep-textual-para">
          ● Anticipate high-pollution periods.
        </p>
        <p className="ibep-textual-para">
          ● Implement interventions like traffic restrictions and promote green
          energy.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image003.png"
            alt=""
            width="602"
            height="72"
            hspace="12"
            vspace="12"
          />
        </p>
        <h3 className="ibep-textual-title">
          <strong>Forecasting Methods</strong>
        </h3>
        <p className="ibep-textual-para">
          We will begin with a <strong>linear forecasting model</strong> to
          understand its limitations and then proceed with{" "}
          <strong>Exponential Triple Smoothing (ETS)</strong>, which is more
          robust and accounts for seasonality and trends in the data.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Step-by-Step Forecasting Process</strong>
        </h3>
        <h4>
          <strong>Step 1: Data Preparation</strong>
        </h4>
        <p className="ibep-textual-para">● Organize the dataset:</p>
        <p className="ibep-textual-para">
          ○ <strong>Column A</strong>: Dates (e.g., from 1st January 2018)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column B</strong>: Corresponding AQI values
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image004.png"
            alt=""
            width="310"
            height="391"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● Create a <strong>visual representation</strong> of historical data:
        </p>
        <p className="ibep-textual-para">○ Select the data.</p>
        <p className="ibep-textual-para">
          ○ Go to <strong>Insert &gt; Charts &gt; Line Chart</strong> to
          visualize trends.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image005.png"
            alt=""
            width="602"
            height="232"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4>&nbsp;</h4>
        <h4>
          <strong>Step 2: Linear Forecasting</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Linear forecasting assumes a constant rate of change and does not
          account for seasonality.
        </p>
        <p className="ibep-textual-para">
          ● Use the formula:
          <br /> =FORECAST.LINEAR(D3,$B$2:$B$85,$A$2:$A$85)
        </p>
        <p className="ibep-textual-para">○ D3: Target date</p>
        <p className="ibep-textual-para">○ $B$2:$B$85: Historical AQI values</p>
        <p className="ibep-textual-para">○ $A$2:$A$85: Historical dates</p>
        <p className="ibep-textual-para">
          ● Lock the reference using <strong>F4</strong> for consistency.
        </p>
        <p className="ibep-textual-para">
          ● Drag down the formula to forecast for multiple dates.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Limitation</strong>: The trend appears linear, failing to
          capture seasonal variations.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image006.png"
            alt=""
            width="509"
            height="437"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4>
          <strong>Step 3: Exponential Triple Smoothing (ETS)</strong>
        </h4>
        <p className="ibep-textual-para">● ETS accounts for:</p>
        <p className="ibep-textual-para">
          1. <strong>Level</strong>: The overall average AQI value.
        </p>
        <p className="ibep-textual-para">
          2. <strong>Trend</strong>: Long-term direction (upward, downward, or
          stable).
        </p>
        <p className="ibep-textual-para">
          3. <strong>Seasonality</strong>: Recurring patterns (e.g., higher AQI
          in winters due to crop burning).
        </p>
        <p className="ibep-textual-para">
          ● Formula:
          <br /> =FORECAST.ETS(D3,$B$2:$B$85,$A$2:$A$85)
        </p>
        <p className="ibep-textual-para">● Steps:</p>
        <p className="ibep-textual-para">1. Select a blank cell.</p>
        <p className="ibep-textual-para">2. Enter the formula.</p>
        <p className="ibep-textual-para">
          3. Press <strong>Enter</strong> to calculate forecasted AQI.
        </p>
        <p className="ibep-textual-para">
          4. Drag the formula down to fill multiple dates.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Comparison</strong>: Create a line chart comparing linear
          and ETS forecasts to visualize seasonal effects.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image007.png"
            alt=""
            width="515"
            height="438"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4>
          <strong>Step 4: Seasonality Analysis</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Identify seasonality patterns using:
          <br /> =FORECAST.ETS.SEASONALITY($B$2:$B$85,$A$2:$A$85)
        </p>
        <p className="ibep-textual-para">
          ● Excel detects a <strong>12-month</strong> seasonal cycle for AQI
          fluctuations.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image008.png"
            alt=""
            width="622"
            height="121"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4>
          <strong>Step 5: Confidence Intervals</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Forecasts include a confidence range to reflect uncertainty:
          <br /> =FORECAST.ETS.CONFINT(D3,$B$2:$B$85,$A$2:$A$85,95%)
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image009.png"
            alt=""
            width="380"
            height="448"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Upper Confidence Level</strong> = ETS forecast value +
          confidence interval.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image010.png"
            alt=""
            width="389"
            height="302"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Lower Confidence Level</strong> = ETS forecast value -
          confidence interval.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image011.png"
            alt=""
            width="484"
            height="445"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4>
          <strong>Step 6: Automated Forecasting in Excel</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Create a forecasting model without formulas:
        </p>
        <p className="ibep-textual-para">
          ○ Select <strong>Date and AQI data</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Go to <strong>Data &gt; Forecast</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Enable <strong>Advanced Statistics</strong> to generate the model.
        </p>
        <p className="ibep-textual-para">
          ● The forecasted data will be displayed in a separate sheet with
          statistical insights.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image012.png"
            alt=""
            width="689"
            height="283"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Interpreting the Forecast Results</strong>
        </h3>
        <h4>
          <strong>Observed Patterns in Forecasted Data (2025-2026)</strong>
        </h4>
        <p className="ibep-textual-para">
          ● <strong>Seasonal Fluctuations</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ Higher AQI in <strong>November-December</strong> and early{" "}
          <strong>January-February</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Lower AQI in <strong>April-August</strong>.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image013.png"
            alt=""
            width="602"
            height="212"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Overall Level &amp; Volatility</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ AQI ranges between <strong>100 and 250</strong>, with occasional
          spikes above <strong>300</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image014.png"
            alt=""
            width="602"
            height="213"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Forecast Behavior</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ AQI is expected to <strong>peak in late Q4/early Q1</strong> and{" "}
          <strong>drop in Q2-Q3</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Wide confidence intervals indicate growing uncertainty over time.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image015.png"
            alt=""
            width="280"
            height="331"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Seasonality Metrics</strong>
        </p>
        <p className="ibep-textual-para">
          Although not broken down numerically here (e.g., seasonal indices),
          the pattern in the raw data shows that months like November and
          December (and sometimes January) often come in at or near the top of
          the year&rsquo;s range. By contrast, months such as February and March
          frequently fall at or near yearly lows&mdash;though there are
          exceptions.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image016.png"
            alt=""
            width="602"
            height="200"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Trend vs. Seasonality</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ No strong long-term trend detected; the pattern is{" "}
          <strong>cyclical</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Forecast suggests similar oscillations will continue in 2025-2026.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image017.png"
            alt=""
            width="592"
            height="183"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Statistical Interpretation of the Model</strong>
        </h3>
        <h4>
          <strong>1. Alpha (0.13)</strong>
        </h4>
        <p className="ibep-textual-para">
          ● <strong>Low smoothing</strong> factor; prevents overreacting to
          short-term spikes.
        </p>
        <p className="ibep-textual-para">
          ● Helps maintain realistic AQI forecasts by averaging fluctuations.
        </p>
        <h4>
          <strong>2. Beta (0.00)</strong>
        </h4>
        <p className="ibep-textual-para">
          ● <strong>No strong long-term trend</strong> detected in AQI values.
        </p>
        <p className="ibep-textual-para">
          ● AQI fluctuations are more seasonal rather than steadily
          increasing/decreasing.
        </p>
        <h4>
          <strong>3. Gamma (0.00)</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Seasonal smoothing is <strong>not strongly applied</strong> in the
          default model.
        </p>
        <p className="ibep-textual-para">
          ● AQI seasonal effects are irregular, with variable peak months.
        </p>
        <h4>
          <strong>4. MASE (0.76)</strong>
        </h4>
        <p className="ibep-textual-para">
          ● The model performs <strong>24% better</strong> than a simple
          historical carry-forward approach.
        </p>
        <p className="ibep-textual-para">
          ● Indicates <strong>ETS outperforms naive forecasting</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h3 className="ibep-textual-title">
          <strong>Insights &amp; Recommendations</strong>
        </h3>
        <p className="ibep-textual-para">
          Using the ETS model, we can make informed policy decisions:
        </p>
        <h4>
          <strong>1. Seasonal Trends</strong>
        </h4>
        <p className="ibep-textual-para">
          ● <strong>High AQI in Winter</strong> &rarr; Implement stricter
          pollution controls.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Low AQI in Summer</strong> &rarr; Opportunity for green
          initiatives.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m3v4/image018.png"
            alt=""
            width="602"
            height="215"
            hspace="12"
            vspace="12"
          />
        </p>
        <h4>
          <strong>2. Strategic Interventions</strong>
        </h4>
        <p className="ibep-textual-para">
          ● <strong>Traffic Regulations in Winter</strong> &rarr; Restrict
          heavy-duty vehicles.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Adopt Clean Energy</strong> &rarr; Promote electric vehicles
          &amp; renewable energy.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Tree-Planting Campaigns</strong> &rarr; Enhance urban
          greenery.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <p className="ibep-textual-para">
          <strong>Conclusion</strong>
        </p>
        <p className="ibep-textual-para">
          ● ETS captures <strong>seasonal trends</strong> and{" "}
          <strong>confidence intervals</strong>, making it a more reliable
          forecasting method than linear regression.
        </p>
        <p className="ibep-textual-para">
          ● The insights can guide <strong>pollution control measures</strong>{" "}
          and <strong>public health policies</strong>.
        </p>
      </div>
    );
  }
}
XL_M3_V4.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M3_V4);
