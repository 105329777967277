import axios from "axios";
import { ERRORLOG } from "./types";
export const ErrorLog = (data) => dispatch => {
  console.log(data,"inside the data")
    axios
      .post(`/api/errorlog/add`, data)
      .then(res => {
        dispatch({
          type:ERRORLOG
        });
        console.log("data inserted successfully",res);
    })
  .catch(error => {});
};