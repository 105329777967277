import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M4_V3 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <h2 className="ibep-textual-title">
          <strong>Introduction</strong>
        </h2>
        <p className="ibep-textual-para">
          Multiple regression allows us to examine the relationship between one
          dependent variable and two or more independent variables. We will use
          a dataset containing economic indicators from 1970 to 2004, with GDP
          as the dependent variable and other factors as independent variables.
          Let&rsquo;s dive into the step-by-step process.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 1: Sample Dataset </strong>
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img xlm4v3"
            src="/img/excel/m4v3/image001.png"
            alt=""
            width="121"
            height="121"
            hspace="12"
            vspace="12"
          />
        </h2>
        <p className="ibep-textual-para">
          The dataset includes the following variables:
        </p>
        <p className="ibep-textual-para">
          <strong>Dependent Variable:</strong>
        </p>
        <p className="ibep-textual-para">
          ● GDP (Gross Domestic Product in Billion USD)
        </p>
        <p className="ibep-textual-para">
          <strong>Independent Variables:</strong>
        </p>
        <p className="ibep-textual-para">
          ● Population Growth: Annual growth rate of population (%)
        </p>
        <p className="ibep-textual-para">
          ● Investment: Investment as a percentage of GDP
        </p>
        <p className="ibep-textual-para">
          ● Export Growth: Growth rate of exports (%)
        </p>
        <p className="ibep-textual-para">
          ● Government Spending: Government spending as a percentage of GDP
        </p>
        <p className="ibep-textual-para">
          You can copy and paste this dataset into a new worksheet in Excel.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>
            Step 2: Steps to Perform Multiple Linear Regression in Excel
          </strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>1. Organize the Data</strong>
        </h3>
        <p className="ibep-textual-para">
          ● Open Excel and paste the dataset into a new worksheet.
        </p>
        <p className="ibep-textual-para">
          ● Ensure the following column structure:
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column A:</strong> Year
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column B:</strong> Population Growth (%)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column C:</strong> Investment (% of GDP)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column D:</strong> Export Growth (%)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column E:</strong> Government Spending (% of GDP)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Column F:</strong> GDP (in Billion USD)
        </p>
        <p className="ibep-textual-para">
          ● Ensure the data is clean, with no blank cells.
        </p>
        <h3 className="ibep-textual-title">
          <strong>2. Enable the Analysis ToolPak</strong>
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v3/image002.png"
            alt=""
            width="602"
            height="300"
            hspace="12"
            vspace="12"
          />
        </h3>
        <p className="ibep-textual-para">
          ● Go to <strong>File &gt; Options &gt; Add-ins</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Under &ldquo;Manage&rdquo; at the bottom, select{" "}
          <strong>Excel Add-ins</strong> and click <strong>Go</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Check the box for <strong>Analysis ToolPak</strong> and click{" "}
          <strong>OK</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v3/image003.png"
            alt=""
            width="288"
            height="355"
            hspace="12"
            vspace="12"
          />
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v3/image004.png"
            alt=""
            width="424"
            height="346"
            hspace="12"
            vspace="12"
          />
        </p>
        <h3 className="ibep-textual-title">
          <strong>3. Perform the Regression Analysis</strong>
        </h3>
        <p className="ibep-textual-para">
          ● Once the <strong>Analysis ToolPak</strong> is enabled:
        </p>
        <p className="ibep-textual-para">
          ○ Go to the <strong>Data</strong> tab and click on{" "}
          <strong>Data Analysis</strong> in the <strong>Analysis</strong> group.
        </p>
        <p className="ibep-textual-para">
          ○ From the list of tools, choose <strong>Regression</strong> and click{" "}
          <strong>OK</strong>.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v3/image005.png"
            alt=""
            width="602"
            height="161"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">○ Fill in the input ranges:</p>
        <p className="ibep-textual-para">
          ■ <strong>Input Y Range:</strong> Select the GDP column (
          <strong>Column F</strong>). This is the dependent variable.
        </p>
        <p className="ibep-textual-para">
          ■ <strong>Input X Range:</strong> Select the independent variable
          columns (<strong>Columns B, C, D, and E</strong>).
        </p>
        <p className="ibep-textual-para">
          ■ Check the <strong>Labels</strong> box if your data includes headers.
        </p>
        <p className="ibep-textual-para">
          ■ Select an <strong>Output Range</strong> or choose a new worksheet
          for the results.
        </p>
        <p className="ibep-textual-para">
          ■ Click <strong>OK</strong> to run the regression analysis.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v3/image006.png"
            alt=""
            width="385"
            height="344"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 3: Interpreting Regression Output</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>Regression Statistics</strong>
        </h3>
        <p className="ibep-textual-para">
          ● The <strong>Multiple R-value</strong> is <strong>0.96185</strong>,
          indicating a strong positive relationship between predictors and GDP.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v3/image007.png"
            alt=""
            width="646"
            height="312"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● The <strong>R Square (R&sup2;) value</strong> is{" "}
          <strong>0.92516</strong>, meaning <strong>92.52%</strong> of the
          variation in GDP is explained by independent variables.
        </p>
        <p className="ibep-textual-para">
          ● The <strong>Adjusted R Square</strong> value is{" "}
          <strong>0.91519</strong>, which is more reliable as it accounts for
          irrelevant predictors.
        </p>
        <p className="ibep-textual-para">
          ● The <strong>Standard Error</strong> is <strong>558.11</strong>,
          representing the average prediction error.
        </p>
        <h3 className="ibep-textual-title">
          <strong>ANOVA (Analysis of Variance)</strong>
        </h3>
        <p className="ibep-textual-para">
          ● The <strong>Significance F</strong> is <strong>1.92368E-16</strong>,
          a very small value, confirming that the model is statistically
          significant.
        </p>
        <p className="ibep-textual-para">
          ● If <strong>Significance F &lt; 0.05</strong>, the model is
          considered significant.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 4: Understanding Individual Variable Impact</strong>
        </h2>
        <p className="ibep-textual-para">
          Each independent variable has a <strong>coefficient</strong>,
          indicating its impact on GDP:
        </p>
        <p className="ibep-textual-para">
          ● <strong>Population Growth (%):</strong> Coefficient ={" "}
          <strong>506.02.</strong>
        </p>
        <p className="ibep-textual-para">
          &nbsp;A <strong>1% increase</strong> in Population Growth leads to a{" "}
          <strong>506.02 billion USD</strong> increase in GDP.{" "}
          <strong>(P-value &lt; 0.05 &rarr; Statistically significant)</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Investment (% of GDP):</strong> Coefficient ={" "}
          <strong>197.80</strong>.
        </p>
        <p className="ibep-textual-para">
          &nbsp;A <strong>1% increase</strong> in Investment leads to a{" "}
          <strong>197.80 billion USD</strong> increase in GDP.{" "}
          <strong>(P-value = 0.00029 &rarr; Statistically significant)</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Export Growth (%):</strong> Coefficient ={" "}
          <strong>170.77.</strong>
        </p>
        <p className="ibep-textual-para">
          &nbsp;A <strong>1% increase</strong> in Export Growth leads to a{" "}
          <strong>170.77 billion USD</strong> increase in GDP.{" "}
          <strong>(P-value = 0.000555 &rarr; Statistically significant)</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Government Spending (% of GDP):</strong> Coefficient ={" "}
          <strong>281.69.</strong>
        </p>
        <p className="ibep-textual-para">
          A <strong>1% increase</strong> in Government Spending leads to a{" "}
          <strong>281.69 billion USD</strong> increase in GDP.{" "}
          <strong>(P-value &lt; 0.05 &rarr; Statistically significant)</strong>
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 5: Importance of P-Values</strong>
        </h2>
        <p className="ibep-textual-para">
          ● <strong>P-value &lt; 0.05</strong> &rarr; The variable is
          statistically significant and has a meaningful impact on GDP.
        </p>
        <p className="ibep-textual-para">
          ● In this model,{" "}
          <strong>all independent variables have P-values below 0.05</strong>,
          confirming their significance.
        </p>
        <h3 className="ibep-textual-title">
          <strong>Intercept Interpretation</strong>
        </h3>
        <p className="ibep-textual-para">
          ● The <strong>intercept</strong> represents GDP when all independent
          variables are <strong>zero</strong>.
        </p>
        <p className="ibep-textual-para">
          ● If <strong>P-value &gt; 0.05</strong>, deciding whether to include
          the intercept depends on whether other external factors influence GDP.
        </p>
        <p className="ibep-textual-para">
          ● In this case,{" "}
          <strong>GDP depends on many factors beyond the four chosen</strong>,
          so the <strong>intercept is included in predictions</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <p className="ibep-textual-para">
          Multiple regression analysis is a <strong>powerful technique</strong>{" "}
          for uncovering complex relationships and making{" "}
          <strong>data-driven decisions</strong>. By evaluating{" "}
          <strong>metrics like R-squared and P-values</strong>, we can determine
          our model&rsquo;s effectiveness and the significance of each variable.
        </p>
      </div>
    );
  }
}
XL_M4_V3.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M4_V3);
