import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M2_V1 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, max.data);

    // if (typeof max.data=="undefined" ||max.data<1) {
    //   return (
    //     <div className="text-center">
    //       <h4 className="ibep-textual-title">Please complete the Project-1 before proceeding ahead</h3>
    //     </div>
    //   );
    // }
    // else{
    return (
      <div className="container">
        <h3>Introduction to Pivot Tables</h3>
        <p className="ibep-textual-para">
          Welcome to another session of the Finlatics Excel Program. In this
          video, we will explore one of the most powerful tools in Microsoft
          Excel—<strong>Pivot Tables</strong>.
        </p>
        <p className="ibep-textual-para">
          &nbsp;Pivot Tables play a crucial role in business analytics, and in
          this session, we will cover their essential features and
          functionalities.
        </p>
        <p className="ibep-textual-para">
          <strong>What is a Pivot Table?</strong>
        </p>
        <p className="ibep-textual-para">
          A Pivot Table provides an interactive method to efficiently summarize
          and analyze vast amounts of data. It enables users to:
        </p>
        <p className="ibep-textual-para">● Easily query large datasets.</p>
        <p className="ibep-textual-para">
          ● Summarize data for better visualization.
        </p>
        <p className="ibep-textual-para">
          ● Organize, group, filter, count, total, or calculate averages within
          a dataset.
        </p>
        <p className="ibep-textual-para">
          Simply put, Pivot Tables help extract meaningful insights from complex
          data.
        </p>
        <p className="ibep-textual-para">
          <strong>Key Features of Pivot Tables</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Data Summarization:</strong> Efficiently organize numerical
          data to identify trends and patterns.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Flexibility:</strong> Dynamically rearrange rows and columns
          to suit different analysis needs.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Customization:</strong> Utilize filters and sorting options
          to focus on relevant data.
        </p>
        <p className="ibep-textual-para">
          <strong>
            Creating an Interactive HR Dashboard Using Pivot Tables{" "}
          </strong>
        </p>
        <p className="ibep-textual-para">
          We will begin by designing a{" "}
          <strong>Human Resources (HR) Dashboard</strong> using Excel, allowing
          HR teams to monitor crucial employee-related information, such as{" "}
          <strong>age, performance, growth, and training</strong>.
        </p>
        <p className="ibep-textual-para">
          This dashboard consolidates data in one place, presenting trends and
          patterns through <strong>charts and graphs</strong>. It makes it
          easier to identify issues, enhance decision-making, and save time.
          Additionally, it is <strong>cost-effective, customizable</strong>, and
          suitable for businesses of any size.
        </p>
        <p className="ibep-textual-para">
          <strong>Dataset Overview</strong>
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image001.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          We will use an <strong>HR dataset</strong> containing employee
          information from various company departments. This dataset includes
          key fields such as:
        </p>
        <p className="ibep-textual-para">
          ● <strong>Demographics:</strong> Age, Gender, Marital Status,
          Education.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Job Details:</strong> Job Role, Job Level, Department.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Financials:</strong> Monthly Income, Percent Salary Hike,
          Stock Option Level.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Performance Metrics:</strong> Job Satisfaction, Performance
          Rating, Work-Life Balance.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Experience:</strong> Years at the Company, Years in Current
          Role, Years Since Last Promotion.
        </p>
        <p className="ibep-textual-para">
          Using this dataset, we will generate insights such as:
        </p>
        <p className="ibep-textual-para">
          ● Employee count and <strong>age distribution</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Gender-based <strong>salary differences</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Department-wise distribution of{" "}
          <strong>male and female employees</strong>.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Average monthly income and salary hikes</strong> in relation
          to <strong>performance ratings and overtime</strong>.
        </p>
        <p className="ibep-textual-para">
          ● The correlation between <strong>job satisfaction</strong> and{" "}
          <strong>tenure at the company</strong>.
        </p>
        <div>
          <hr />
        </div>
        <p className="ibep-textual-para">
          <strong>Data Cleaning Before Analysis</strong>
        </p>
        <p className="ibep-textual-para">
          Before proceeding with the analysis, we need to clean the dataset by:
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            <strong>Removing Duplicate Entries:</strong>
          </li>
          <ul>
            <li className="ibep-textual-sub-para">
              Select the entire dataset (Ctrl + A).
            </li>
            <li className="ibep-textual-sub-para">
              Navigate to the <strong>"Data"</strong> tab and click{" "}
              <strong>"Remove Duplicates"</strong>.
            </li>
            <li className="ibep-textual-sub-para">
              Ensure all columns are selected before proceeding.
            </li>
          </ul>
          <li className="ibep-textual-sub-para">
            <strong>Identifying Missing Values:</strong>
          </li>
          <ul>
            <li className="ibep-textual-sub-para">
              Use <strong>Conditional Formatting</strong> to highlight blank
              cells.
            </li>
            <li className="ibep-textual-sub-para">
              Select the dataset, go to{" "}
              <strong>"Home" → "Conditional Formatting" → "New Rule."</strong>
            </li>
            <li className="ibep-textual-sub-para">
              Choose <strong>"Format only cells that contain"</strong> and set
              it to highlight blank cells.
            </li>
            <li className="ibep-textual-sub-para">
              Remove rows with missing data.
            </li>
          </ul>
          <li className="ibep-textual-sub-para">
            <strong>Formatting Data Types:</strong>
          </li>
          <ul>
            <li className="ibep-textual-sub-para">
              Ensure numerical fields like{" "}
              <strong>Age, Monthly Income, and Distance from Home</strong> are
              formatted as <strong>Numbers</strong>.
            </li>
            <li className="ibep-textual-sub-para">
              Text-based fields like{" "}
              <strong>Department, Gender, and Job Role</strong> should be
              formatted as <strong>Text</strong>.
            </li>
            <li className="ibep-textual-sub-para">
              Select the respective columns, right-click, and choose{" "}
              <strong>"Format Cells"</strong> to apply appropriate data types.
            </li>
          </ul>
        </ol>
        <div>
          <hr />
        </div>
        <p className="ibep-textual-para">
          <strong>Building the HR Dashboard</strong>
        </p>
        <p className="ibep-textual-para">
          <strong>Step 1: Create a Pivot Table for Age Distribution</strong>
        </p>
        <p className="ibep-textual-para">
          To analyze <strong>employee age distribution</strong>, follow these
          steps:
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Convert the dataset into a <strong>Table</strong> by clicking any
            cell and selecting <strong>"Insert" → "Table."</strong>
          </li>
          <li className="ibep-textual-sub-para">
            Navigate to <strong>"Insert" → "Pivot Table"</strong> and select{" "}
            <strong>"New Worksheet."</strong>
          </li>
          <li className="ibep-textual-sub-para">
            Drag <strong>Age</strong> into the <strong>Rows</strong> area and{" "}
            <strong>Employee Count</strong> into the <strong>Values</strong>{" "}
            area.
          </li>
          <li className="ibep-textual-sub-para">
            Change the field setting to <strong>"Count"</strong> instead of{" "}
            <strong>"Sum"</strong> for a clearer overview.
          </li>
          <li className="ibep-textual-sub-para">
            To group similar age ranges, right-click on the Pivot Table and
            choose <strong>"Group"</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Bar Chart</strong> by going to{" "}
            <strong>"Insert" → "Bar Chart"</strong> and customize it as needed.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m2v1/image002.png"
              alt=""
            />
          </li>
        </ol>
        <p className="ibep-textual-para">
          <strong>Step 2: Create a Gender Distribution Pivot Table</strong>
        </p>
        <p className="ibep-textual-para">
          To analyze <strong>gender distribution across departments</strong>:
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Pivot Table</strong> in a new worksheet.
          </li>
          <li className="ibep-textual-sub-para">
            Drag <strong>Department</strong> into <strong>Rows</strong> and{" "}
            <strong>Gender</strong> into <strong>Columns</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Use <strong>Employee Count</strong> as the <strong>Value</strong>{" "}
            and set it to <strong>"Count."</strong>
          </li>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Pie Chart</strong> for visualization and customize
            it using the <strong>Design</strong> tab.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <strong>Example Insight:</strong>
        </p>
        <p className="ibep-textual-para">
          ● The <strong>Research &amp; Development</strong> department has{" "}
          <strong>378 female</strong> and <strong>578 male employees</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image003.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <strong>Step 3: Salary Analysis Based on Performance Ratings</strong>
        </p>
        <p className="ibep-textual-para">
          To explore the relationship between{" "}
          <strong>salary, performance, and overtime</strong>:
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Pivot Table</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Drag <strong>Performance Rating</strong> and{" "}
            <strong>Overtime</strong> into <strong>Rows</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Place <strong>Monthly Income</strong> and{" "}
            <strong>Percent Salary Hike</strong> in <strong>Values</strong> and
            change them to <strong>"Average."</strong>
          </li>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Bar Chart</strong> for better visualization.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <strong>Example Insight:</strong>
        </p>
        <p className="ibep-textual-para">
          ● Employees with a <strong>Performance Rating of 3</strong> have an{" "}
          <strong>average salary of ₹6,479</strong> and an{" "}
          <strong>average salary hike of 14%</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image004.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <strong>Step 4: Employee Satisfaction vs. Tenure Analysis</strong>
        </p>
        <p className="ibep-textual-para">
          To understand{" "}
          <strong>job satisfaction based on company tenure</strong>:
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Pivot Table</strong> in a new worksheet.
          </li>
          <li className="ibep-textual-sub-para">
            Drag <strong>Job Satisfaction</strong> and{" "}
            <strong>Years at Company</strong> into <strong>Rows</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Use <strong>Employee Count</strong> as the <strong>Value</strong>{" "}
            and set it to <strong>"Count."</strong>
          </li>
          <li className="ibep-textual-sub-para">
            Insert a <strong>Line Chart</strong> to visualize trends.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <strong>Example Insight:</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>63 employees</strong> have a{" "}
          <strong>job satisfaction score of 3</strong> and an{" "}
          <strong>average tenure of 5 years</strong>.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image005.png"
            alt=""
          />
        </p>
        <div>
          <hr />
        </div>
        <p className="ibep-textual-para">
          <strong>Finalizing the Interactive Dashboard</strong>
        </p>
        <p className="ibep-textual-para">
          <strong>Adding Slicers for Data Filtering</strong>
        </p>
        <p className="ibep-textual-para">
          To make the dashboard more interactive:
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Insert <strong>Slicers</strong> for{" "}
            <strong>Gender, Department, and Education</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Customize the slicers using the <strong>Slicer Tools</strong> tab.
          </li>
          <li className="ibep-textual-sub-para">
            Connect slicers with the charts by:
          </li>
          <ul>
            <li className="ibep-textual-sub-para">Clicking on the slicer.</li>
            <li className="ibep-textual-sub-para">
              Selecting <strong>"Slicer Tools" → "Report Connections"</strong>.
            </li>
            <li className="ibep-textual-sub-para">
              Checking the relevant Pivot Tables for data filtering.
            </li>
          </ul>
        </ol>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image006.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image007.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <strong>Building the Dashboard</strong>
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Create a <strong>New Worksheet</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Remove gridlines by navigating to <strong>View Options</strong>.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image008.png"
            alt=""
          />
        </p>
        <ol>
          <li className="ibep-textual-sub-para">
            Customize the worksheet by adding:
          </li>
          <ul>
            <li className="ibep-textual-sub-para">
              A <strong>title</strong> (e.g., "Interactive HR Dashboard").
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Bar Charts, Pie Charts, and Line Charts</strong> from
              previous worksheets.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Slicers</strong> to allow easy filtering.
            </li>
          </ul>
        </ol>
        <p className="ibep-textual-para">
          <strong>Data Analysis Using the Dashboard</strong>
        </p>
        <p className="ibep-textual-para">
          ● With the <strong>Gender Slicer</strong>, we can see that{" "}
          <strong>386 male employees</strong> belong to the{" "}
          <strong>28-37 age group</strong>.
        </p>
        <p className="ibep-textual-para">
          ● The <strong>Department Slicer</strong> shows the number of employees
          in each department.
        </p>
        <p className="ibep-textual-para">
          ● There is <strong>no significant salary gap</strong> between male and
          female employees.
        </p>
        <p className="ibep-textual-para">
          ● Employees with a <strong>Performance Rating of 4</strong> have a{" "}
          <strong>higher average salary and salary hike</strong> than those with
          a <strong>Performance Rating of 3</strong>.
        </p>
        <p className="ibep-textual-para">
          ●{" "}
          <strong>
            Most employees report Job Satisfaction scores of 3 or 4
          </strong>
          , regardless of tenure.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m2v1/image009.png"
            alt=""
          />
        </p>
        <div>
          <hr />
        </div>
        <p className="ibep-textual-para">
          <strong>Conclusion</strong>
        </p>
        <p className="ibep-textual-para">
          Using pivot tables and pivot charts, we can efficiently summarize,
          analyze, and visualize HR data. The{" "}
          <strong>interactive dashboard</strong> makes exploring trends,
          identifying key insights, and making informed HR decisions easy.
        </p>
        <p className="ibep-textual-para">
          This approach streamlines data analysis and enhances decision-making
          efficiency, making Excel an essential tool for business analytics.
        </p>
      </div>
    );
    // }
  }
}
XL_M2_V1.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M2_V1);
