import React from "react";
import styled from "styled-components";
import {
  Navbar,
  NavbarToggler,
  Collapse,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import SearchBox from "./searchBox";
import Profile from "./profile";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Notification from "./notification";

import {
  getCurrentUserDetails,
  getlivesession,
} from "../../actions/userActions";
import { getECodePermission } from "../../actions/gamesActions";
import { getnotifyscore } from "../../actions/researchActions";
import { withRouter, Link } from "react-router-dom";
import { generateKeyPair } from "crypto";
import Userswitchmodule from "./Userswitchmodule";
import moment from "moment";

import { FaAngleDown } from "react-icons/fa6";
import { RxHamburgerMenu } from "react-icons/rx";

const HeaderContent = styled.div`
  height: 90px;
`;
const ProfileDropdownContainer = styled.div`
  float: right;
  padding: 10px;
`;

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      icon: "",
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
      hover6: false,
      hover7: false,
      hover8: false,
      hover9: false,
      hover10: false,
      previousProgram: "",
      mobileToggle: false,
    };

    this.dropdownRef = React.createRef();

    this.state = {
      blocking: true,
    };

    this.state = {
      modalIsOpen: true,
      livesession: false,
    };

    this.state = {
      Displayprogramname: "INVESTMENT BANKING",
    };
  }

  componentDidMount = () => {
    this.props.getCurrentUserDetails();
    this.props.getnotifyscore();
    this.props.getECodePermission();
    this.props.getlivesession();
    this.makeactive(window.location.pathname);
    document.addEventListener("mousedown", this.handleClickOutside);
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target)
    ) {
      this.setState({ mobileToggle: false });
    }
    console.log(this.state.mobileToggle, "handling click outside");
  };

  toggleDropdown = () => {
    this.setState((prevState) => ({
      mobileToggle: !prevState.mobileToggle,
    }));
  };

  toggleNavbar = () => {
    this.setState({
      mobileToggle: !this.state.mobileToggle,
    });
  };
  onSelect = (s) => {
    this.setState({ icon: s });
  };

  makeactive = (path, program_name, previous_program) => {
    console.log(
      window.location.pathname,
      program_name,
      "dssssssssssssssssssssssssssssssss"
    );

    this.setState({
      hover1: false,
      hover2: false,
      hover3: false,
      hover4: false,
      hover5: false,
      hover6: false,
      hover7: false,
      hover8: false,
      hover9: false,
      hover10: false,
    });

    if(program_name == "PYTHON" && window.location.pathname != "/DSNOTES" ){
      console.log("pythonnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn",window.location.pathname)
      this.setState({ hover5: true});
      this.setState({ icon: "resource" });
    }
    else if((program_name == "ML" || program_name == "EXCEL") && window.location.pathname != "/EXCELNOTES"){
      this.setState({ hover5: true, hover7: false });
      this.setState({ icon: "resource" });
    }
    else if (
      window.location.pathname == "/newresource" &&
      (this.props.user.cprogram == "DSGOLD" || this.props.user.cprogram == "DS")
    ) {
      console.log(
        window.location.pathname,
        program_name,
        "dsssssssssssssssssssssssssssssssspython"
      );
      this.setState({ hover5: true, hover7: false });
      this.setState({ icon: "resource" });
    } else if (
      window.location.pathname == "/lifesession" &&
      program_name == "BAGOLD"
    ) {
      console.log("inssdienfevnrv IBEPPPPPPPPPPPPPPPPPPPPPPP");
      this.setState({ hover8: true, hover7: false });
      this.setState({ icon: "lifesession" });
      this.setState({ isOpen: false });
    } else if (
      window.location.pathname == "/ivepresearch" &&
      path == "/newresource"
    ) {
      this.setState({ hover4: true });
      this.setState({ icon: "research" });
    } else if (
      window.location.pathname != "/games" &&
      window.location.pathname != "/lifesession"
    ) {
      console.log(path, "inside");
      if (path == "/newresource") {
        console.log(path, "newresourceindeis itnjfnjdk");
        this.setState({ hover5: true });
        this.setState({ icon: "resource" });

        //  this.setState({ isOpen: false });
      }
      
      if (path == "/newresource" && window.location.pathname == "/DSNOTES" && (program_name == "DSGOLD" || program_name == "DS")) {
        console.log(path, "dsnotespythonnewresourceindeis itnjfnjdk");
        this.setState({ hover5: false,hover7: true });
        this.setState({ icon: "note" });

        //  this.setState({ isOpen: false });
      }
      // Make it active when AI Interview goes live.
      // if (path == "/newresource" && window.location.pathname == "/interview") {
      //   console.log(path,window.location.pathname,"newresourceindeis itnjfnjdk interview")
      //   this.setState({ hover5: true });
      //   this.setState({ icon: "resource" })

      //   //  this.setState({ isOpen: false });
      // }

      if (path == "/portfolio") {
        this.setState({ hover2: true, hover5: false });
        this.setState({ icon: "portfolio" });

        //
      }
    }
    // if(path == "/portfolio" && window.location.pathname != "/games"){
    //   this.setState({ hover2: true });
    //   this.setState({ icon: "portfolio" })
    // }
    else if (
      path == "/portfolio" &&
      window.location.pathname == "/games" &&
      program_name.match(/FMEP/)
    ) {
      console.log("udnefocndeovndvonvdfchb");
      this.setState({ hover2: true });
      this.setState({ icon: "portfolio" });
    } else if (
      (path == "/portfolio" && program_name == "FAFMEP") ||
      program_name == "FMEPGOLD" ||
      program_name == "FMEPPLATINUM" ||
      program_name == "FMEPSILVER"
    ) {
      console.log("insided cnewfnewncecnevcnv");
      this.setState({ hover2: true });
      this.setState({ icon: "portfolio" });
    } else if (window.location.pathname == "/games") {
      console.log(path, "newresourceindeis itnjfnjdk");
      if (
        previous_program == "FAFMEP" ||
        previous_program == "FMEPGOLD" ||
        previous_program == "FMEPPLATINUM" ||
        previous_program == "FMEPSILVER" ||
        previous_program == "DS" ||
        previous_program == "DSGOLD"||
        previous_program=="PYTHON"
      ) {
        this.setState({ hover5: true });
        this.setState({ icon: "resource" });
      } else {
        this.setState({ hover6: true });
        this.setState({ icon: "games" });
      }
    } else if (
      (path == "/newresource" && (program_name == "BA" || program_name == "BABASIC") ) ||
      program_name == "IBEPSILVER" ||
      program_name == "SSEP" ||
      program_name == "FnO" ||
      program_name == "BAGOLD" ||
      program_name == "EXCEL"
    ) {
      console.log(path, "newresourceindeis itnjfnjdk");
      this.setState({ hover5: true });
      this.setState({ icon: "resource" });

      //  this.setState({ isOpen: false });
    } else if (
      (window.location.pathname == "/lifesession" &&
        program_name == "BAGOLD") ||
      program_name == "SSEPGOLD" ||
      program_name == "IBEPGOLD"
    ) {
      if (
        previous_program == "FAFMEP" ||
        previous_program == "FMEPGOLD" ||
        previous_program == "FMEPPLATINUM" ||
        previous_program == "FMEPSILVER"
      ) {
        this.setState({ hover5: true });
        this.setState({ icon: "resource" });
      } else {
        console.log("inssdienfevnrv IBEPPPPPPPPPPPPPPPPPPPPPPP");
        this.setState({ hover8: true });
        this.setState({ icon: "lifesession" });
        this.setState({ isOpen: false });
      }
    }

    // Make it active when AI Interview goes live.
    if(window.location.pathname == '/mockinterview'){
      // window.location.pathname = '/newresource'

      console.log("inside interview",window.location.pathname,path)

      if((program_name == 'FnO' || program_name == 'IBEPGOLD') && window.location.pathname == '/mockinterview'){
        if(previous_program && previous_program == "FAFMEP"){
          this.setState({ hover5 : true,hover10: false });
          this.setState({ icon: "resource" })
          this.setState({ isOpen: false });
        }
        else{
          this.setState({ hover10: true , hover5 : false });
          this.setState({ icon: "interview" })
          this.setState({ isOpen: false });
        }
        
      }
      else if(program_name && program_name.match(/FMEP/)){
        this.setState({ hover2: true });
        this.setState({ icon: "portfolio" })
      }
      else{
        console.log("inside check",window.location.pathname)
        this.setState({ hover5: true });
        this.setState({ icon: "resource" })
      }

      // if(previous_program == undefined || (previous_program && previous_program.match(/FMEP/))){
      //   console.log("inside check",window.location.pathname)
      //   this.setState({ hover5: true });
      //   this.setState({ icon: "resource" })
      // }

      // if((previous_program == undefined || previous_program) && !program_name.match(/FMEP/)){
      //   this.setState({ hover10: true });
      //   this.setState({ icon: "interview" })
      //   this.setState({ isOpen: false });
      // }
      // else{
      //   this.setState({ hover10: true });
      //   this.setState({ icon: "interview" })
      //   this.setState({ isOpen: false });
      // }

    }

    return;
  };

  setpreviousprogram = (p) => {
    this.setState({ previousProgram: p });
  };

  onDispalyname = (d) => {
    this.setState({ Displayprogramname: d });
  };
  getRedirect = (o) => {
    this.setState({ icon: o });
  };
  updateLive = () => {
    let ls = this.props.livesession;
    for (var i = 0; i < this.props.livesession.length; i++) {
      let timing = ls[i].timing;
      let a = timing.toLowerCase().split("on");
      var current_date = new Date();
      var c_d = moment(current_date);
      var l = new Date(a[1].trim());
      var fd = moment(l);
      if (fd.isSameOrAfter(c_d, "date")) {
        this.setState({
          livesession: true,
        });
      }
    }
  };

  render() {
    const props = this.props;
    console.log(this.props.user.cprogram, "dsdsdsdsdsfnjrnjnvjnvnvnj");
    let notification = 0;
    if (this.props.notify) {
      notification = this.props.notify.length;
    }
    if (this.props.livesession && !this.state.livesession) {
      this.updateLive();
    }
    let showHeader = props.location.pathname.indexOf("/signup") < 0;
    return (
      <Navbar style={{ backgroundColor: "#eef5fe" }} expand="lg">
        <div
          style={{ display: "flex", justifyContent: "center", width: "100%" }}
        >
          <div style={{ width: "100%", display: "grid" }}>
            <a className="navbar-brand" style={{ marginLeft: "2rem" }} href="#">
              <img src="/img/newbrand.png" alt="logo" />
            </a>
            <div className="headCont">
              <div
                className="headCont1"
                style={{
                  backgroundColor: "white",
                  borderRadius: "10px",
                  justifySelf: "center",
                  padding: "1rem",
                  width: "100%",
                }}
              >
                <div
                  className="container"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Collapse
                    isOpen={this.state.isOpen}
                    navbar
                    className={"navbarOP"}
                  >
                    {showHeader ? (
                     <>
                        {this.props.user.cprogram == "PYTHON"?(
                          <>
                             
                             <ul
                        id="menu"
                        className="navbar-nav mx-2"
                        style={{ width: "100%", margin: "auto", placeContent:"center" }}
                      >
                          <ul
                            id="menu"
                            className="navbar-nav  navbar-right"
                            style={{
                              backgroundColor: "#eef5fe",
                              boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >

                                <li
                              className={
                                "nav-item  " +
                                (this.state.hover5
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "resource"
                                  ? "activeHover"
                                  : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: true,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("resource");
                              }}
                            >
                              <Link
                                to="/newresource"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover5
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Resource
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li
                              className={
                                "nav-item " +
                                (this.state.hover7
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "note" ? "activeHover" : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: false,
                                  hover6: false,
                                  hover7: true,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("note");
                              }}
                            >
                              <Link
                                to={
                                  "/DSNOTES"
                                 
                                }
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover7
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Notes
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            </ul>
                            </ul>
                          </>
                        )
                      :
                      
                        this.props.user.cprogram != "FMEPGOLD" &&
                        this.props.user.cprogram != "FMEPPLATINUM" &&
                        this.props.user.cprogram != "FAFMEP" &&
                        this.props.user.cprogram != "FMEPSILVER" ? (
                    
                          <ul
                          id="menu"
                          className="navbar-nav mx-2"
                          style={{ width: "100%", margin: "auto" }}
                        >
                          <ul
                            id="menu"
                            className="navbar-nav  navbar-right"
                            style={{
                              backgroundColor: "#eef5fe",
                              boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <li
                              className={
                                "nav-item  " +
                                (this.state.hover4
                                  ? "activeImg"
                                  : "inactiveImg") +
                                " " +
                                (this.state.icon == "research"
                                  ? "activeHover"
                                  : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: true,
                                  hover5: false,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("research");
                              }}
                            >
                              <Link
                                to="/ivepresearch"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover4
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Projects
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li
                              className={
                                "nav-item  " +
                                (this.state.hover5
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "resource"
                                  ? "activeHover"
                                  : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: true,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("resource");
                              }}
                            >
                              <Link
                                to="/newresource"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover5
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Resource
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li
                              className={
                                "nav-item " +
                                (this.state.hover7
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "note" ? "activeHover" : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: false,
                                  hover6: false,
                                  hover7: true,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("note");
                              }}
                            >
                              <Link
                                to={
                                  this.props.user.cprogram == "IBEPSILVER" ||
                                  this.props.user.cprogram == "IBEPGOLD"
                                    ? "/note"
                                    : this.props.user.cprogram == "SSEP" ||
                                      this.props.user.cprogram == "SSEPGOLD"
                                    ? "/ssepnotes"
                                    : this.props.user.cprogram == "BA" ||
                                      this.props.user.cprogram == "BABASIC" ||
                                      this.props.user.cprogram == "BAGOLD"
                                    ? "/BANotes"
                                    : this.props.user.cprogram == "FnO"
                                    ? "/FnONotes"
                                    : this.props.user.cprogram == "DSGOLD" ||
                                      this.props.user.cprogram == "DS"
                                    ? "/DSNOTES"
                                    : this.props.user.cprogram == "EXCEL"
                                    ? "/EXCELNOTES"
                                    : "MLNOTES"
                                }
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover7
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Notes
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            {this.props.user.cprogram != "DSGOLD" &&
                            this.props.user.cprogram != "ML" &&
                            this.props.user.cprogram != "DS" && 
                            this.props.user.cprogram != "EXCEL"  ? (
                              <li
                                className={
                                  "nav-item " +
                                  (this.state.hover6
                                    ? "activeImg"
                                    : "inactiveImg  ") +
                                  " " +
                                  (this.state.icon == "games"
                                    ? "activeHover"
                                    : "")
                                }
                                onClick={() => {
                                  this.setState({
                                    hover1: false,
                                    hover2: false,
                                    hover3: false,
                                    hover4: false,
                                    hover5: false,
                                    hover6: true,
                                    hover7: false,
                                    hover8: false,
                                    hover9: false,
                                    hover10: false,
                                  });
                                  this.onSelect("games");
                                }}
                              >
                                <Link
                                  to="/games"
                                  className="nav-link text-secondary navItemLink"
                                >
                                  <div
                                    className={
                                      this.state.hover6
                                        ? "imgItems imgSelected"
                                        : "imgItems"
                                    }
                                  >
                                    <div>
                                      <p
                                        className={"text_dark m-0"}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Games
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ) : null}

                            {this.props.user.cprogram == "IBEPGOLD" ||
                            this.props.user.cprogram == "BAGOLD" ||
                            this.props.user.cprogram == "DSGOLD" ? (
                              <>
                                <li
                                  className={
                                    "nav-item  lifesessionav" +
                                    (this.state.hover8
                                      ? "activeImg"
                                      : "inactiveImg") +
                                    " " +
                                    (this.state.icon == "lifesession"
                                      ? "activeHover"
                                      : "")
                                  }
                                  style={{ marginRight: "0px" }}
                                  onClick={() => {
                                    this.setState({
                                      hover1: false,
                                      hover2: false,
                                      hover3: false,
                                      hover4: false,
                                      hover5: false,
                                      hover6: false,
                                      hover7: false,
                                      hover8: true,
                                      hover9: false,
                                      hover10: false,
                                    });
                                    this.onSelect("lifesession");
                                  }}
                                >
                                  <Link
                                    to="/lifesession"
                                    className="nav-link text-secondary navItemLink"
                                  >
                                    <div
                                      className={
                                        this.state.hover8
                                          ? "imgItems imgSelected"
                                          : "imgItems"
                                      }
                                      style={{ position: "relative" }}
                                    >
                                      {this.state.livesession ? (
                                        <div className="ml-5">
                                          <i className="notify d-inline-block">
                                            {1}
                                          </i>
                                        </div>
                                      ) : null}
                                      <div style={{ width: "max-content" }}>
                                        <p
                                          className={"text_dark m-0"}
                                          style={{ fontWeight: "bold" }}
                                        >
                                          Live Sessions
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              </>
                            ) : null}

                            {this.props.user.cprogram == "FnO" ||
                            this.props.user.cprogram == "ML" ? (
                              <li
                                className={
                                  "nav-item " +
                                  (this.state.hover9
                                    ? "activeImg"
                                    : "inactiveImg  ") +
                                  " " +
                                  (this.state.icon == "beginnerresource"
                                    ? "activeHover"
                                    : "")
                                }
                                onClick={() => {
                                  this.setState({
                                    hover1: false,
                                    hover2: false,
                                    hover3: false,
                                    hover4: false,
                                    hover5: false,
                                    hover6: false,
                                    hover7: false,
                                    hover8: false,
                                    hover9: true,
                                    hover10: false,
                                  });
                                  this.onSelect("beginnerresource");
                                }}
                              >
                                <Link
                                  to="/beginerresource"
                                  className="nav-link text-secondary navItemLink"
                                >
                                  <div
                                    className={
                                      this.state.hover9
                                        ? "imgItems imgSelected"
                                        : "imgItems"
                                    }
                                  >
                                    <div
                                    style={{ width: "max-content" }}
                                    >
                                      <p
                                        className={"text_dark m-0"}
                                        style={{ fontWeight: "bold"}}
                                      >
                                        For Beginner
                                      </p>
                                    </div>
                                  </div>
                                </Link>{" "}
                              </li>
                            ) : null}

                            {this.props.user.cprogram == "FnO" && this.props.user.program.some((e) => e.program_name == "FnO" && e.comment == "EDMPGOLD") ||
                            this.props.user.cprogram == "FnO" && this.props.user.program.some((e) => e.program_name == "FnO" && e.comment == "FnO") ||
                            this.props.user.cprogram == "IBEPGOLD" ? (
                              <>
                                <li
                                  className={
                                    "nav-item  " +
                                    (this.state.hover10
                                      ? "activeImg"
                                      : "inactiveImg  ") +
                                    " " +
                                    (this.state.icon == "mockinterview"
                                      ? "activeHover"
                                      : "")
                                  }
                                  onClick={() => {
                                    this.setState({
                                      hover1: false,
                                      hover2: false,
                                      hover3: false,
                                      hover4: false,
                                      hover5: false,
                                      hover6: false,
                                      hover7: false,
                                      hover8: false,
                                      hover9: false,
                                      hover10: true,
                                    });
                                    this.onSelect("mockinterview");
                                  }}
                                >
                                  <Link
                                    to="/mockinterview"
                                    className="nav-link text-secondary navItemLink AIint"
                                  >
                                    <div
                                      className={
                                        this.state.hover10
                                          ? "imgItems imgSelected AIint"
                                          : "imgItems AIint"
                                      }
                                    >
                                      <div
                                      style={{ width: "max-content" }}
                                      >
                                        <p
                                          className={"text_dark m-0"}
                                          style={{ fontWeight: "bold"}}
                                        >
                                          AI Interview
                                        </p>
                                      </div>
                                    </div>
                                  </Link>
                                </li>
                              </>
                            ) : null}
                          </ul>
                          </ul>
                        ) :(
                          
                          <ul
                          id="menu"
                          className="navbar-nav mx-2"
                          style={{ width: "100%", margin: "auto" }}
                        >
                          <ul
                            id="menu"
                            className="navbar-nav p-2 m-auto navbar-right"
                            style={{
                              backgroundColor: "#eef5fe",
                              boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-evenly",
                              alignItems: "center",
                            }}
                          >
                            <li
                              className={
                                "nav-item " +
                                (this.state.hover1
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "home" ? "activeHover" : "")
                              }
                              onClick={() => {
                                this.onSelect("home");
                                this.setState({
                                  hover1: true,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: false,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                              }}
                            >
                              <Link
                                to="/dashboard"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover1
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <p
                                    className={"text_dark m-0"}
                                    style={{ fontWeight: "bold" }}
                                  >
                                    Home
                                  </p>
                                </div>
                              </Link>
                            </li>
                            <li
                              className={
                                "nav-item  " +
                                (this.state.hover2
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "portfolio"
                                  ? "activeHover"
                                  : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: true,
                                  hover3: false,
                                  hover4: false,
                                  hover5: false,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("portfolio");
                              }}
                            >
                              <Link
                                to="/portfolio"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover2
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Portfolio
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li
                              className={
                                "nav-item " +
                                (this.state.hover3
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "stats"
                                  ? "activeHover"
                                  : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: true,
                                  hover4: false,
                                  hover5: false,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("stats");
                              }}
                            >
                              <Link
                                to="/stats"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover3
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Stats
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>

                            {this.props.user.cprogram == "FMEPGOLD" ||
                            this.props.user.cprogram == "FMEPPLATINUM" ||
                            this.props.user.cprogram == "FAFMEP" ? (
                              <li
                                className={
                                  "nav-item " +
                                  (this.state.hover6
                                    ? "activeImg"
                                    : "inactiveImg") +
                                  " " +
                                  (this.state.icon == "games"
                                    ? "activeHover"
                                    : "")
                                }
                                onClick={() => {
                                  this.setState({
                                    hover1: false,
                                    hover2: false,
                                    hover3: false,
                                    hover4: false,
                                    hover5: false,
                                    hover6: true,
                                    hover7: false,
                                    hover8: false,
                                    hover9: false,
                                    hover10: false,
                                  });
                                  this.onSelect("games");
                                }}
                              >
                                <Link
                                  to="/games"
                                  className="nav-link text-secondary navItemLink"
                                >
                                  <div
                                    className={
                                      this.state.hover6
                                        ? "imgItems imgSelected"
                                        : "imgItems"
                                    }
                                  >
                                    <div>
                                      <p
                                        className={"text_dark m-0"}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Games
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ) : null}

                            {this.props.user.planned !== "p199" &&
                            this.props.user.research_type == "OLD" ? (
                              <li
                                className={
                                  "nav-item  " +
                                  (this.state.hover4
                                    ? "activeImg"
                                    : "inactiveImg") +
                                  " " +
                                  (this.state.icon == "research"
                                    ? "activeHover"
                                    : "")
                                }
                                onClick={() => {
                                  this.setState({
                                    hover1: false,
                                    hover2: false,
                                    hover3: false,
                                    hover4: true,
                                    hover5: false,
                                    hover6: false,
                                    hover7: false,
                                    hover8: false,
                                    hover9: false,
                                    hover10: false,
                                  });
                                  this.onSelect("research");
                                }}
                              >
                                <Link
                                  to="/research"
                                  className="nav-link text-secondary navItemLink"
                                >
                                  <div
                                    className={
                                      this.state.hover4
                                        ? "imgItems imgSelected"
                                        : "imgItems"
                                    }
                                  >
                                    <div className="position-relative">
                                      {notification > 0 ? (
                                        <i className="pr-2  notify d-inline-block ">
                                          <span className="rnotify">
                                            {parseFloat(notification)}
                                          </span>
                                        </i>
                                      ) : null}
                                    </div>
                                    <div>
                                      <p
                                        className={"text_dark m-0"}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Research
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ) : null}

                            {this.props.user.research_type == "NEW" &&
                            this.props.user.planned !== "p199" ? (
                              <li
                                className={
                                  "nav-item  " +
                                  (this.state.hover4
                                    ? "activeImg"
                                    : "inactiveImg   ") +
                                  " " +
                                  (this.state.icon == "research"
                                    ? "activeHover"
                                    : "")
                                }
                                onClick={() => {
                                  this.setState({
                                    hover1: false,
                                    hover2: false,
                                    hover3: false,
                                    hover4: true,
                                    hover5: false,
                                    hover6: false,
                                    hover7: false,
                                    hover8: false,
                                    hover9: false,
                                    hover10: false,
                                  });
                                  this.onSelect("research");
                                }}
                              >
                                <Link
                                  to={
                                    this.props.user.cprogram == "FAFMEP"
                                      ? "faresearch"
                                      : "newresearch"
                                  }
                                  className="nav-link text-secondary navItemLink"
                                >
                                  <div
                                    className={
                                      this.state.hover4
                                        ? "imgItems imgSelected"
                                        : "imgItems"
                                    }
                                  >
                                    <div className="position-relative">
                                      {notification > 0 ? (
                                        <i className="pr-2  notify d-inline-block ">
                                          <span className="rnotify">
                                            {parseFloat(notification)}
                                          </span>
                                        </i>
                                      ) : null}
                                    </div>
                                    <div>
                                      <p
                                        className={"text_dark m-0"}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Research
                                      </p>
                                    </div>
                                  </div>
                                </Link>
                              </li>
                            ) : null}

                            {this.props.user.planned !== "p199" ? (
                              <li
                                className={
                                  "nav-item " +
                                  (this.state.hover5
                                    ? "activeImg"
                                    : "inactiveImg   ") +
                                  " " +
                                  (this.state.icon == "resource"
                                    ? "activeHover"
                                    : "")
                                }
                                onClick={() => {
                                  this.setState({
                                    hover1: false,
                                    hover2: false,
                                    hover3: false,
                                    hover4: false,
                                    hover5: true,
                                    hover6: false,
                                    hover7: false,
                                    hover8: false,
                                    hover9: false,
                                    hover10: false,
                                  });
                                  this.onSelect("resource");
                                }}
                              >
                                <Link
                                  to="/resource"
                                  className="nav-link text-secondary navItemLink"
                                >
                                  <div
                                    className={
                                      this.state.hover5
                                        ? "imgItems imgSelected"
                                        : "imgItems"
                                    }
                                  >
                                    <div>
                                      <p
                                        className={"text_dark m-0"}
                                        style={{ fontWeight: "bold" }}
                                      >
                                        Resource
                                      </p>
                                    </div>
                                  </div>
                                </Link>{" "}
                              </li>
                            ) : null}

                            <>
                              {this.props.user.cprogram == "FAFMEP" && this.props.user.program.some((e) => e.program_name == "FAFMEP" && e.comment == "FAGOLD") ||
                              this.props.user.cprogram == "FAFMEP" && this.props.user.program.some((e) => e.program_name == "FAFMEP" && e.comment == "EDMPGOLD") ||
                              this.props.user.cprogram == "FMEPPLATINUM"
                              ? (
                                <>
                                  <li
                                    className={
                                      "nav-item  lifesessionav " +
                                      (this.state.hover8
                                        ? "activeImg"
                                        : "inactiveImg") +
                                      " " +
                                      (this.state.icon == "lifesession"
                                        ? "activeHover"
                                        : "")
                                    }
                                    style={{ marginRight: "0px" }}
                                    onClick={() => {
                                      this.setState({
                                        hover1: false,
                                        hover2: false,
                                        hover3: false,
                                        hover4: false,
                                        hover5: false,
                                        hover6: false,
                                        hover7: false,
                                        hover8: true,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.onSelect("lifesession");
                                    }}
                                  >
                                    <Link
                                      to="/lifesession"
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover8
                                            ? "imgItems imgSelected"
                                            : "imgItems"
                                        }
                                        style={{ position: "relative" }}
                                      >
                                        {this.state.livesession ? (
                                          <div className="ml-5">
                                            <i className="notify d-inline-block">
                                              {1}
                                            </i>
                                          </div>
                                        ) : null}
                                        <div style={{ width: "max-content" }}>
                                          <p
                                            className={"text_dark m-0"}
                                            style={{
                                              fontWeight: "bold",
                                              lineHeight: "1rem",
                                            }}
                                          >
                                            Live Sessions
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </li>
                                  <li>
                                    <div
                                      className={
                                        "nav-item  " +
                                        (this.state.hover10
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "mockinterview"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: false,
                                          hover6: false,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: true,
                                        });
                                        this.onSelect("mockinterview");
                                      }}
                                    >
                                      <Link
                                        to="/mockinterview"
                                        className="nav-link text-secondary navItemLink AIint"
                                      >
                                        <div
                                          className={
                                            this.state.hover10
                                              ? "imgItems imgSelected AIint"
                                              : "imgItems AIint"
                                          }
                                        >
                                          <div
                                          style={{ width: "max-content" }}
                                          >
                                            <p
                                              className={"text_dark m-0"}
                                              style={{ fontWeight: "bold" }}
                                            >
                                              AI Interview
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  </li>
                                </>
                              ) : null}
                            </>
                          </ul>
                          </ul>
                        )}
                  
                      </>
                    ) : null}
                  </Collapse>

                  <div
                    className={
                      "nav navbar-nav navbar-right profile-nav mx-2" +
                      (this.state.isOpen ? " d-none " : "")
                    }
                    style={{
                      width: "fit-content",
                      backgroundColor: "#eef5fe",
                      borderRadius: "10px",
                      alignItems: "center",
                      boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
                      height: "3rem",
                      // marginRight: "50px",
                    }}
                  >
                    <li>
                      <div className="profileSection">
                        <Profile
                          getRedirect={this.getRedirect}
                          notification={notification}
                        />
                      </div>
                    </li>
                  </div>

                  <div
                    className={
                      "nav navbar-nav navbar-right profile-nav group mx-1" +
                      (this.state.isOpen ? " d-none " : "")
                    }
                    style={{
                      width: "fit-content",
                      display: "flex",
                      justifyContent: "space-around",
                      flexWrap: "nowrap",
                      flexDirection: "row",
                      height: "3rem",
                    }}
                  >
                    <div style={{ display: "flex", width: "fit-content" }}>
                      <div
                        style={{
                          display: "flex",
                          height: "100%",
                          borderRadius: "10px",
                          backgroundColor: "#eef5fe",
                          alignItems: "center",
                          boxShadow: " rgba(0, 0, 0, 0.16) 0px 1px 4px",
                          // overflow: 'hidden',
                        }}
                      >
                        <Userswitchmodule
                          getDisplay={this.onDispalyname}
                          reflectactive={this.makeactive}
                          getpreviousprogram={this.setpreviousprogram}
                          currProgram={this.props.user.cprogram}
                        />
                        <FaAngleDown className="switchIcon" />
                      </div>

                      <div style={{ display: "grid" }} ref={this.dropdownRef}>
                        {/* <NavbarToggler
                          onClick={this.toggleNavbar}
                          className="toggleMobile navbar-dark"
                        /> */}
                        <button
                          onClick={this.toggleNavbar}
                          className="mobileTogBtn"
                        >
                          <RxHamburgerMenu />
                        </button>
                        {this.state.mobileToggle && (
                          <div className="mobile_dropdown">
                            <div
                              style={{
                                display: "grid",
                                backgroundColor: "#eef5fe",
                                borderRadius: "10px",
                              }}
                            >
 {this.props.user.cprogram == "PYTHON"?(
                          <>
                             
                            

                                <li
                              className={
                                "nav-item  " +
                                (this.state.hover5
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "resource"
                                  ? "activeHover"
                                  : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: true,
                                  hover6: false,
                                  hover7: false,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("resource");
                              }}
                            >
                              <Link
                                to="/newresource"
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover5
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Resource
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                            <li
                              className={
                                "nav-item " +
                                (this.state.hover7
                                  ? "activeImg"
                                  : "inactiveImg   ") +
                                " " +
                                (this.state.icon == "note" ? "activeHover" : "")
                              }
                              onClick={() => {
                                this.setState({
                                  hover1: false,
                                  hover2: false,
                                  hover3: false,
                                  hover4: false,
                                  hover5: false,
                                  hover6: false,
                                  hover7: true,
                                  hover8: false,
                                  hover9: false,
                                  hover10: false,
                                });
                                this.onSelect("note");
                              }}
                            >
                              <Link
                                to={
                                  "/DSNOTES"
                                 
                                }
                                className="nav-link text-secondary navItemLink"
                              >
                                <div
                                  className={
                                    this.state.hover7
                                      ? "imgItems imgSelected"
                                      : "imgItems"
                                  }
                                >
                                  <div>
                                    <p
                                      className={"text_dark m-0"}
                                      style={{ fontWeight: "bold" }}
                                    >
                                      Notes
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            </li>
                          
                          </>
                        )
                      :

                              this.props.user.cprogram != "FMEPGOLD" &&
                              this.props.user.cprogram != "FMEPPLATINUM" &&
                              this.props.user.cprogram != "FAFMEP" &&
                              this.props.user.cprogram != "FMEPSILVER"? (
                                <>
                                  <div
                                    className={
                                      "nav-item  " +
                                      (this.state.hover4
                                        ? "activeImg"
                                        : "inactiveImg mobileMenu  ") +
                                      " " +
                                      (this.state.icon == "research   "
                                        ? "activeHover"
                                        : "")
                                    }
                                    onClick={() => {
                                      this.setState({
                                        hover1: false,
                                        hover2: false,
                                        hover3: false,
                                        hover4: true,
                                        hover5: false,
                                        hover6: false,
                                        hover7: false,
                                        hover8: false,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.onSelect("research");
                                      this.setState({ mobileToggle: false });
                                    }}
                                  >
                                    <Link
                                      to="/ivepresearch"
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover4
                                            ? "imgItems mobileImgSelected"
                                            : "imgItems"
                                        }
                                      >
                                        <div>
                                          <p className={"text_dark m-0"}>
                                            Projects
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <div
                                    className={
                                      "nav-item  " +
                                      (this.state.hover5
                                        ? "activeImg"
                                        : "inactiveImg mobileMenu  ") +
                                      " " +
                                      (this.state.icon == "resource"
                                        ? "activeHover"
                                        : "")
                                    }
                                    onClick={() => {
                                      this.setState({
                                        hover1: false,
                                        hover2: false,
                                        hover3: false,
                                        hover4: false,
                                        hover5: true,
                                        hover6: false,
                                        hover7: false,
                                        hover8: false,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.onSelect("resource");
                                      this.setState({ mobileToggle: false });
                                    }}
                                  >
                                    <Link
                                      to="/newresource"
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover5
                                            ? "imgItems mobileImgSelected"
                                            : "imgItems"
                                        }
                                      >
                                        <div>
                                          <p className={"text_dark m-0"}>
                                            Resource
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <div
                                    className={
                                      "nav-item " +
                                      (this.state.hover7
                                        ? "activeImg"
                                        : "inactiveImg mobileMenu  ") +
                                      " " +
                                      (this.state.icon == "note"
                                        ? "activeHover"
                                        : "")
                                    }
                                    onClick={() => {
                                      this.setState({
                                        hover1: false,
                                        hover2: false,
                                        hover3: false,
                                        hover4: false,
                                        hover5: false,
                                        hover6: false,
                                        hover7: true,
                                        hover8: false,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.onSelect("note");
                                      this.setState({ mobileToggle: false });
                                    }}
                                  >
                                    <Link
                                      to={
                                        this.props.user.cprogram ==
                                          "IBEPSILVER" ||
                                        this.props.user.cprogram == "IBEPGOLD"
                                          ? "/note"
                                          : this.props.user.cprogram ==
                                              "SSEP" ||
                                            this.props.user.cprogram ==
                                              "SSEPGOLD"
                                          ? "/ssepnotes"
                                          : this.props.user.cprogram == "BA" ||
                                            this.props.user.cprogram == "BABASIC" ||
                                            this.props.user.cprogram == "BAGOLD"
                                          ? "/BANotes"
                                          : this.props.user.cprogram == "FnO"
                                          ? "/FnONotes"
                                          : this.props.user.cprogram ==
                                              "DSGOLD" ||
                                            this.props.user.cprogram == "DS"
                                          ? "/DSNOTES"
                                          : this.props.user.cprogram == "EXCEL"
                                          ? "/EXCELNOTES"
                                          : "MLNOTES"
                                      }
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover7
                                            ? "imgItems mobileImgSelected"
                                            : "imgItems"
                                        }
                                      >
                                        <div>
                                          <p className={"text_dark m-0"}>
                                            Notes
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  {this.props.user.cprogram != "DSGOLD" &&
                                    this.props.user.cprogram != "ML" &&
                                    this.props.user.cprogram != "DS" && 
                                    this.props.user.cprogram != "EXCEL"  ? (
                                    <div
                                      className={
                                        "nav-item " +
                                        (this.state.hover6
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "games"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: false,
                                          hover6: true,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: false,
                                        });
                                        this.onSelect("games");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to="/games"
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover6
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                        >
                                          <div>
                                            <p className={"text_dark m-0"}>
                                              Games
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ) : null}

                                  {this.props.user.cprogram == "IBEPGOLD" ||
                                  this.props.user.cprogram == "BAGOLD" ||
                                  this.props.user.cprogram == "DSGOLD" ? (
                                    <div
                                      className={
                                        "nav-item  lifesessionav" +
                                        (this.state.hover8
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu") +
                                        " " +
                                        (this.state.icon == "lifesession"
                                          ? "activeHover"
                                          : "")
                                      }
                                      style={{ marginRight: "0px" }}
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: false,
                                          hover6: false,
                                          hover7: false,
                                          hover8: true,
                                          hover9: false,
                                          hover10: false,
                                        });
                                        this.onSelect("lifesession");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to="/lifesession"
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover8
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                          style={{ position: "relative" }}
                                        >
                                          {this.state.livesession ? (
                                            <div className="ml-5">
                                              <i className="notify d-inline-block">
                                                {1}
                                              </i>
                                            </div>
                                          ) : null}
                                          <div style={{ width: "max-content" }}>
                                            <p>Live Sessions</p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ) : null}

                                  {this.props.user.cprogram == "FnO" || 
                                    this.props.user.cprogram == "ML" ? (
                                    <div
                                      className={
                                        "nav-item " +
                                        (this.state.hover9
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu") +
                                        " " +
                                        (this.state.icon == "beginnerresource"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: false,
                                          hover6: false,
                                          hover7: false,
                                          hover8: false,
                                          hover9: true,
                                          hover10: false,
                                        });
                                        this.onSelect("beginnerresource");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to="/beginerresource"
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover9
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                        >
                                          <div style={{ width: "max-content" }}>
                                            <p>For Beginner</p>
                                          </div>
                                        </div>
                                      </Link>{" "}
                                    </div>
                                  ) : null}

                                  {this.props.user.cprogram == "FnO" && this.props.user.program.some((e) => e.program_name == "FnO" && e.comment == "EDMPGOLD") ||
                                  this.props.user.cprogram == "FnO" && this.props.user.program.some((e) => e.program_name == "FnO" && e.comment == "FnO") ||
                                  this.props.user.cprogram == "IBEPGOLD" ? (
                                    <div
                                      className={
                                        "nav-item  " +
                                        (this.state.hover10
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "mockinterview"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: false,
                                          hover6: false,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: true,
                                        });
                                        this.onSelect("mockinterview");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                    to="/mockinterview"
                                    className="nav-link text-secondary navItemLink AIint"
                                  >
                                    <div
                                      className={
                                        this.state.hover10
                                          ? "imgItems mobileImgSelected AIint"
                                          : "imgItems AIint"
                                      }
                                    >
                                          <div
                                          style={{ width: "max-content" }}
                                          >
                                            <p >
                                              AI Interview
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ) : null}
                                </>
                              ) : (
                                <>
                                  <div
                                    className={
                                      "nav-item " +
                                      (this.state.hover1
                                        ? "activeImg"
                                        : "inactiveImg mobileMenu  ") +
                                      " " +
                                      (this.state.icon == "home"
                                        ? "activeHover"
                                        : "")
                                    }
                                    onClick={() => {
                                      this.onSelect("home");
                                      this.setState({
                                        hover1: true,
                                        hover2: false,
                                        hover3: false,
                                        hover4: false,
                                        hover5: false,
                                        hover6: false,
                                        hover7: false,
                                        hover8: false,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.setState({ mobileToggle: false });
                                    }}
                                  >
                                    <Link
                                      to="/dashboard"
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover1
                                            ? "imgItems mobileImgSelected"
                                            : "imgItems"
                                        }
                                      >
                                        <p className={"text_dark m-0"}>Home</p>
                                      </div>
                                    </Link>
                                  </div>
                                  <div
                                    className={
                                      "nav-item  " +
                                      (this.state.hover2
                                        ? "activeImg"
                                        : "inactiveImg mobileMenu  ") +
                                      " " +
                                      (this.state.icon == "portfolio"
                                        ? "activeHover"
                                        : "")
                                    }
                                    onClick={() => {
                                      this.setState({
                                        hover1: false,
                                        hover2: true,
                                        hover3: false,
                                        hover4: false,
                                        hover5: false,
                                        hover6: false,
                                        hover7: false,
                                        hover8: false,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.onSelect("portfolio");
                                      this.setState({ mobileToggle: false });
                                    }}
                                  >
                                    <Link
                                      to="/portfolio"
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover2
                                            ? "imgItems mobileImgSelected"
                                            : "imgItems"
                                        }
                                      >
                                        <div>
                                          <p className={"text_dark m-0"}>
                                            Portfolio
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>
                                  <div
                                    className={
                                      "nav-item " +
                                      (this.state.hover3
                                        ? "activeImg"
                                        : "inactiveImg mobileMenu  ") +
                                      " " +
                                      (this.state.icon == "stats"
                                        ? "activeHover"
                                        : "")
                                    }
                                    onClick={() => {
                                      this.setState({
                                        hover1: false,
                                        hover2: false,
                                        hover3: true,
                                        hover4: false,
                                        hover5: false,
                                        hover6: false,
                                        hover7: false,
                                        hover8: false,
                                        hover9: false,
                                        hover10: false,
                                      });
                                      this.onSelect("stats");
                                      this.setState({ mobileToggle: false });
                                    }}
                                  >
                                    <Link
                                      to="/stats"
                                      className="nav-link text-secondary navItemLink"
                                    >
                                      <div
                                        className={
                                          this.state.hover3
                                            ? "imgItems mobileImgSelected"
                                            : "imgItems"
                                        }
                                      >
                                        <div>
                                          <p className={"text_dark m-0"}>
                                            Stats
                                          </p>
                                        </div>
                                      </div>
                                    </Link>
                                  </div>

                                  {this.props.user.cprogram == "FMEPGOLD" ||
                                  this.props.user.cprogram == "FMEPPLATINUM" ||
                                  this.props.user.cprogram == "FAFMEP" ? (
                                    <div
                                      className={
                                        "nav-item " +
                                        (this.state.hover6
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "games"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: false,
                                          hover6: true,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: false,
                                        });
                                        this.onSelect("games");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to="/games"
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover6
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                        >
                                          <div>
                                            <p className={"text_dark m-0"}>
                                              Games
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ) : null}

                                  {this.props.user.planned !== "p199" &&
                                  this.props.user.research_type == "OLD" ? (
                                    <div
                                      className={
                                        "nav-item  " +
                                        (this.state.hover4
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "research"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: true,
                                          hover5: false,
                                          hover6: false,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: false,
                                        });
                                        this.onSelect("research");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to="/research"
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover4
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                        >
                                          <div className="position-relative">
                                            {notification > 0 ? (
                                              <i className="pr-2  notify d-inline-block ">
                                                <span className="rnotify">
                                                  {parseFloat(notification)}
                                                </span>
                                              </i>
                                            ) : null}
                                          </div>
                                          <div>
                                            <p className={"text_dark m-0"}>
                                              Research
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ) : null}

                                  {this.props.user.research_type == "NEW" &&
                                  this.props.user.planned !== "p199" ? (
                                    <div
                                      className={
                                        "nav-item  " +
                                        (this.state.hover4
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "research"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: true,
                                          hover5: false,
                                          hover6: false,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: false,
                                        });
                                        this.onSelect("research");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to={
                                          this.props.user.cprogram == "FAFMEP"
                                            ? "faresearch"
                                            : "newresearch"
                                        }
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover4
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                        >
                                          <div className="position-relative">
                                            {notification > 0 ? (
                                              <i className="pr-2  notify d-inline-block ">
                                                <span className="rnotify">
                                                  {parseFloat(notification)}
                                                </span>
                                              </i>
                                            ) : null}
                                          </div>
                                          <div>
                                            <p className={"text_dark m-0"}>
                                              Research
                                            </p>
                                          </div>
                                        </div>
                                      </Link>
                                    </div>
                                  ) : null}

                                  {this.props.user.planned !== "p199" ? (
                                    <div
                                      className={
                                        "nav-item " +
                                        (this.state.hover5
                                          ? "activeImg"
                                          : "inactiveImg mobileMenu  ") +
                                        " " +
                                        (this.state.icon == "resource"
                                          ? "activeHover"
                                          : "")
                                      }
                                      onClick={() => {
                                        this.setState({
                                          hover1: false,
                                          hover2: false,
                                          hover3: false,
                                          hover4: false,
                                          hover5: true,
                                          hover6: false,
                                          hover7: false,
                                          hover8: false,
                                          hover9: false,
                                          hover10: false,
                                        });
                                        this.onSelect("resource");
                                        this.setState({ mobileToggle: false });
                                      }}
                                    >
                                      <Link
                                        to="/resource"
                                        className="nav-link text-secondary navItemLink"
                                      >
                                        <div
                                          className={
                                            this.state.hover5
                                              ? "imgItems mobileImgSelected"
                                              : "imgItems"
                                          }
                                        >
                                          <div>
                                            <p className={"text_dark m-0"}>
                                              Resource
                                            </p>
                                          </div>
                                        </div>
                                      </Link>{" "}
                                    </div>
                                  ) : null}

                                  <>
                                    {this.props.user.program[0].comment ==
                                      "FAGOLD" ||
                                    this.props.user.cprogram ==
                                      "FMEPPLATINUM" ||
                                    this.props.user.program[0].comment ==
                                      "EDMPGOLD" ? (
                                      <div
                                        className={
                                          "nav-item  lifesessionav " +
                                          (this.state.hover8
                                            ? "activeImg"
                                            : "inactiveImg mobileMenu  ") +
                                          " " +
                                          (this.state.icon == "lifesession"
                                            ? "activeHover"
                                            : "")
                                        }
                                        style={{ marginRight: "0px" }}
                                        onClick={() => {
                                          this.setState({
                                            hover1: false,
                                            hover2: false,
                                            hover3: false,
                                            hover4: false,
                                            hover5: false,
                                            hover6: false,
                                            hover7: false,
                                            hover8: true,
                                            hover9: false,
                                            hover10: false,
                                          });
                                          this.onSelect("lifesession");
                                          this.setState({ mobileToggle: false });
                                        }}
                                      >
                                        <Link
                                          to="/lifesession"
                                          className="nav-link text-secondary navItemLink"
                                        >
                                          <div
                                            className={
                                              this.state.hover8
                                                ? "imgItems mobileImgSelected"
                                                : "imgItems"
                                            }
                                            style={{ position: "relative" }}
                                          >
                                            {this.state.livesession ? (
                                              <div className="ml-5">
                                                <i className="notify d-inline-block">
                                                  {1}
                                                </i>
                                              </div>
                                            ) : null}
                                            <div
                                              style={{ width: "max-content" }}
                                            >
                                              <p className={"text_dark m-0"}>
                                                Live Sessions
                                              </p>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    ) : null}
                                  </>

                                  <>
                                    {this.props.user.cprogram == "FAFMEP" && this.props.user.program.some((e) => e.program_name == "FAFMEP" && e.comment == "FAGOLD") ||
                                      this.props.user.cprogram == "FAFMEP" && this.props.user.program.some((e) => e.program_name == "FAFMEP" && e.comment == "EDMPGOLD") ||
                                      this.props.user.cprogram == "FMEPPLATINUM" ? (
                                      <div
                                        className={
                                          "nav-item " +
                                          (this.state.hover10
                                            ? "activeImg"
                                            : "inactiveImg mobileMenu  ") +
                                          " " +
                                          (this.state.icon == "aiinterview"
                                            ? "activeHover"
                                            : "")
                                        }
                                        onClick={() => {
                                          this.setState({
                                            hover1: false,
                                            hover2: false,
                                            hover3: false,
                                            hover4: false,
                                            hover5: false,
                                            hover6: false,
                                            hover7: false,
                                            hover8: false,
                                            hover9: false,
                                            hover10: true,
                                          });
                                          this.onSelect("resource");
                                          this.setState({ mobileToggle: false });
                                        }}
                                      >
                                       <Link
                                    to="/mockinterview"
                                    className="nav-link text-secondary navItemLink AIint"
                                  >
                                    <div
                                      className={
                                        this.state.hover10
                                          ? "imgItems mobileImgSelected AIint"
                                          : "imgItems AIint"
                                      }
                                    >
                                            <div
                                            style={{ width: "max-content" }}
                                            >
                                              <p
                                                className={"text_dark m-0"}
                                                
                                              >
                                                AI Interview
                                              </p>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    ) : null}
                                  </>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Navbar>
    );
  }
}
Header.propTypes = {
  user: PropTypes.object.isRequired,
  getCurrentUserDetails: PropTypes.func.isRequired,
  getnotifyscore: PropTypes.func.isRequired,
  getECodePermission: PropTypes.func.isRequired,
  livesession: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user.info,
  notify: state.research.notify.data,
  check: state.games.permission.data,
  livesession: state.user.live,
});

export default withRouter(
  connect(
    mapStateToProps,
    {
      getCurrentUserDetails,
      getnotifyscore,
      getECodePermission,
      getlivesession,
    }
  )(Header)
);

//export default withRouter(Header);