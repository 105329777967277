import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M1_V3 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, max.data);

    // if (typeof max.data=="undefined" ||max.data<1) {
    //   return (
    //     <div className="text-center">
    //       <h4 className="ibep-textual-title">Please complete the Project-1 before proceeding ahead</h4>
    //     </div>
    //   );
    // }
    // else{
    return (
      <div className="container">
        <h4 className="ibep-textual-title">1. Data Validation</h4>
        <p className="ibep-textual-para">
          ● <strong>Purpose</strong>: Controls the type of data entered into a
          cell or range.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Steps to Apply Data Validation</strong>:
        </p>
        <p className="ibep-textual-para">
          1. Open Excel and create a blank workbook.
        </p>
        <p className="ibep-textual-para">
          2. Rename the sheet to "Task Tracker".
        </p>
        <p className="ibep-textual-para">
          3. Add column headers: "Tasks" (Column A) and "Status" (Column B).
        </p>
        <p className="ibep-textual-para">
          4. Enter sample tasks (e.g., "Buy groceries," "Pay Electricity bill,"
          "Car repair").
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image001.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          5. Create another sheet named "Options" and list valid statuses
          ("Done," "Pending," "Postponed," etc.).
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image002.jpg"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          6. Select cells B2 to B4 and navigate to{" "}
          <strong>Data &gt; Data Validation</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image003.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image004.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          7. Choose <strong>List</strong> in the Allow dropdown.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image005.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          8. Set the source to the "Options" sheet column.
        </p>
        <p className="ibep-textual-para">
          9. Ensure that the <strong>Ignore Blanks</strong> and{" "}
          <strong>In-cell Dropdown</strong> are checked.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image006.jpg"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">10.Click OK to apply.</p>
        <p className="ibep-textual-para">
          ● <strong>Effect</strong>: Users can only select predefined values;
          any other input triggers an error.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image007.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Modifications</strong>: Add new options to the "Options"
          sheet to update the dropdown.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image008.jpg"
            alt=""
          />
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image009.jpg"
            alt=""
          />
        </p>
        <h4 className="ibep-textual-title">2. Formatting Cells</h4>
        <p className="ibep-textual-para">
          ● <strong>Purpose</strong>: Enhances readability and presentation of
          data.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Key Formatting Options</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ Font, alignment, number format, borders, and fill color.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image010.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Example</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ Select A1 and B1 (headers) and apply the yellow fill color.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image011.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Apply <strong>Thick Outside Border</strong> to the entire data
          range.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image012.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image013.png"
            alt=""
          />
        </p>
        <h4 className="ibep-textual-title">3. Conditional Formatting</h4>
        <p className="ibep-textual-para">
          ● <strong>Purpose</strong>: Highlights important information based on
          conditions.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Steps to Highlight Salaries Above Rs. 70,000</strong>:
        </p>
        <p className="ibep-textual-para">○ Select the Salary column.</p>
        <p className="ibep-textual-para">
          ○ Go to{" "}
          <strong>
            Home &gt; Conditional Formatting &gt; Highlight Cell Rules &gt;
            Greater Than
          </strong>
          .
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image014.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image015.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Enter <strong>70000</strong> and choose "Yellow fill with the dark
          yellow text."
        </p>
        <p className="ibep-textual-para">
          ○ Click OK.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image016.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Steps to Highlight Bottom 10 Values</strong>:
        </p>
        <p className="ibep-textual-para">○ Select the Salary column.</p>
        <p className="ibep-textual-para">
          ○ Go to{" "}
          <strong>
            Home &gt; Conditional Formatting &gt; Top/Bottom Rules &gt; Bottom
            10 Items
          </strong>
          .
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image017.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Select "Light red fill with dark red text."
        </p>
        <p className="ibep-textual-para">
          ○ Click OK.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image018.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image019.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Managing Rules</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ Use <strong>Manage Rules</strong> to edit or delete existing rules.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image020.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ Apply rules to the entire sheet or specific sections.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image021.png"
            alt=""
          />
        </p>
        <h4 className="ibep-textual-title">4. AutoSum Feature</h4>
        <p className="ibep-textual-para">
          ● <strong>Purpose</strong>: Quickly calculates totals, counts, and
          averages.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Steps to Sum the Salary Column</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ Select the Salary column (without the header).
        </p>
        <p className="ibep-textual-para">
          ○ Go to <strong>Home &gt; AutoSum</strong>.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image022.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ The total sum appears below the selected range.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image023.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Additional Functions</strong>:
        </p>
        <p className="ibep-textual-para">
          ○ Click the dropdown beside AutoSum for{" "}
          <strong>Count, Average, Min, and Max</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image024.png"
            alt=""
          />
        </p>
        <p className="ibep-textual-para">
          ○ A glance at these values in the bottom-right bar.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m1v3/image025.jpg"
            alt=""
          />
        </p>
        <h4 className="ibep-textual-title">5. Summary</h4>
        <p className="ibep-textual-para">
          ● <strong>Data Validation</strong>: Ensures only specified inputs are
          entered.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Formatting Cells</strong>: Enhances readability using
          colors, fonts, and borders.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Conditional Formatting</strong>: Highlights key data based
          on conditions.
        </p>
        <p className="ibep-textual-para">
          ● <strong>AutoSum</strong>: Provides quick calculations without manual
          formulas.
        </p>
        <p className="ibep-textual-para">
          Excel provides numerous tools to improve data management and
          visualization.
        </p>
      </div>
    );
    // }
  }
}
XL_M1_V3.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M1_V3);
