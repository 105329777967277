import React, { Component } from 'react';
import { connect } from "react-redux";
// import './ErrorPage.css';  // Import your custom CSS if needed
import { ErrorLog } from '../../actions/errorlog';
import PropTypes from "prop-types";

class ErrorPage extends Component {
    
    componentDidMount() {
        console.log(this.props.user)
        this.props.ErrorLog({user_id: this.props.user.user_id,error:`${this.props.servicename} service error in ${this.props.user.cprogram}`});
    }
  
    render() {
        return (
        
        <div
            className="modal fade show"
            id="maintenanceModal"
            tabIndex="-1"
            aria-labelledby="maintenanceModalLabel"
            aria-modal="true"
            role="dialog"
            style={{ display: 'block',  fontSize: "1vw!important" }}
            
        >
            <div className="modal-dialog modal-dialog-centered modal-sm  modal-lg errormodal">
            <div className="modal-content" style={{marginTop: "7em"}}>
                <div className="modal-body">
                <div className="container">
                    <div className="row error">
                    <div className="col-md-6 centertext col-sm-12 d-flex flex-column justify-content-center align-items-center " style={{ gap: '1em' }}>
                        <h1 id="errorpageh1">We will be back online shortly!</h1>
                        <h2 id="errorpageh2">
                        Our {this.props.servicename} service is  currently undergoing scheduled maintenance.
                        </h2>
                    </div>

                    <div className="col-md-6 col-sm-12 text-center">
                        <img
                        src="./img/error.png"
                        alt="Error"
                        className="img-fluid"
                        style={{ maxWidth: '100%', height: 'auto' }}
                        />
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
        );
    }
}

ErrorPage.propTypes = {
    ErrorLog: PropTypes.func.isRequired, 
    user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    user: state.user.info
});

export default connect(
    mapStateToProps,
    { ErrorLog }
  )(ErrorPage);


// export default ErrorPage;
