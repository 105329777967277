import React from 'react';
import { path } from 'react-stockcharts/lib/utils';
export default function Index() {
  const pathname = window.location.pathname;
  console.log(pathname, "pathname");
  if (pathname === "/portfolio" || pathname === "/BANotes") {
    return (
      <div>
        <img src="img/shape11.png" alt="Shape1" className="shape1" />
        <img src="img/shape2.png" alt="Shape2" className="shape2" />
        <img src="img/shape3.png" alt="Shape3" className="shape3" />
        <img src="img/shape4.png" alt="Shape4" className="shape4" />
        <img src="img/shape5.png" alt="Shape5" className="shape5" />
        {/* <img src="img/shape6.png" alt="Shape6" className="shape6" /> */}
        <img src="img/shape7.png" alt="Shape7" className="shape7" />
        <img src="img/shape8.png" alt="Shape8" className="shape8" />
      </div>
    );
  } else
  return(
    <div>
        <img src="img/shape11.png" alt="Shape1" className="shape1" />
         <img src="img/shape2.png" alt="Shape2" className="shape2" />
        <img src="img/shape3.png" alt="Shape3" className="shape3" />
       </div>
  )
}






