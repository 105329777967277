import React from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { logout } from "../../actions/authActions";
import { switchprogram } from "../../actions/selectActions";
import { Redirect } from "react-router-dom";
import { GoDotFill } from "react-icons/go";
import { markRead } from "../../actions/notificationActions";

import {
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";

const ProfilePic = styled.img`
  border-radius: 50%;
  width: 30px;
`;

const ProfileName = styled.span`
  font-weight: bold;
  padding-left: 10px;
  font-size: 15px;
`;

class ProfileDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownOpen: false,
      redirect: false,
      ivep: "IVEP",
      fmep: "NORMAL",
      ba: "BA",
      ibep: "IBEP",
      bafmep: "BAFMEP",
      FnO: "FnO",
      notiP: false,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  };
  setRedirect = (url) => {
    this.setState({ redirect: url, dropdownOpen: false });
  };
  setRedirect1 = (url) => {
    return <Redirect to={url} />;
  };

  handleClicknotification = () => {
    this.setState({ notiP: true });

    this.props.markRead(5);
  };
  render() {
    let notification = this.props.notification + 1;
    console.log(notification, "Notification from profile");
    const photo =
      this.props.user.info.photo == "" || this.props.user.info.photo == null
        ? "img/active/newprofile.png"
        : `${window.BASE_URL}/uploads/userProfile/${
            this.props.user.info.photo
          }`;
    if (this.state.redirect) {
      this.setState({ redirect: false, dropdownOpen: false });
      return <Redirect to={this.state.redirect} />;
    }
    return (
      <div className="dropdowndiv">
        <Dropdown
          className="dropdowndiv"
          caret
          size="lg"
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
        >
          <DropdownToggle className="dtoggle" caret color="light">
            <ProfilePic
              src={photo}
              width="100px"
              className=""
              style={{ borderRadius: "10px", color: "gray" }}
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/img/profile.png";
              }}
            />
            <ProfileName>Profile</ProfileName>
            {this.props.user.info.planned != "ivep" &&
            this.props.user.info.cprogram != "IBEPGOLD" &&
            this.props.user.info.cprogram != "IBEPSILVER" &&
            this.props.user.info.cprogram != "BA" &&
            this.props.user.info.cprogram != "BAGOLD" &&
            this.props.user.info.cprogram != "SSEP" &&
            this.props.user.info.cprogram != "SSEPGOLD" &&
            this.props.user.info.cprogram != "FnO" &&
            this.props.user.info.cprogram != "ML" &&
            this.props.user.info.cprogram != "DS" &&
            this.props.user.info.cprogram != "DSGOLD" &&
            this.props.user.info.cprogram != "PYTHON" &&
            parseInt(notification) > 0 ? (
              <>
                {parseInt(notification)  > 0  && !this.state.notiP? (
                  <GoDotFill className="notiDot" />
                ) : null}
              </>
            ) : null}
          </DropdownToggle>
          <DropdownMenu>
            {(this.props.user &&
              this.props.user.info &&
              parseInt(this.props.user.info.post_signup_steps) > 1) ||
            this.props.user.info.planned == "ivep" ||
            this.props.user.info.cprogram == "IVEP" ? (
              <React.Fragment>
                <DropdownItem
                  onClick={() => {
                    this.setRedirect("/profile");
                    this.props.getRedirect("profile");
                  }}
                >
                  Edit Profile
                </DropdownItem>
                {this.props.user.info.BA == 1 &&
                this.props.user.info.baibep > 0 ? (
                  <DropdownItem
                    onClick={() => {
                      this.props.switchprogram(
                        parseInt(this.props.user.info.baibep) >= 2
                          ? this.state.ibep
                          : this.state.ba
                      );
                      this.setRedirect1("/newresource");
                    }}
                  >
                    {parseInt(this.props.user.info.baibep) >= 2
                      ? "Go to IBEP"
                      : this.props.user.info.cprogram == "NORMAL"
                      ? "Go to IBEP"
                      : "Go to BA"}
                  </DropdownItem>
                ) : null}
                <DropdownItem />
                {this.props.user.info.cprogram != "DSGOLD" &&
                this.props.user.info.cprogram != "DS"&& this.props.user.info.cprogram != "PYTHON" && this.props.user.info.cprogram != "FnO" && this.props.user.info.cprogram != "ML" ? (
                  <div>
                   
                      <DropdownItem onClick={() => this.setRedirect("/faq")}>
                        FAQs
                      </DropdownItem>
                    
                    <DropdownItem />{" "}
                  </div>
                ) : null}
                {this.props.user.info.planned == "ivep" ||
                this.props.user.info.cprogram == "IBEPGOLD" ||
                this.props.user.info.cprogram == "IBEPSILVER" ||
                this.props.user.info.cprogram == "BA" ||
                this.props.user.info.cprogram == "BAGOLD" ||
                this.props.user.info.cprogram == "SSEP" ||
                this.props.user.info.cprogram == "SSEPGOLD" ||
                this.props.user.info.cprogram == "FnO" ||
                this.props.user.info.cprogram == "DS" ||
                this.props.user.info.cprogram == "DSGOLD" ||
                this.props.user.info.cprogram == "ML" ||  this.props.user.info.cprogram == "PYTHON" ? null : (
                  <DropdownItem
                    onClick={() => {
                      this.setRedirect("/notifications");
                      // this.setState({notiP : !this.state.notiP})
                    }}
                  >
                    Notification
                    {!this.state.notiP && (
                      <span
                        className="notiNum badge badge-danger"
                        onClick={this.handleClicknotification}
                      >
                        {notification}
                      </span>
                    )}
                  </DropdownItem>
                )}
              </React.Fragment>
            ) : null}
            <DropdownItem onClick={this.props.logout}>Logout</DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

ProfileDropdown.propTypes = {
  user: PropTypes.object.isRequired,
  markRead: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  switchprogram: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(
  mapStateToProps,
  { logout, switchprogram, markRead }
)(ProfileDropdown);
