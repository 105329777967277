import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M4_V1 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <p className="ibep-textual-title">
          <strong>Regression Analysis: Overview</strong>
          
        </p>
        <h3 className="ibep-textual-title">
          <strong>1. Introduction to Regression Analysis</strong>
        </h3>
        <h3 className="ibep-textual-title">
          ➢ Regression Analysis is a statistical tool used to examine
          relationships between variables.
        </h3>
        <p className="ibep-textual-para">
          ● It helps make data-driven decisions in fields like business
          forecasting and scientific research.
        </p>
        <p className="ibep-textual-para">
          ● The analysis identifies how independent variables influence a
          dependent variable.
        </p>
        <p className="ibep-textual-para">
          ● Businesses use regression to predict future outcomes, assess risks,
          and optimize strategies.
        </p>
        <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img xlm4v1"
            src="/img/excel/m4v1/image001.png"
            alt=""
            width="104"
            height="104"
            hspace="12"
            vspace="12"
          />
        <h3 className="ibep-textual-title">
          <strong>
            2. Understanding Regression Analysis through an Example
          </strong>
        </h3>
        <p className="ibep-textual-para">
          ● Consider predicting exam marks based on factors such as:
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v1/image002.png"
            alt=""
            width="73"
            height="73"
            hspace="2"
            vspace="2"
          />
        </p>
        <p className="ibep-textual-para">○ Hours spent self-studying.</p>
        <p className="ibep-textual-para">○ Hours attended in class lectures.</p>
        <p className="ibep-textual-para">
          ○ Hours spent on extracurricular activities.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Dependent Variable (Y):</strong> Exam marks (impacted by
          independent variables).
        </p>
        <p className="ibep-textual-para">
          ● <strong>Independent Variables (X):</strong> Study hours, class
          attendance, extracurricular activity hours.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img xlm4v1"
            src="/img/excel/m4v1/image003.png "
            alt=""
            width="73"
            height="81"
            hspace="2"
            vspace="2"
          />
        </p>
        <p className="ibep-textual-para">
          ● Regression helps estimate how changes in these independent variables
          impact the dependent variable.
        </p>
        <h3 className="ibep-textual-title">
          <strong>3. Types of Regression</strong>
        </h3>
        <h4>
          <strong>A. Simple Linear Regression</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Analyzes the relationship between one independent variable and one
          dependent variable.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Example</strong>: If marks depend only on study hours, it is
          a simple linear regression.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v1/image004.png"
            alt=""
            width="134"
            height="54"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Formula:</strong> Y = a + bX
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Y:</strong> Dependent variable (exam marks)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>X:</strong> Independent variable (study hours)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>b (Beta):</strong> Slope (impact of X on Y)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>a (Alpha):</strong> Intercept (accounts for other minor
          factors)
        </p>
        <h4>
          <strong>B. Multiple Linear Regression</strong>
        </h4>
        <p className="ibep-textual-para">
          ● Examines relationships between multiple independent variables and a
          dependent variable.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Example</strong>: If marks depend on study hours, class
          attendance, and extracurricular activities.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v1/image005.png"
            alt=""
            width="96"
            height="96"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          ● <strong>Formula:</strong> Y = a + b1X1 + b2X2 + b3X3
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Y:</strong> Dependent variable (exam marks)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>a (Alpha):</strong> Intercept (accounts for additional
          factors)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>b1, b2, b3:</strong> Slopes (impact of each X on Y)
        </p>
        <p className="ibep-textual-para">
          ○ <strong>X1, X2, X3:</strong> Independent variables (study hours,
          class attendance, extracurricular activities)
        </p>
        <p className="ibep-textual-para">
          ● Beta values can be negative if the independent and dependent
          variables have an inverse relationship.
        </p>
        <h3 className="ibep-textual-title">
          <strong>4. </strong>
          <strong>
            Here are some other key terms you&rsquo;ll encounter during
            regression analysis:
          </strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>R-Squared (R&sup2;):</strong> Measures how well independent
          variables explain variations in the dependent variable.
        </p>
        <p className="ibep-textual-para">
          ○ Higher R&sup2; values indicate a better model.
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Example</strong>: R&sup2; = 0.95 means 95% of the variation
          is explained by independent variables.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Adjusted R-Squared:</strong> Adjusted to remove bias caused
          by adding irrelevant variables.
        </p>
        <p className="ibep-textual-para">
          ○ Preferred in multiple regression models.
        </p>
        <p className="ibep-textual-para">
          ● <strong>P-Value:</strong> Determines statistical significance of
          independent variables.
        </p>
        <p className="ibep-textual-para">
          ○ P-value &lt; 0.05: Independent variable significantly impacts the
          dependent variable.
        </p>
        <p className="ibep-textual-para">
          ○ P-value &gt; 0.05: No significant impact.
        </p>
        <h3 className="ibep-textual-title">
          <strong>5. Importance of Regression Analysis</strong>
        </h3>
        <ol start="1" type="1">
          <li className="ibep-textual-sub-para">
            <strong>Understanding Relationships:</strong> Quantifies how one
            variable affects another. <strong>Example</strong>: Businesses can
            use it to understand how changes in <strong>income</strong> affect{" "}
            <strong>consumer spending</strong>.
          </li>
        </ol>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img xlm4v1"
            src="/img/excel/m4v1/image006.png"
            alt=""
            width="86"
            height="89"
            hspace="2"
            vspace="2"
          />
        </p>
        <p className="ibep-textual-para">Income Consumer spending</p>
        <ol start="2" type="1">
          <li className="ibep-textual-sub-para">
            <strong>Making Predictions:</strong> Uses historical data to
            forecast future trends. <strong>Example</strong>: If we know the{" "}
            <strong>income</strong> in the coming months, we can predict how{" "}
            <strong>consumer spending</strong> might change.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img xlm4v1"
              src="/img/excel/m4v1/image007.png"
              alt=""
              width="67"
              height="67"
              hspace="12"
              vspace="12"
            />
          </li>
        </ol>
        <ol start="3" type="1">
          <li className="ibep-textual-sub-para">
            <strong>Optimizing Strategies:</strong> Helps businesses make
            data-driven decisions, such as pricing and marketing strategies.{" "}
            <strong>Example</strong>: Pricing or marketing, based on what drives{" "}
            <strong>consumer spending</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img xlm4v1"
              src="/img/excel/m4v1/image008.png"
              alt=""
              width="97"
              height="97"
              hspace="12"
              vspace="12"
            />
          </li>
        </ol>
        <h3 className="ibep-textual-title">
          <strong>6. Conclusion</strong>
        </h3>
        <p className="ibep-textual-para">
          ● Regression analysis is a vital tool for understanding and predicting
          relationships between variables.
        </p>
        <p className="ibep-textual-para">
          ● Simple and multiple linear regression are commonly used models.
        </p>
        <p className="ibep-textual-para">
          ● Provides valuable insights for data-driven decision-making.
        </p>
        <p className="ibep-textual-para">
          ● Future lessons will cover practical applications of regression
          models.
        </p>
      </div>
    );
  }
}
XL_M4_V1.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M4_V1);
