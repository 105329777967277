import axios from "axios";

import { SELECT_PROGRAM } from "./types";
import { getCurrentUserDetails } from "./userActions";

export const selectprogram = selection => async dispatch => {
  axios
    .post("/api/selectprogram/Done", {
      selection: selection
    })
    .then(async res => {
      dispatch(getCurrentUserDetails());
    })
    .catch(err => console.log(err));
};

export const switchprogram = selection => async dispatch => {
  axios
    .post("/api/selectprogram/switch", {
      selection: selection
    })
    .then(async res => {
      dispatch(getCurrentUserDetails());
    })
    .catch(err => console.log(err));
};
