import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M4_V2 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <div>
        <h1 className="ibep-textual-title">
          <strong>Regression Analysis in Excel</strong>
        </h1>
        <h2 className="ibep-textual-title">
          <strong>Introduction</strong>
        </h2>
        <p className="ibep-textual-para">
          Regression is a <strong>statistical method</strong> used to examine
          the relationship between two or more variables. We will analyze the
          relationship between <strong>Disposable Income (DI)</strong> and
          <strong>Consumer Spending (CS)</strong> and build a
          <strong>simple regression model</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 1: Sample Dataset</strong>
        </h2>
        <p className="ibep-textual-para">
          To begin, we need a dataset. For this example, we will use a dataset
          containing two columns:
        </p>
        <p className="ibep-textual-para">
          ● <strong>Disposable Income (DI)</strong>
        </p>
        <p className="ibep-textual-para">
          ● <strong>Consumer Spending (CS)</strong>
        </p>
        <p className="ibep-textual-para">
          The provided data highlights trends in
          <strong>Disposable Income and Consumer Spending</strong>. The dataset
          is from <strong>LalaLand</strong>, a fictional developing country
          where our company, <strong>ABC Ltd</strong>, is considering expansion.
        </p>
        <p className="ibep-textual-para">
          LalaLand shows promising economic indicators, particularly a
          <strong>steady increase in Disposable Income</strong>, which
          correlates positively with rising <strong>Consumer Spending</strong>.
          However, to make
          <strong>informed decisions</strong>, we need to assess how
          <strong>Consumer Spending depends on Disposable Income</strong>. To
          achieve this, we will conduct a{" "}
          <strong>regression analysis using Excel</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 2: Steps to Perform Regression in Excel</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>1. Organize the Data</strong>
        </h3>
        <p className="ibep-textual-para">
          ● Open <strong>Excel</strong> and paste the dataset into a new
          worksheet.
        </p>
        <p className="ibep-textual-para">
          ● Place <strong>Disposable Income (DI)</strong> in
          <strong>Column B</strong> and <strong>Consumer Spending (CS)</strong>{" "}
          in
          <strong>Column C</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Ensure the data is <strong>clean</strong> and free of any
          <strong>blank cells</strong>.
        </p>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image001.png"
            alt=""
            width="412"
            height="347"
            hspace="12"
            vspace="12"
          />
        </p>
        <h3 className="ibep-textual-title">
          <strong>2. Enable the Analysis ToolPak</strong>
        </h3>
        <p className="ibep-textual-para">
          Before performing regression, we need to enable the
          <strong>Analysis ToolPak</strong>:
        </p>
        <p className="ibep-textual-para">
          ● Go to <strong>File &gt; Options &gt; Add-ins</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Under <strong>Manage</strong>, select <strong>Excel Add-ins</strong>{" "}
          and click <strong>Go</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Check the box for <strong>Analysis ToolPak</strong> and click
          <strong>OK</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image002.png"
            alt=""
            width="424"
            height="346"
            hspace="12"
            vspace="12"
          />
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image003.png"
            alt=""
            width="288"
            height="355"
            hspace="12"
            vspace="12"
          />
        </p>
        <h3 className="ibep-textual-title">
          <strong>3. Perform the Regression</strong>
        </h3>
        <p className="ibep-textual-para">
          Now that the <strong>Analysis ToolPak</strong> is enabled, follow
          these steps:
        </p>
        <p className="ibep-textual-para">
          ● Go to the <strong>Data</strong> tab and click on
          <strong>Data Analysis</strong> in the <strong>Analysis</strong> group.
        </p>
        <p className="ibep-textual-para">
          ● From the list of tools, choose <strong>Regression</strong> and click
          <strong>OK</strong>.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image004.png"
            alt=""
            width="613"
            height="160"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">● Fill in the input ranges:</p>
        <p className="ibep-textual-para">
          ○ <strong>Input Y Range:</strong> Select the
          <strong>Consumer Spending column (Column C)</strong> &rarr; This is
          the
          <strong>dependent variable</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ <strong>Input X Range:</strong> Select the
          <strong>Disposable Income column (Column B)</strong> &rarr; This is
          the
          <strong>independent variable</strong>.
        </p>
        <p className="ibep-textual-para">
          ○ Check the <strong>Labels</strong> box if your data includes headers.
        </p>
        <p className="ibep-textual-para">
          ○ Select an <strong>Output Range</strong> or choose a
          <strong>new worksheet</strong> for the results.
        </p>
        <p className="ibep-textual-para">
          ○ Click <strong>OK</strong> to run the regression analysis.
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image005.png"
            alt=""
            width="410"
            height="367"
            hspace="12"
            vspace="12"
          />
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Step 3: Analyze the Results</strong>
        </h2>
        <h3 className="ibep-textual-title">
          <strong>Regression Equation</strong>
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image006.png"
            alt=""
            width="602"
            height="261"
            hspace="12"
            vspace="12"
          />
        </h3>
        <p className="ibep-textual-para">
          The regression output provides <strong>coefficients</strong> for the
          regression line. The equation follows this format:
        </p>
        <p className="ibep-textual-para">Y=Intercept+(Slope&times;X)</p>
        <p className="ibep-textual-para">Where:</p>
        <p className="ibep-textual-para">
          ● <strong>Intercept (b0):</strong> Represents the base spending when
          Disposable Income is <strong>$0</strong>.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Slope (b1):</strong> Indicates the <strong>increase</strong>{" "}
          in Consumer Spending for every <strong>unit increase</strong> in
          Disposable Income.
        </p>
        <h3 className="ibep-textual-title">
          <strong>R-Squared (R&sup2;) Value</strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>R&sup2;</strong> measures how well the model
          <strong>explains the variation</strong> in Consumer Spending.
        </p>
        <p className="ibep-textual-para">
          ● An <strong>R&sup2; value closer to 1</strong> indicates a
          <strong>better fit</strong>.
        </p>
        <p className="ibep-textual-para">
          ● Example: If <strong>R&sup2; = 0.9945</strong>, this means
          <strong>
            99.45% of the variation in Consumer Spending is explained by
            Disposable Income
          </strong>
          .
        </p>
        <h3 className="ibep-textual-title">
          <strong>P-values</strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>P-values</strong> test the
          <strong>significance of the coefficients</strong>.
        </p>
        <p className="ibep-textual-para">
          ● If <strong>P-value &lt; 0.05</strong>, the variable is
          <strong>statistically significant</strong>, meaning it significantly
          contributes to the model.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">&nbsp;</h2>
        <h2 className="ibep-textual-title">&nbsp;</h2>
        <h2 className="ibep-textual-title">&nbsp;</h2>
        <h2 className="ibep-textual-title">
          <strong>Step 4: Create a Scatter Plot with Trendline</strong>
        </h2>
        <p className="ibep-textual-para">
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img"
            src="/img/excel/m4v2//image007.png"
            alt=""
            width="474"
            height="286"
            hspace="12"
            vspace="12"
          />
        </p>
        <p className="ibep-textual-para">
          To <strong>visualize</strong> the relationship between Disposable
          Income and Consumer Spending:
        </p>
        <ol start="1" type="1">
          <li className="ibep-textual-sub-para">
            <strong>Highlight</strong> the data for
            <strong>Disposable Income and Consumer Spending</strong>.
          </li>
          <li className="ibep-textual-sub-para">
            Go to the <strong>Insert</strong> tab and select
            <strong>Scatter Plot</strong>.
          </li>
        </ol>
        <h3 className="ibep-textual-title">
          <strong>Add a Trendline</strong>
        </h3>
        <p className="ibep-textual-para">
          ● <strong>Right-click</strong> on any data point and select
          <strong>Add Trendline</strong>.
        </p>
        <p className="ibep-textual-para">
          ● <strong>Check</strong> "Display Equation on Chart" and "R&sup2;
          value".
        </p>
        <p className="ibep-textual-para">
          The <strong>trendline</strong> will show the
          <strong>regression line</strong>, and the equation on the chart will
          match the{" "}
          <strong>regression equation from the analysis output</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <h2 className="ibep-textual-title">
          <strong>Interpretation</strong>
        </h2>
        <p className="ibep-textual-para">Based on this example:</p>
        <p className="ibep-textual-para">
          ● The <strong>regression equation</strong> might look like:
        </p>
        <p className="ibep-textual-para">
          Consumer Spending=Intercept+0.963&times;(Disposable Income)
        </p>
        <p className="ibep-textual-para">
          This means that for every <strong>$1 billion increase</strong> in
          Disposable Income,{" "}
          <strong>Consumer Spending increases by $0.963 billion</strong>.
        </p>
        <p className="ibep-textual-para">
          ● With <strong>R&sup2; = 0.9945</strong>, the model is
          <strong>highly reliable</strong>.
        </p>
        <div>
          <hr size="2" width="100%" />
        </div>
        <p className="ibep-textual-para">
          Regression analysis is a <strong>powerful tool</strong> for
          understanding relationships between variables and making
          <strong>data-driven decisions</strong>. It is especially useful in
          <strong>forecasting and trend analysis</strong>.
        </p>
      </div>
    );
  }
}
XL_M4_V2.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M4_V2);
