import React from "react";
import { Table } from "reactstrap";
import { getmaxresearch } from "../../../actions/ivepresearchActions";
import PropTypes from "prop-types";
import { connect } from "react-redux";
class XL_M3_V1 extends React.Component {
  componentDidMount() {
    this.props.getmaxresearch();
  }
  render() {
    let max = this.props.max;
    console.log(max, "maxxxx");
    return (
      <>
        <div>
          <h1 className="ibep-textual-title">
            <strong>Sensitivity Analysis in Excel</strong>
          </h1>
          <h2 className="ibep-textual-title">
            <strong>Introduction</strong>
          </h2>
          <p className="ibep-textual-para">
            Sensitivity Analysis is a powerful technique for assessing how
            changes in input variables impact an outcome. It helps in
            decision-making by identifying which factors have the most influence
            on a model&rsquo;s results.
          </p>
          <p className="ibep-textual-para">
            By performing sensitivity analysis, we can:
          </p>
          <p className="ibep-textual-para">
            ● Understand the impact of uncertainties in model inputs.
          </p>
          <p className="ibep-textual-para">
            ● Identify key drivers of profitability or risk.
          </p>
          <p className="ibep-textual-para">
            ● Make data-driven strategic decisions.
          </p>
          <h2 className="ibep-textual-title">
            <strong>What is Sensitivity Analysis?</strong>
          </h2>
          <p className="ibep-textual-para">
            Sensitivity Analysis examines how variations in one or more inputs
            affect a particular output. It allows users to test different
            scenarios and understand potential risks.
          </p>
          <p className="ibep-textual-para">
            In financial modeling and business applications, Sensitivity
            Analysis is commonly used to:
          </p>
          <p className="ibep-textual-para">
            ● Evaluate the impact of cost fluctuations on profitability.
          </p>
          <p className="ibep-textual-para">
            ● Assess risks in investment decisions.
          </p>
          <p className="ibep-textual-para">
            ● Determine optimal pricing strategies.
          </p>
          <h2 className="ibep-textual-title">
            <strong>Applications of Sensitivity Analysis</strong>
          </h2>
          <p className="ibep-textual-para">
            Sensitivity Analysis is widely applied in:
          </p>
          <p className="ibep-textual-para">
            ● <strong>Financial Modeling</strong> &ndash; Analyzing how cost and
            revenue changes affect profit.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Investment Decision-Making</strong> &ndash; Evaluating
            risk exposure in financial portfolios.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Manufacturing and Supply Chain</strong> &ndash; Assessing
            how material costs and production volumes influence margins.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Marketing and Sales Strategy</strong> &ndash;
            Understanding how price changes affect revenue.
          </p>
          <h2 className="ibep-textual-title">
            <strong>How Does Sensitivity Analysis Work?</strong>
          </h2>
          <p className="ibep-textual-para">
            Sensitivity Analysis involves the following steps:
          </p>
          <ol start="1" type="1">
            <li className="ibep-textual-sub-para">
              <strong>Defining the Model</strong> &ndash; Identify key input
              variables and output metrics.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Setting Up Scenarios</strong> &ndash; Establish a range of
              possible values for inputs.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Using Excel&rsquo;s What-If Analysis</strong> &ndash;
              Apply data tables to analyze different cases.
            </li>
            <li className="ibep-textual-sub-para">
              <strong>Interpreting Results</strong> &ndash; Evaluate the
              influence of changes on the outcome.
            </li>
          </ol>
          <h2 className="ibep-textual-title">
            <strong>Practical Implementation in Excel</strong>
          </h2>
          <h3 className="ibep-textual-title">
            <strong>Scenario: Smartphone Manufacturing Company</strong>
          </h3>
          <p className="ibep-textual-para">
            Let&rsquo;s consider a{" "}
            <strong>smartphone manufacturing business</strong> that wants to
            analyze profitability. The key factors affecting profitability
            include:
          </p>
          <p className="ibep-textual-para">
            ● <strong>Production Volume</strong> &ndash; Number of units
            produced.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Material Cost per Unit</strong> &ndash; Cost to
            manufacture each smartphone.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Labor Cost per Unit</strong> &ndash; Workforce cost per
            smartphone.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Fixed Costs</strong> &ndash; Overhead expenses like rent
            and utilities.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Sales Price per Unit</strong> &ndash; Selling price of the
            smartphone.
          </p>
          <h3 className="ibep-textual-title">
            <strong>Step 1: Define the Variables</strong>
          </h3>
          <p className="ibep-textual-para">
            The <strong>profitability model</strong> follows this formula:
          </p>
          <p className="ibep-textual-para">
            Net Profit = Total Revenue &minus; Total Costs
          </p>
          <p className="ibep-textual-para">Where:</p>
          <p className="ibep-textual-para">
            ● <strong>Total Revenue</strong> = (Units Sold) &times; (Sales Price
            per Unit)
          </p>
          <p className="ibep-textual-para">
            ● <strong>Total Costs</strong> = Fixed Costs + Variable Costs
            (Material Cost + Labor Cost)
          </p>
          <h3 className="ibep-textual-title">
            <strong>Step 2: Set Up the Excel Model</strong>
          </h3>
          <p className="ibep-textual-para">
            In Excel, create the following columns:
          </p>
          {/* <table border="1" width="372" cellspacing="0" cellpadding="0">
            <tbody>
              <tr>
                <td width="159">
                  <p className="ibep-textual-para">
                    <strong>Variable</strong>
                  </p>
                </td>
                <td width="213">
                  <p className="ibep-textual-para">
                    <strong>Example Value</strong>
                  </p>
                </td>
              </tr>
              <tr>
                <td width="159">
                  <p className="ibep-textual-para">Production Volume</p>
                </td>
                <td width="213">
                  <p className="ibep-textual-para">10,000 units</p>
                </td>
              </tr>
              <tr>
                <td width="159">
                  <p className="ibep-textual-para">Material Cost per Unit</p>
                </td>
                <td width="213">
                  <p className="ibep-textual-para">$50</p>
                </td>
              </tr>
              <tr>
                <td width="159">
                  <p className="ibep-textual-para">Labor Cost per Unit</p>
                </td>
                <td width="213">
                  <p className="ibep-textual-para">$30</p>
                </td>
              </tr>
              <tr>
                <td width="159">
                  <p className="ibep-textual-para">Fixed Costs</p>
                </td>
                <td width="213">
                  <p className="ibep-textual-para">$200,000</p>
                </td>
              </tr>
              <tr>
                <td width="159">
                  <p className="ibep-textual-para">Sales Price per Unit</p>
                </td>
                <td width="213">
                  <p className="ibep-textual-para">$150</p>
                  <p className="ibep-textual-para" />
                </td>
              </tr>
            </tbody>
          </table> */}
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>Variable</th>
                  <th>Example Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Production Volume</td>
                  <td>10,000 units</td>
                </tr>
                <tr>
                  <td>Material Cost per Unit</td>
                  <td>$50</td>
                </tr>
                <tr>
                  <td>Labor Cost per Unit</td>
                  <td>$30</td>
                </tr>
                <tr>
                  <td>Fixed Costs</td>
                  <td>$200,000</td>
                </tr>
                <tr>
                  <td>Sales Price per Unit</td>
                  <td>$150</td>
                </tr>
              </tbody>
            </table>
          </div>
          <br />
          <img
            className="rounded mx-auto d-block img-fluid ibep-textual-img xlm3v1"
            src="/img/excel/m3v1/image001.png"
            alt=""
            width="242"
            height="285"
            hspace="12"
            vspace="12"
          />
          <h3 className="ibep-textual-title">
            <strong>
              Step 3: Perform Sensitivity Analysis Using Excel&rsquo;s "What-If
              Analysis"
            </strong>
          </h3>
          <p className="ibep-textual-para">
            To analyze how{" "}
            <strong>material cost, labor cost, and sales price</strong> impact
            net profit, we used <strong>data tables</strong> in Excel.
          </p>
          <h4 className="ibep-textual-title">
            <strong>Material Cost Sensitivity Analysis</strong>
          </h4>
          <ol start="1" type="1">
            <li className="ibep-textual-sub-para">
              Highlight the data range for Material Cost and Net Profit.
            </li>
            <li className="ibep-textual-sub-para">
              Go to{" "}
              <strong>Data &rarr; What-If Analysis &rarr; Data Table</strong>.{" "}
              <img
                className="rounded mx-auto d-block img-fluid ibep-textual-img"
                src="/img/excel/m3v1/image002.png"
                alt=""
                width="602"
                height="56"
                hspace="12"
                vspace="12"
              />
            </li>
            <li className="ibep-textual-sub-para">
              Set the <strong>Column Input Cell</strong> to the Material Cost
              per Unit.
              <img
                className="rounded mx-auto d-block img-fluid ibep-textual-img"
                src="/img/excel/m3v1/image003.png"
                alt=""
                width="527"
                height="320"
                hspace="12"
                vspace="12"
              />
            </li>
          </ol>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image004.png"
              alt=""
              width="485"
              height="310"
              hspace="12"
              vspace="12"
            />
          </p>
          <ol start="4" type="1">
            <li className="ibep-textual-sub-para">
              Excel generates a table showing how Net Profit changes with
              Material Cost.
              <img
                className="rounded mx-auto d-block img-fluid ibep-textual-img"
                src="/img/excel/m3v1/image005.png"
                alt=""
                width="467"
                height="274"
                hspace="12"
                vspace="12"
              />
            </li>
          </ol>
          <h4 className="ibep-textual-title">
            <strong>Labor Cost Sensitivity Analysis</strong>
          </h4>
          <ol start="1" type="1">
            <li className="ibep-textual-sub-para">
              Select the Labor Cost and Net Profit columns.
            </li>
            <li className="ibep-textual-sub-para">
              Apply <strong>What-If Analysis &rarr; Data Table</strong>.
            </li>
            <li className="ibep-textual-sub-para">
              Set <strong>the column input cell</strong> for the labor cost per
              unit.
            </li>
            <li className="ibep-textual-sub-para">
              Analyze the changes in profitability.
            </li>
          </ol>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image006.png"
              alt=""
              width="529"
              height="350"
              hspace="12"
              vspace="12"
            />
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image007.png"
              alt=""
              width="560"
              height="358"
              hspace="12"
              vspace="12"
            />
          </p>
          <h4 className="ibep-textual-title">
            <strong>Sales Price Sensitivity Analysis</strong>
          </h4>
          <ol start="1" type="1">
            <li className="ibep-textual-sub-para">
              Choose the Sales Price and Net Profit data.
            </li>
            <li className="ibep-textual-sub-para">
              Use <strong>What-If Analysis &rarr; Data Table.</strong>
            </li>
            <li className="ibep-textual-sub-para">
              Set <strong>Column Input Cell</strong> to Sales Price per Unit.
            </li>
            <li className="ibep-textual-sub-para">
              Observe how different pricing strategies impact Net Profit.
            </li>
          </ol>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image008.png"
              alt=""
              width="508"
              height="356"
              hspace="12"
              vspace="12"
            />
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image009.png"
              alt=""
              width="535"
              height="354"
              hspace="12"
              vspace="12"
            />
          </p>
          <h3 className="ibep-textual-title">
            <strong>Step 4: Interpret the Results</strong>
          </h3>
          <h4 className="ibep-textual-title">
            <strong>1. Material Cost Sensitivity</strong>
          </h4>
          <p className="ibep-textual-para">
            ● If <strong>Material Cost increases</strong>,{" "}
            <strong>Net Profit decreases</strong>.
          </p>
          <p className="ibep-textual-para">● Example:</p>
          <p className="ibep-textual-para">
            ○ At <strong>$55 Material Cost</strong>, Net Profit ={" "}
            <strong>$650,000.</strong>
          </p>
          <p className="ibep-textual-para">
            ○ At <strong>$75 Material Cost</strong>, Net Profit drops to{" "}
            <strong>$450,000</strong>.
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image010.png"
              alt=""
              width="235"
              height="233"
              hspace="12"
              vspace="12"
            />
          </p>
          <h4 className="ibep-textual-title">
            <strong>2. Labor Cost Sensitivity</strong>
          </h4>
          <p className="ibep-textual-para">
            ● If <strong>Labor costs increase</strong>,{" "}
            <strong>Net Profit declines</strong>.
          </p>
          <p className="ibep-textual-para">● Example:</p>
          <p className="ibep-textual-para">
            ○ At <strong>$27 Labor Cost</strong>, Net Profit =
            <strong>$630,000.</strong>
          </p>
          <p className="ibep-textual-para">
            ○ At <strong>$45 Labor Cost</strong>, Net Profit drops to{" "}
            <strong>$450,000</strong>.{" "}
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image011.png"
              alt=""
              width="237"
              height="253"
              hspace="12"
              vspace="12"
            />
          </p>
          <h4 className="ibep-textual-title">
            <strong>3. Sales Price Sensitivity</strong>
          </h4>
          <p className="ibep-textual-para">
            ● If the <strong>Sales Price increases</strong>, the{" "}
            <strong>Net Profit rises</strong>.
          </p>
          <p className="ibep-textual-para">● Example:</p>
          <p className="ibep-textual-para">
            ○ At <strong>$160 Sales Price</strong>, Net Profit ={" "}
            <strong>$800,000.</strong>
          </p>
          <p className="ibep-textual-para">
            ○ At <strong>$200 Sales Price</strong>, Net Profit ={" "}
            <strong>$1,200,000</strong>.
          </p>
          <p className="ibep-textual-para">
            <img
              className="rounded mx-auto d-block img-fluid ibep-textual-img"
              src="/img/excel/m3v1/image012.png"
              alt=""
              width="231"
              height="288"
              hspace="12"
              vspace="12"
            />
          </p>
          <h3 className="ibep-textual-title">
            <strong>Step 5: Key Insights</strong>
          </h3>
          <p className="ibep-textual-para">
            ● <strong>Material &amp; Labor Costs have a negative impact</strong>{" "}
            on Net Profit.
          </p>
          <p className="ibep-textual-para">
            ● <strong>Sales Price has a positive impact</strong>, but increasing
            it too much may reduce demand.
          </p>
          <p className="ibep-textual-para">
            ●{" "}
            <strong>
              Businesses should balance cost control and pricing strategy
            </strong>{" "}
            to maximize profits.
          </p>
          <h2 className="ibep-textual-title">
            <strong>Conclusion</strong>
          </h2>
          <p className="ibep-textual-para">
            Sensitivity Analysis is an essential decision-making tool that:
          </p>
          <p className="ibep-textual-para">
            ● Identifies key cost drivers affecting profitability.
          </p>
          <p className="ibep-textual-para">
            ● Helps businesses manage risk and uncertainty.
          </p>
          <p className="ibep-textual-para">
            ● Enables strategic pricing and cost optimization.
          </p>
          <p className="ibep-textual-para">
            With <strong>Excel&rsquo;s What-If Analysis</strong>, businesses can
            explore multiple scenarios and make informed decisions to ensure
            financial stability and growth.
          </p>
          <div>
            <hr size="2" width="100%" />
          </div>
        </div>
      </>
    );
  }
}
XL_M3_V1.propTypes = {
  getmaxresearch: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  max: state.ivepresearch.max,
});
export default connect(
  mapStateToProps,
  { getmaxresearch }
)(XL_M3_V1);
