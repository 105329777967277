import React from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getScrips } from "../../actions/scripsActions";
import { Link, withRouter } from "react-router-dom";

class Ticker extends React.Component {
  componentDidMount() {
    this.props.getScrips(
      { segment: "ALL", industry: "ALL", sort: "NAME" },
      false
    );
  }
  // comment

  getIndiceTicker = indice => {
    let code = indice.code || indice.no_code;

    if (!this.props.indices || !this.props.indices[code]) return null;
    var market_update = this.props.market_update[code]
      ? this.props.market_update[code]
      : this.props.indices[code].price;
    const pDiff = (
      market_update - this.props.indices[code].previous_eod
    ).toFixed(2);
let c = pDiff < 0 ? "\u25BC" : pDiff > 0 ? "\u25B2" : ""
    return (
      <span key={"j" + code} className={pDiff <= 0 ? "down" : "up"}>
       {indice.short+"  "+market_update+"  "+((pDiff / this.props.indices[code].previous_eod) * 100).toFixed(2)+"%"+"  "+"("+c+" "+pDiff+")"}
      </span>
    );
  };

  getTicker = scrip => {
    var market_update = this.props.market_update[scrip.srCode]
      ? this.props.market_update[scrip.srCode]
      : scrip.market_price;
    const pDiff = (market_update - scrip.prev_close).toFixed(2);
    let c = pDiff < 0 ? "\u25BC" : pDiff > 0 ? "\u25B2" : ""

    return (
      <span key={"j" + scrip.srCode} className={pDiff <= 0 ? "down" : "up"}>
         {scrip.shortCode+"  "+market_update+"  "+((pDiff / scrip.prev_close) * 100).toFixed(2)+"%"+"  "+"("+c+" "+pDiff+")"}
      </span>
    );
  };

  render() {
    let clasT = "block";
    if (this.props.location.pathname.indexOf("/signup") >= 0) {
      clasT = "none";
    }
    return (
      <div
      style={{
        display:
          ['IBEPGOLD', 'IBEPSILVER', 'BA','SSEP', 'FnO','SSEPGOLD','BAGOLD','DSGOLD','DS','ML','BABASIC','EXCEL'].includes(this.props.user.cprogram)
            ? "none"
            : clasT
      }}
      >
        <div
          style={{
            position: "fixed",
            bottom: "26px",
            zIndex: "1112",
            display: this.props.indicesLabel.length > 0 ? "block" : "none"
          }}
        >
          <div id="ticker2" className="stockTicker">
            <span className="quote">BSE Index: </span>
            {this.props.indicesLabel.map(indice =>
              this.getIndiceTicker(indice)
            )}
          </div>
        </div>
        <div
          style={{
            position: "fixed",
            bottom: "0px",
            zIndex: "1112",
            display: this.props.scrips.length > 0 ? "block" : "none"
          }}
        >
          <div id="ticker1" className="stockTicker">
            <span className="quote">Stock Quotes: </span>
            {this.props.scrips.map(scrip => this.getTicker(scrip))}
          </div>
        </div>
      </div>
    );
  }
}

Ticker.propTypes = {
  getScrips: PropTypes.func.isRequired,
  scrips: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  market_update: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  scrips: state.scrips.scrips,
  indices: state.scrips.indices,
  user: state.user.info,
  indicesLabel: state.scrips.indicesLabel,
  market_update: state.socketData.new_market_data,
  errors: state.errors
});

export default withRouter(
  connect(
    mapStateToProps,
    { getScrips }
  )(Ticker)
);